/**
 * Created by Sebastian Venhuis on 29.10.2020.
 */

import React from "react";
import {
    AdministrationGetUsers
} from "../../../../State/Selectors/AdministrationSelectors/usersSelector";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import "../AdministrationForm.css"
import Button from "../../../Utils/Inputs/Button";
import RestUtils from "../../../../Utils/RestUtils/RestUtils";
import {updateUserAsync} from "../../../../State/Actions/Administration/userAdministrationActions";

//https://gist.github.com/johnelliott/cf77003f72f889abbc3f32785fa3df8d
const v4Regex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

class UserEditorClass extends React.Component{
    constructor(props) {
        super(props);

        let error = false;
        if(!(this.props.match.params.userId && this.props.match.params.userId.match(v4Regex))){
            error = true;
        }
        let currentUser = this.props.users.find((user)=>{return user.id === this.props.match.params.userId});
        if(currentUser === undefined){
            error = true;
        }
        if(error){
            this.state={
                error: true,
                errorMessage: null,
                success: false
            }
            return;
        }

        console.log(currentUser);
        this.state = {
            error: false,
            errorMessage: null,
            ...currentUser,
            success: false
        }
    }

    async saveUser(){
       this.props.updateUserAsync({
           id: this.state.id,
           name: this.state.name,
           firstname: this.state.firstname,
           eMail: this.state.eMail});

       this.setState({success: true});
    }


    render(){
        if(this.state.error){
            if(this.state.errorMessage)
                return <div className={"error"}>{this.state.errorMessage}</div>
            else
                return <div className={"error"}>Unzulässige Benutzer Id</div>
        }

        if(this.state.success)
            return <Redirect to={"/Administration/Benutzerverwaltung"}/>

        //console.log(this.state);

        return <div className={"UserEditor"}>
            <div className={"content"}>
                <div className={"AdministrationForm"}>
                    <header>Benutzer ändern</header>
                    <div className={"content"}>
                        <div className={"userField"}><span>Name</span><input type={"text"} value={this.state.name} onChange={(event)=>{this.setState({name: event.target.value})}}/></div>
                        <div className={"userField"}><span>Firstname</span><input type={"text"} value={this.state.firstname} onChange={(event)=>{this.setState({firstname: event.target.value})}}/></div>
                        <div className={"userField"}><span>E-Mail</span><input type={"text"} value={this.state.eMail} onChange={(event)=>{this.setState({eMail: event.target.value})}}/></div>
                        <div className={"userField"}><span>Tenant</span><input type={"text"} value={"TODO"} readOnly={true}/></div>
                    </div>
                    <footer>
                        <Button color={"#910303"} link={"/Administration/Benutzerverwaltung"} >Abbrechen</Button><Button onClick={()=>{this.saveUser()}}>Speichern</Button>
                    </footer>
                </div>
            </div>
        </div>
    }
}


const mapStateToProps = state=>{
    return {
        users: AdministrationGetUsers(state)
    }
}

const mapActionsToProps = {
    updateUserAsync
}

export const UserEditor = connect(mapStateToProps, mapActionsToProps)(withRouter(UserEditorClass))
