import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./State/store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import RestUtils from "./Utils/RestUtils/RestUtils";
import {AsyncLogin, Login, OpenEmergencyLoginWindow, TestAuth} from "./State/Actions/authActions";
import {APIConfig} from "./config";
import {PerformanceMeasurementLogger} from "./Utils/PerformanceMeasurementLogger/PerformanceMeasurementLogger";

export const currentConfig = APIConfig;

PerformanceMeasurementLogger.initialize(APIConfig.performanceMeasurementLoggerEnabled)

console.log(APIConfig.url);
RestUtils.initialise(APIConfig.url, (token)=>{
    store.dispatch(AsyncLogin(token));
}, ()=>{
    let emergencyLogin = new Promise(((resolve, reject) => {
        store.dispatch(OpenEmergencyLoginWindow(resolve, reject));
        //store.dispatch(TestAuth());
        //resolve();
    }));
    return emergencyLogin;
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
