import React from "react"
import PropTypes from "prop-types"
import "./Information.css"

export  default class Information extends React.Component{

    render(){
        return(
            <div className={"Information"}>
                <header>
                    {this.props.label}
                </header>
                <div className={"content"}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

Information.propTypes = {
    label: PropTypes.string
};