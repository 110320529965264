import TagData from "../../../Components/Utils/Tags/TagData";

let initialState = [
    new TagData("fceccb79-93e6-4603-9dbb-1cdddc87a9f2", "Projektaufstellung")
        .Color("#AA0000"),
    new TagData("6e48a738-0721-4f36-971c-7cc3790cea98", "Ist-Analyse")
        .Color("#00AA00"),
    new TagData("a8bc3336-9fea-4647-b721-a8038733af42", "Simulation")
        .Color("#0000AA"),
    new TagData("e4057aa9-9bca-4810-b37f-5c0454b50dd2", "Durchführung")
        .Color("#00AAAA"),
];

export default function(state=initialState, action)
{
    switch (action.type) {
        default:
            return state;
    }
}
