import {combineReducers} from "redux";
import cardsReducer from "./data/cardsReducer";
import tagsReducer from "./data/tagsReducer";
import userReducer from "./data/userReducer";
import {administrationReducer} from "./data/Administration/administrationReducer";


const dataReducer = combineReducers({
    cards: cardsReducer,
    tags: tagsReducer,
    user: userReducer,
    administration: administrationReducer
});

export default dataReducer;