/**
 * Created by Sebastian Venhuis on 10.11.2020.
 */

import {v4 as uuid} from "uuid"

export const ADD_ERROR = "ADD_ERROR";

export function addError(message){
    console.log("ADD_ERROR action creator")
    return {
        type: ADD_ERROR,
        data:{
            id: uuid(),
            message: message
        }
    }
}

export const REMOVE_ERROR = "REMOVE_ERROR";

export function removeError(errorId){
    return {
        type: REMOVE_ERROR,
        data: {
            id: errorId
        }
    }
}
