/**
 * Created by Sebastian Venhuis on 24.04.2020.
 */

import React from "react"
import {connect} from "react-redux";
import "./InfoDiagram.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faAngleRight,
    faEquals,
    faLongArrowAltRight,
    faPause,
    faPen,
    faPlus,
    faSearch, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {FlowDiagram} from "./FlowDiagram";
import {v4 as uuid} from "uuid"
import Button from "../../../Utils/Inputs/Button";
import {MultiSelect} from "../../../Utils/Inputs/MultiSelect";
import {Popup} from "../../../Utils/Popup/Popup";
import RestUtils from "../../../../Utils/RestUtils/RestUtils";
import Loading from "../../../Misc/LoadingCircle/Loading";
import {DATA_SAVE_TYPES} from "../FlexIA";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {SizeCallbackTh} from "./SizeCallbackTh";

library.add(faPlus, faPen, faTrash, faTrashAlt);

export const InformationActivity = {
    INSPECT: "inspect",
    MOVE: "move",
    EDIT: "edit",
    CREATE: "create",
    WAIT: "wait",
    TRANSFORM: "transform",
    NO_ACTIVITY: "no_activity"
};

const ConnectionTypes = {
    NONE: "none",
    NORMAL: "normal",
    EVENT: "event",
    TO_NEXT: "to_next",
    TO_PREVIOUS: "to_previous"
};

const CellSpecifier = {
    ACTIVITY: "activity",
    INFORMATION_OBJECT: "information_object",
    INFORMATION_ACTIVITY: "information_activity",
    EVENTS: "events"
};

export const InfoDiagramConnectionType = ConnectionTypes;


class infoDiagram extends React.Component{

    constructor(props) {
        super(props);

        this.table = React.createRef();

        this.state = {
            loadingData: true,
            currentlyEditing: null,
            selectedItems: {1: false, 2: false, 3:false},
            eventStorage: [],
            newEventField: "",
            analysisId: this.props.analysisId,
            processId: this.props.processId,
            newActivityName: "",
            activityColumnWidth: 100,
            visualisationColumnWidth: 200,
            activityColumnCallback: ()=>{},
            visualisationColumnCallback: ()=>{}
        }
    }

    async componentDidMount(){
        //await this.mockFetchData("f5f75a87-a155-43ba-9e66-917ac084fdf6", "3b43a823-e89e-4793-af08-71413f667206")
        await this.fetchData(this.state.analysisId, this.state.processId);
    }

    getInitialEvents(rows){
        if (rows === []) {
            return [];
        }
        let events = [];
        rows.forEach((row)=>{
            row.events.forEach((event)=>{
                events.push(event);
            })
        });
        /*rows.map((row)=>{
            row.events.map((event)=>{
                events.push(event);
            });
        });*/
        events = [...events,
            {text: "Fehlerhaftes Teil", key: "e11bc90d-94da-4c12-bed5-949c29013c0a"},
            {text: "Fehlendes Teil", key: "b0c832bc-4633-40fa-9b5b-f778eddf839b"},
            {text: "Fehlerhafte Information", key: "f0392703-3c2a-4378-88df-3cf8ccdb0014"},
            {text: "Fehlende Information", key: "1c89eac2-fd9d-49dc-b7f1-4c9dfefeddf1"},
            {text: "Aktivität unterbrochen", key: "76f90053-6444-456f-ac4e-4696d41ad0ef"}]

        let result = [];
        events.forEach((event)=>{
            let existing = result.find((resultEvent)=>{
                return resultEvent.key === event.key;
            });
            if(existing === undefined) {
                result.push(event);
            }
        });

        return result;
    }

    async fetchData(analysisId, processId){
        this.setState({loadingData: true});
        try {
            let getUrl = `/tools/flexia/${analysisId}/${processId}/Flows`;
            let result = await RestUtils.Get(getUrl);
            if(result.status !== 200){
                this.setState({loadingError: true});
                console.log("status !== 200");
                return;
            }
            let rows = await result.json();
            rows = await rows.data;
            rows = await rows.map((row)=>{
                row.connections = row.connections.map((connection)=> {
                    return {
                        type: connection.connectionType,
                        to: connection.connectionTo,
                    }
                });
                row.events = row.events.map((event)=>{
                    return {
                        key: event.eventKey,
                        text: event.eventText,
                    }
                });
                row.informationActivity = row.informationActivity.map((activity)=>{
                    return activity.activity;
                });
                return row;
            });
            let eventStorage = this.getInitialEvents(rows);
            this.setState({
                loadingData: false,
                diagramData: {rows:rows},
                eventStorage: eventStorage
            })
        }
        catch (e){
            this.setState({loadingError: true});
            console.error("error catched: ", e);
            return;
        }
    }
    async putRow(row) {
        return await RestUtils.Put("/tools/flexia/" + this.state.analysisId + "/" + this.state.processId + "/Flow", row);
    }
    async postRow(row) {
        return await RestUtils.Post("/tools/flexia/" + this.state.analysisId + "/" + this.state.processId + "/Flow/" + row.id, row);
    }
    async deleteRow(rowId){
        return await RestUtils.Delete(`/tools/flexia/${this.state.analysisId}/${this.state.processId}/Flow/${rowId}`);
    }

    saveNewRow(row) {
        this.props.onSaveStarted();
        this.putRow(row).then((response)=>{
           this.props.onSaveFinished({
               saving: false,
               lastSave: new Date(),
               restStatus: response.status,
               retryFkt: ()=>{
                   this.saveNewRow(row);
               },
               dataType: DATA_SAVE_TYPES.NEW_FLOW,
           });
        });
    }
    saveExistingRow(row) {
        this.props.onSaveStarted();
        this.postRow(row).then((response)=>{
            this.props.onSaveFinished({
                saving: false,
                lastSave: new Date(),
                restStatus: response.status,
                retryFkt: ()=>{
                    this.saveExistingRow(row);
                },
                dataType: DATA_SAVE_TYPES.FLOW,
            });
        });
    }

    renderInformationActivity(activity){
        switch(activity){
            case InformationActivity.CREATE:
                return <span key={InformationActivity.CREATE}>Erstellen</span>;
            case InformationActivity.EDIT:
                return <span key={InformationActivity.EDIT}>Bearbeiten</span>;
            case InformationActivity.INSPECT:
                return <span key={InformationActivity.INSPECT}>Untersuchen</span>;
            case InformationActivity.MOVE:
                return <span key={InformationActivity.MOVE}>Bewegen</span>;
            case InformationActivity.WAIT:
                return <span key={InformationActivity.WAIT}>Warten</span>;
            case InformationActivity.TRANSFORM:
                return <span key={InformationActivity.TRANSFORM}>Transformieren</span>;
            case InformationActivity.NO_ACTIVITY:
                return <span key={InformationActivity.NO_ACTIVITY}>Keine Tätigkeit</span>;
            default:
                return <span></span>
        }
    }

    findNodeIndex(connectionId){
        if(this.state.diagramData === null || this.state.diagramData === undefined) return null;
        for(let rowIndex = 0; rowIndex < this.state.diagramData.rows.length; rowIndex++){
            if(connectionId === this.state.diagramData.rows[rowIndex].id)
                return rowIndex;
        }
        return null;
    }

    getNormalEdge(currentRowIndex, connection){
        return {start: currentRowIndex, end: this.findNodeIndex(connection.to)};
    }

    calculateTableRowHeights(){
        let table = this.table.current;
        if(table) {
            let body = table.querySelector("tbody");
            let rows = body.querySelectorAll("tr");
            let header = table.querySelector("thead");
            let rowHeights = [];
            let lastHeight = 0;
            rows.forEach((row) => {
                //lastHeight = lastHeight+row.clientHeight;
                rowHeights.push(row.offsetTop-header.clientHeight+row.clientHeight/2);
            });
            return rowHeights;
        }
        else{
            let rowHeights = [];
            let lastHeight = 0;
            for(let rowIndex = 0; rowIndex < this.state.diagramData.rows.length; rowIndex++) {
                lastHeight+=50;
                rowHeights.push(lastHeight)
            }
            return rowHeights;
        }
    }

    convertDataToGraph(){
        let graph =  {edges: [], nodes:[], globalConnections: []}

        if(this.state.diagramData === null || this.state.diagramData === undefined){
            return graph;
        }

        let maxColumn = 0;
        for(let rowIndex = 0; rowIndex < this.state.diagramData.rows.length; rowIndex++){
            let row = this.state.diagramData.rows[rowIndex];
            maxColumn = Math.max(maxColumn, row.column);
            graph.nodes.push({
                id: row.id,
                PosX: row.column,
                PosY: rowIndex+1
            })
            for(let connectionIndex = 0; connectionIndex  < row.connections.length; connectionIndex++){
                let connection = row.connections[connectionIndex];
                switch(connection.type){
                    case ConnectionTypes.NORMAL:
                        graph.edges.push(this.getNormalEdge(rowIndex, connection));
                        break;
                    case ConnectionTypes.TO_NEXT:
                        graph.globalConnections.push({type: ConnectionTypes.TO_NEXT, nodeIndex: rowIndex});
                        break;
                    case ConnectionTypes.TO_PREVIOUS:
                        graph.globalConnections.push({type: ConnectionTypes.TO_PREVIOUS, nodeIndex: rowIndex});
                        break;
                    default:
                        continue;
                }
            }

        }
        graph.amountColumns=maxColumn+1;
        return graph;
    }

    flowDiagramNodeMoved(id, posX){
        this.setState((state, props)=>{
            let newRows = [...state.diagramData.rows];
            let movedNode = newRows.find((element)=>{return element.id === id});
            movedNode.column = posX;
            this.saveExistingRow(movedNode);
            return {
                diagramData: {
                    ...state.diagramData,
                    rows: newRows
                }
            }
        });
    }

    flowDiagramEdgeCreated(startId, endId){
        this.setState((state, props)=>{
            let newRows = [...state.diagramData.rows];
            let startNode = newRows.find((element)=>{return element.id === startId});
            let newConnection = {type: ConnectionTypes.NORMAL, to:endId};

            let preexisting = startNode.connections.find((element)=>element.type === ConnectionTypes.NORMAL && element.to === endId);
            if(preexisting) {
                return;
            }

            startNode.connections = [...startNode.connections, newConnection];
            this.saveExistingRow(startNode);

            //Determine the column of the end node
            if(startNode.connections.length > 1){
                let newColumn = 0;
                newRows.forEach((node)=>{newColumn = Math.max(newColumn, node.column)});
                newColumn+=1;//Add one to create a new Column

                let endNode = newRows.find((element)=>{return element.id === endId});

                if(newRows.findIndex((node)=>node.id === startNode.id) < newRows.findIndex((node)=>node.id===endNode.id)){//Only shift the node, if the connection comes from a previous step
                    if(endNode.column === startNode.column) { //Only shift the Node, if it is in the same column as the start node
                        endNode.column = newColumn;

                        //Check if further nodes need to be moved
                        let nextNode = endNode;
                        do{
                            nextNode.column = newColumn;
                            this.saveExistingRow(nextNode);
                            let nextConnections = nextNode.connections.filter((connection)=>connection.type === ConnectionTypes.NORMAL);
                            if(nextConnections.length===0) break;
                            let directNext = nextConnections[0];
                            nextNode = newRows.find((node)=>node.id === directNext.to);

                        }while(nextNode)
                    }
                }
            }


            return{
                diagramData: {
                    ...state.diagramData,
                    rows: newRows
                }
            }
        });
    }
    flowDiagramToggleSelfConnection(id){
        this.setState((state, props)=>{
            let newRows = [...state.diagramData.rows];
            let node = newRows.find((element)=>{return element.id === id});
            let existingSelfConnection = node.connections.find((element)=>{return element.to === id});
            if(existingSelfConnection){
                node.connections = [...node.connections.filter((element)=>{return element.to!==id})]
            }
            else{
                node.connections = [...node.connections, {type: ConnectionTypes.NORMAL, to: id}]
            }
            this.saveExistingRow(node);
            return {
                diagramData: {
                    ...state.diagramData,
                    rows: newRows
                }
            }
        })
    }
    flowDiagramEdgeReplaced(oldEdge, newEdge){
        this.setState((oldState)=>{
            let newRows = [...oldState.diagramData.rows];
            let node = newRows.find((element)=>{return element.id === oldEdge.start});


            node.connections = [...node.connections.filter((element)=>{return element.to!==oldEdge.end})];
            if(newEdge) //Add new Edge
            {
                node.connections = [...node.connections, {type: ConnectionTypes.NORMAL, to:newEdge.end}]
            }
            this.saveExistingRow(node);
            return {
                diagramData:{
                    ...oldState.diagramData,
                    rows: newRows
                }
            }

        })
    }
    flowDiagramToggleToNextConnection(nodeId){
        this.setState((state, props)=>{
            let newRows = [...state.diagramData.rows];
            let node = newRows.find((element)=>{return element.id === nodeId});
            if(node.connections.find((element)=>{return element.type === ConnectionTypes.TO_NEXT})){
                node.connections = [...node.connections.filter((element)=>{return element.type !== ConnectionTypes.TO_NEXT}), {type: ConnectionTypes.TO_PREVIOUS}]
            }
            else if (node.connections.find((element)=>{return element.type === ConnectionTypes.TO_PREVIOUS})){
                node.connections = [...node.connections.filter((element)=>{return element.type !== ConnectionTypes.TO_PREVIOUS})]
            }
            else{
                node.connections = [...node.connections, {type: ConnectionTypes.TO_NEXT}]
            }
            this.saveExistingRow(node);
            return {
                diagramData: {
                    ...state.diagramData,
                    rows: newRows
                }
            }
        })
    }

    onAddRow(activityName = ""){
        this.setState((oldState)=>{
            let currentMaxRowOrder = 0;
            oldState.diagramData.rows.forEach((row)=>{
                if(row.rowOrder > currentMaxRowOrder)
                    currentMaxRowOrder = row.rowOrder;
            })
            let newRows = [...oldState.diagramData.rows];
            let newRowItem = {
                id: uuid(),
                activity: activityName,
                informationObject: "",
                informationActivity: [],
                events: [],
                connections: [
                    {type: ConnectionTypes.TO_NEXT},
                ],
                column: 3,
                rowOrder: currentMaxRowOrder+1
            };
            if(newRows.length !== 0) {
                newRows[newRows.length - 1].connections = newRows[newRows.length - 1].connections.filter((connection) => {
                    return connection.type !== ConnectionTypes.TO_NEXT
                });
                newRows[newRows.length - 1].connections.push({type: ConnectionTypes.NORMAL, to: newRowItem.id});
                newRowItem.column = newRows[newRows.length - 1].column;
                this.saveExistingRow(newRows[newRows.length - 1]);
            }
            else{
                newRowItem.column = 2;
            }
            this.saveNewRow(newRowItem);
            return {
                diagramData: {
                    ...oldState.diagramData,
                    rows: [...newRows, newRowItem]
                }
            }
        })
    }

    async onRemoveRow(id){
        this.props.onSaveStarted();
        try {

            let updatePromise = Promise.all(this.state.diagramData.rows.map(async row => {
                if (row.id === id) return; //Skip row, that will be deleted anyway
                row.connections = row.connections.filter((connection) => {
                    if (connection.type === ConnectionTypes.NORMAL) {
                        return connection.to !== id;
                    } else return true
                })
                return await this.postRow(row);
            }))
            let deletePromise = this.deleteRow(id);
            this.setState((oldState) => {
                let newRows = oldState.diagramData.rows.reduce((accum, element) => {
                    if (element.id !== id) {
                        let newElement = {
                            ...element,
                            connections: element.connections.filter((connection) => {
                                if (connection.type === ConnectionTypes.NORMAL) {
                                    return connection.to !== id;
                                } else return true
                            })
                        }
                        accum.push(newElement);
                    }
                    return accum;
                }, [])

                return {
                    diagramData: {
                        ...oldState.diagramData,
                        rows: newRows
                    }
                }
            })
            await Promise.all([updatePromise, deletePromise]);
            this.props.onSaveFinished({
                saving: false,
                lastSave: new Date(),
                restStatus: 200,
                retryFkt: ()=>{
                    this.onRemoveRow(id);
                },
                dataType: DATA_SAVE_TYPES.FLOW,
            });
        }
        catch(e){
            this.props.onSaveFinished({
                saving: false,
                lastSave: new Date(),
                restStatus: 400,
                retryFkt: ()=>{
                    this.onRemoveRow(id);
                },
                dataType: DATA_SAVE_TYPES.FLOW,
            });
        }

    }

    getEditableCellValue(state, rowId, cellSpecifier){
        let row = state.diagramData.rows.find((row)=>{return row.id === rowId})
        if(!row){
            console.error("Could not find Row with id ", rowId);
            return null;
        }

        switch(cellSpecifier){
            case CellSpecifier.ACTIVITY:
                return row.activity;
            case CellSpecifier.INFORMATION_ACTIVITY:
                return row.informationActivity;
            case CellSpecifier.INFORMATION_OBJECT:
                return row.informationObject;
            case CellSpecifier.EVENTS:
                return row.events;
            default:
                console.warn(cellSpecifier, " not Implemented");
                return null
        }
    }
    onClickEditableCell(rowId, cellSpecifier){
        this.setState((oldState)=>{
            if(oldState.currentlyEditing){
                //Maybe do something here?
            }
            return{
                currentlyEditing: {
                    rowId: rowId,
                    cellSpecifier: cellSpecifier,
                    currentValue: this.getEditableCellValue(oldState, rowId, cellSpecifier)
                }
            }
        })
    }
    onBlurEditableCell(){
        this.setState({currentlyEditing: false})
    }

    onSaveEditableCell(){
        this.setState((oldState)=>{
            let rows =  [...oldState.diagramData.rows];
            let currentlyEditing = oldState.currentlyEditing;
            let editedRow = rows.find((row)=>{return row.id===currentlyEditing.rowId});
            switch (currentlyEditing.cellSpecifier) {
                case CellSpecifier.ACTIVITY:
                    editedRow.activity = currentlyEditing.currentValue;
                    break;
                case CellSpecifier.INFORMATION_OBJECT:
                    editedRow.informationObject = currentlyEditing.currentValue;
                    break;
                case CellSpecifier.INFORMATION_ACTIVITY:
                    editedRow.informationActivity = currentlyEditing.currentValue;
                    break;
                case CellSpecifier.EVENTS:
                    editedRow.events = currentlyEditing.currentValue;
                    break;
                default:
                    console.error(currentlyEditing.cellSpecifier, " not Implemented")
            }
            this.saveExistingRow(editedRow);
            return{
                diagramData:{
                    ...oldState.diagramData,
                    rows: rows
                },
                currentlyEditing: null
            }
        })
        //this.setState({currentlyEditing: false})
    }

    getMultiSelectDict(array){
        let dict = {}
        array.forEach((element)=>{dict[element]=true})
        return dict;
    }

    renderRow(element){
        let editing = this.state.currentlyEditing;
        let rowEditing = editing && editing.rowId === element.id;

        let events = [...this.state.eventStorage];

        return <tr key={element.id}>
            <td>
                <div>
                    <input type={"text"} defaultValue={element.activity}
                           onBlur={ (event)=>{
                               this.saveExistingRow({
                                   ...element,
                                   activity: event.target.value
                               })
                           }}
                           onKeyUp={(event => {
                               if(event.key === "Enter")
                                   this.saveExistingRow({
                                       ...element,
                                       activity: event.target.value
                                   })
                               if(event.key === "Escape"){
                                   event.target.value = element.activity;
                               }
                           })}
                    />
                </div>
            </td>
            <td>
                <div>
                    <input type={"text"} defaultValue={element.informationObject}
                           onBlur={ (event)=>{
                               this.saveExistingRow({
                                   ...element,
                                   informationObject: event.target.value
                               })
                           }}
                           onKeyUp={(event => {
                               if(event.key === "Enter")
                                   this.saveExistingRow({
                                       ...element,
                                       informationObject: event.target.value
                                   })
                               if(event.key === "Escape"){
                                   event.target.value = element.informationObject;
                               }
                           })}
                    />
                </div>
            </td>
            <td className={"activity"}>
                {
                    rowEditing && editing.cellSpecifier === CellSpecifier.INFORMATION_ACTIVITY ?
                        <div style={{ display: "flex", alignItems: "center"}}>
                            <Popup onOutsideClick={this.onBlurEditableCell.bind(this)}>
                                <MultiSelect
                                    elements={[
                                        {element: <div>Untersuchen</div>, key:InformationActivity.INSPECT},
                                        {element: <div>Bewegen</div>, key:InformationActivity.MOVE},
                                        {element: <div>Bearbeiten</div>, key:InformationActivity.EDIT},
                                        {element: <div>Erstellen</div>, key:InformationActivity.CREATE},
                                        {element: <div>Warten</div>, key:InformationActivity.WAIT},
                                        {element: <div>Transformieren</div>, key:InformationActivity.TRANSFORM},
                                        {element: <div>Keine Tätigkeit</div>, key:InformationActivity.NO_ACTIVITY},
                                    ]}
                                    title={"Informationsaktivitäten"}
                                    onClose={this.onBlurEditableCell.bind(this)}
                                    allowElementAdd={false}
                                    selectedChildren={this.getMultiSelectDict(element.informationActivity)}
                                    onSelectionChanged={(key, status)=>{
                                        let array = element.informationActivity;
                                        if(status) {
                                            array.push(key)
                                        }
                                        else{
                                            array = array.filter((element)=>{return element !== key})
                                        }
                                        this.setState((oldState)=>{
                                            let rows =  [...oldState.diagramData.rows];
                                            let currentlyEditing = oldState.currentlyEditing;
                                            let editedRow = rows.find((row)=>{return row.id===currentlyEditing.rowId});
                                            editedRow.informationActivity = array;
                                            this.saveExistingRow(editedRow);
                                            return{
                                                diagramData:{
                                                    ...oldState.diagramData,
                                                    rows: rows
                                                }
                                            }
                                        })


                                    }}
                                />
                            </Popup>
                            {element.informationActivity.map((element)=>{
                                return this.renderInformationActivity(element);
                            })}
                        </div>:
                        <div style={{ display: "flex", alignItems: "center"}} onClick={this.onClickEditableCell.bind(this, element.id, CellSpecifier.INFORMATION_ACTIVITY)}>
                            {(element.informationActivity && element.informationActivity.length > 0) ?
                                element.informationActivity.map((element)=>{
                                return this.renderInformationActivity(element);
                            }) : <span className={"placeholder"}>Bitte wählen Sie eine aktivität aus</span>}
                        </div>
                }
            </td>
            <td>
                {
                    rowEditing && editing.cellSpecifier === CellSpecifier.EVENTS ?
                        <div>
                            <Popup onOutsideClick={this.onBlurEditableCell.bind(this)}>
                                <MultiSelect
                                    elements={events.map((element)=>{
                                        let res =  {element: <div key={element.key}>{element.text}</div>, key: element.key};
                                        return res;
                                    })}

                                    selectedChildren={
                                        this.getMultiSelectDict(element.events.map((element)=>{return element.key}))
                                    }

                                    onSelectionChanged={(key, status)=>{
                                        let event = events.find((event)=>{return event.key === key});
                                        if(!event) return;
                                        let array = element.events;
                                        if(status) {
                                            array.push(event)
                                        }
                                        else{
                                            array = array.filter((element)=>{return element.key !== key})
                                        }
                                        this.setState((oldState)=>{
                                            let rows =  [...oldState.diagramData.rows];
                                            let currentlyEditing = oldState.currentlyEditing;
                                            let editedRow = rows.find((row)=>{return row.id===currentlyEditing.rowId});
                                            editedRow.events = array;
                                            this.saveExistingRow(editedRow);
                                            return{
                                                diagramData:{
                                                    ...oldState.diagramData,
                                                    rows: rows
                                                }
                                            }
                                        })
                                    }}

                                    onElementAdd={()=>{return <input type={"text"}
                                                                     value={this.state.newEventField}
                                                                     onChange={(event)=>{this.setState({newEventField: event.target.value})}}/>}}
                                    onElementAdded={()=>{
                                        this.setState(oldState=>{
                                            return {
                                                eventStorage: [...oldState.eventStorage, {text: oldState.newEventField, key: uuid()}]
                                            }
                                        })
                                    }}

                                    allowElementAdd={true}
                                    title={"Störereigniss"}
                                    onClose={this.onBlurEditableCell.bind(this)}

                                />
                            </Popup>
                            <div className={"EventList"}>
                                {(element.events && element.events.length > 0) ? element.events.map((element)=>{return <div key={element.key}>{element.text}</div>}) :
                                    <span className={"placeholder"}>Bitte wählen Sie eine aktivität aus</span>}
                            </div>
                        </div>:
                        <div className={"EventList"} onClick={this.onClickEditableCell.bind(this, element.id, CellSpecifier.EVENTS)}>
                            {(element.events && element.events.length > 0) ? element.events.map((element)=>{return <div key={element.key}>{element.text}</div>}) :
                                <span className={"placeholder"}>Bitte wählen Sie eine aktivität aus</span>}
                        </div>
                }
            </td>
            <td></td>
            <td className={"action"}>
                <div>
                    <FontAwesomeIcon icon={faTrashAlt} onClick={()=>{
                        this.onRemoveRow(element.id)
                    }}/>
                </div>
            </td>
        </tr>
    }

    calculateFlowDiagramSize(){
        return this.state.visualisationColumnWidth;
    }

    calculateFlowDiagramOffset(){
        return this.state.activityColumnWidth + this.state.visualisationColumnWidth
    }

    render() {
        return (
            <div className={"InfoDiagram"}>
                {this.state.loadingData ?
                    <span><Loading/></span> :
                    <div className={"CompleteDiagramContainer"}>
                        <div className={"DiagramContainer"}>
                            <table className={"DiagramTable"} ref={this.table}>
                                <thead>
                                    <tr>
                                        <th style={{width: "20%"}} rowSpan={2}>Aktivität / Unteraktivität</th>
                                        <th colSpan={2}>Informationsobjekt</th>
                                        <th rowSpan={2}>Störereignisse</th>
                                        <SizeCallbackTh tdProps={{rowSpan: 2}} onResize={(width, height)=>{
                                            this.setState({
                                                visualisationColumnWidth: width
                                            })
                                        }}>Visualisierung</SizeCallbackTh>
                                        <SizeCallbackTh style={{width: "1%"}} tdProps={{rowSpan: 2}} onResize={(width, height)=>{
                                            this.setState({
                                                activityColumnWidth: width
                                            })
                                        }}>Aktionen</SizeCallbackTh>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <th style={{width: "8%"}}>Tätigkeit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.diagramData.rows.map((element, index)=>{
                                    return this.renderRow(element)
                                })}
                                <tr>
                                    <td className={"newActivityButtonCell"}>
                                        <div>
                                            <input className={"newActivityCell"} value={this.state.newActivityName}
                                               onBlur={e=>{
                                                   if(e.target.value === "" || this.state.newActivityName === ""){
                                                       return;
                                                   }
                                                   this.onAddRow(e.target.value);
                                                   this.setState({
                                                       newActivityName: ""
                                                   })
                                               }}
                                               onChange={e=>{
                                                   this.setState({
                                                       newActivityName: e.target.value
                                                   })
                                               }}
                                               onKeyUp={e=>{
                                                   if(e.key === "Escape"){
                                                       this.setState({
                                                           newActivityName: ""
                                                       })
                                                       e.preventDefault();
                                                   }
                                                   if(e.key === "Tab" || e.key === "Enter"){

                                                       this.onAddRow(e.target.value);
                                                       this.setState({
                                                           newActivityName: ""
                                                       })
                                                       e.preventDefault();
                                                   }
                                               }}
                                            />
                                        </div>
                                    </td>
                                    <td><div><input placeholder={"z.B. Zeichnung"} disabled={true}/></div></td>
                                    <td><div><input disabled={true}/></div></td>
                                    <td><div><input disabled={true}/></div></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={"FlowDiagramContainer"}>
                            <div style={{right: this.calculateFlowDiagramOffset(), width: this.calculateFlowDiagramSize()}} className={"FlowDiagramInnerContainer"}>
                                <FlowDiagram
                                    data = {this.convertDataToGraph()}
                                    onNodeMoved={this.flowDiagramNodeMoved.bind(this)}
                                    onEdgeCreated={this.flowDiagramEdgeCreated.bind(this)}
                                    onGetDiagramRows={this.calculateTableRowHeights.bind(this)}
                                    onToggleSelfConnection={this.flowDiagramToggleSelfConnection.bind(this)}
                                    onReplaceConnection={this.flowDiagramEdgeReplaced.bind(this)}
                                    onToggleToNextConnection={this.flowDiagramToggleToNextConnection.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state){
    return {

    }
}

const mapActionsToProps = {
};

export const InfoDiagram = connect(mapStateToProps, mapActionsToProps)(infoDiagram);
