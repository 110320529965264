/**
 * Created by Sebastian Venhuis on 12.11.2019.
 */
import {FLEXIA_SWITCH_SIDEBARS} from "../../../Actions/view/tools/flexiaActions";

const initialState = {
    hideSidebars: false
};

export default function flexiaReducer(state = initialState, action) {
    switch (action.type) {
        case FLEXIA_SWITCH_SIDEBARS:
            return {
                ...state,
                hideSidebars: !action.showSidebars
            };
        default:
            return state;
    }
}
