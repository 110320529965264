import React from "react";
import {connect} from "react-redux"
import {GetCurrentUserId} from "../../State/Selectors/authSelectors";
import {AsyncSetCurrentUser} from "../../State/Actions/userActions";
import {GetEMail, GetFirstname, GetLastname, GetRoleIds, GetUsername} from "../../State/Selectors/userSelector";
import "./UserButton.css"
import Button from "../Utils/Inputs/Button";
import onClickOutside from "react-onclickoutside";
import {AsyncLogout} from "../../State/Actions/authActions";
import {Link} from "react-router-dom";
import {currentConfig} from "../../index";

class UserButton extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            userMenuOpen: false
        };
    }

    componentDidMount() {
        try {
            this.props.AsyncSetCurrentUser();
        }
        catch (e) {
            console.error(e);
        }
    }

    handleClickOutside = (event  =>{
        if(this.state.userMenuOpen)
            this.setState({userMenuOpen: false});
    });


    render(){
        return(
            <div className={"UserButton"}>
                <Button name={this.props.Firstname + " " + this.props.Lastname} onClick={(()=>{
                    this.setState({userMenuOpen: true})
                })}/>
                {this.state.userMenuOpen ? <div className={"UserButton MenuContainer"}>
                    <div className={"UserButton Menu"}>
                        <Link to={"/Profil"} className={"UserButton MenuEntry"} onClick={(event)=>{this.setState({userMenuOpen: false})}}>
                            Profil
                        </Link>
                        <Link to={"/Firmenprofil"} className={"UserButton MenuEntry"} onClick={(event)=>{this.setState({userMenuOpen: false})}}>
                            Firmen Profil
                        </Link>
                        {this.props.Roles.includes(currentConfig.adminRole) ?
                            <Link to={"/Administration"} className={"UserButton MenuEntry"}onClick={(event)=>{this.setState({userMenuOpen: false})}}>
                                Administration
                            </Link> : null
                        }
                        <div className={"UserButton MenuSeperator"}/>
                        <div className={"UserButton MenuEntry"} onClick={(event)=>{
                            this.props.AsyncLogout();
                            this.setState({userMenuOpen: false});
                        }}>
                            Ausloggen
                        </div>
                    </div>
                </div> : null}
            </div>
        )
    }
}

function mapStateToProps(state){
    return{
        UserId: GetCurrentUserId(state),
        Username: GetUsername(state),
        Firstname: GetFirstname(state),
        Lastname: GetLastname(state),
        Email: GetEMail(state),
        Roles: GetRoleIds(state)
    }
}

const mapActionsToProps = {
    AsyncSetCurrentUser,
    AsyncLogout
};

export default connect(mapStateToProps, mapActionsToProps)(onClickOutside(UserButton));

