/**
 * Created by Sebastian Venhuis on 29.04.2020.
 */

import * as d3 from "d3";
import deepmerge from "deepmerge";
import {InfoDiagramConnectionType} from "./InfoDiagram";
import "d3-selection-multi";

/**
 * This Function can render all of the FlexIA Flow Graph diagram. It also handles all interaction with the Graph.
 * This (sadly) could not be implemented as a class, as d3 relies on the "this" in callbacks
 * @param canvasRef React Reference to the container in which to render the SVG
 * @param initialOptions initial Options for the renderer. See defaultOptions for a full list of options
 * @returns {renderFlowDiagram} The Renderer Function, that needs to be called to create the renderer. This Functions also holds some additional Functions: renderUpdate, onNodeMoved, onEdgeCreated, onGetDiagramRows
 * @constructor
 */
export const FlowDiagramRenderer = function(canvasRef, initialOptions){
    console.log("Initializing FlowDiagramRenderer with Options {canvasRef:",canvasRef,",initalOptions:",initialOptions);

    //TODO: Set End Node Type
    //TODO: Events

    let defaultOptions = {
        edges: {
            strokeWidth: 1,
            strokeColor: "black"
        },
        nodes: {
            radius: 5,
            selected: {
                radius: 6,
                fillColor: "black",
                transitionTime: 50
            },
            fillColor: "black"
        },
        menu: {
            nodeMenu: {
                width: 16,
                height: 16,
                offsetX: 10,
                offsetY: -15
            },
            nodeMenuItem:{
                strokeWidth: 0.5,
                strokeColor: "white",
                fillColor: "lightgray",
                fillOpacity: 0.5
            },
            nodeMenuItemSelfConnection:{
                size: 8,
                strokeWith: 0.8,
                strokeColor: "black"
            },
            noeMenuToNextToggle:{
                size: 8,
                strokeWith: 0.8,
                strokeColor: "black"
            },
            strokeWidth: 0.5,
            strokeColor: "gray"
        },
        globalConnections: {
            arrowLength: 25
        },
        update:{
            transitionDuration: 50
        },
        interaction:{
          edgeCreateYOffset: 50,
            createEdgeMaxDistance: 50
        },
        callbacks:{
            onNodeMoved: function(){},
            onEdgeCreated: function(){},
            onGetDiagramRows: function(){},
            onToggleSelfConnection: function(){},
            onReplaceConnection: function(){},
            onToggleToNextConnection: function(){}
        }
    }

    let xScale = function(){}
    let yScale = function(yPos, data){
        if(Number.isInteger(yPos))
            return data.rows[yPos];
        else{
            let lower = data.rows[Math.floor(yPos)]
            let upper = data.rows[Math.ceil(yPos)]
            let partway =  yPos - Math.floor(yPos);
            let i = d3.interpolate(lower, upper);
            return i(partway);
        }
    }

    initialOptions = initialOptions || {};
    let options = deepmerge(defaultOptions, initialOptions);

    let currentData = null;
    let nodeMoveStartPosition = {x:0, y:0};
    let nodeMoveStartData = null;
    let nodeMoveStatus = null;

    let selectEdgesPointToPrevious = function(edges){
        return edges.filter((edge)=>{
            return edge.start > edge.end;
        });
    }

    let selectEdgesPointingToStart = function(edges){
        return edges.filter((edge)=>{
            return edge.start === edge.end;
        });
    }

    let selectEdgesPoringToNext = function(edges){
        return edges.filter((edge)=>{
            return edge.start < edge.end;
        });
    }

    let selectToNextGlobalConnections = function(connections){
        return connections.filter((connection)=>{
            return connection.type === InfoDiagramConnectionType.TO_NEXT;
        })
    }

    let selectToPreviousGlobalConnections = function(connections){
        return connections.filter((connection)=>{
            return connection.type === InfoDiagramConnectionType.TO_PREVIOUS;
        })
    }

    let defaultStyles = {};
    defaultStyles.line = function(selection){
        selection
            .attr("stroke-width", options.edges.strokeWidth)
            .attr("stroke", options.edges.strokeColor)
    }
    defaultStyles.menuItem = function(selection){
        selection
            .attr("stroke-width", options.menu.nodeMenuItem.strokeWidth)
            .attr("stroke", options.menu.nodeMenuItem.strokeColor)
            .attr("fill", options.menu.nodeMenuItem.fillColor)
            .attr("fill-opacity", options.menu.nodeMenuItem.fillOpacity)

    }

    let renderMarkerDefinitions = function(parent) {
        parent.append("svg:defs")
            .append("svg:marker")
            .attr("id", "LineArrowTriangle")
            .attr("refX", "20")
            .attr("refY", "8")
            .attr("markerWidth", 10)
            .attr("markerHeight", 16)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M 0 16 0 0 10 8 ")
            .style("stroke", "black");


        parent.append("svg:defs")
            .append("svg:marker")
            .attr("id", "LineArrowTriangleSmallFar")
            .attr("refX", "-8")
            .attr("refY", "6")
            .attr("markerWidth", 8)
            .attr("markerHeight", 12)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M 0 12 0 0 8 6 ")
            .style("stroke", "black");

        parent.append("svg:defs")
            .append("svg:marker")
            .attr("id", "LineArrowTriangleSmall")
            .attr("refX", "8")
            .attr("refY", "6")
            .attr("markerWidth", 8)
            .attr("markerHeight", 12)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M 0 12 0 0 8 6 ")
            .style("stroke", "black");

        parent.append("svg:defs")
            .append("svg:marker")
            .attr("id", "LineArrowTriangleVerySmall")
            .attr("refX", "4")
            .attr("refY", "3")
            .attr("markerWidth", 4)
            .attr("markerHeight", 6)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M 0 6 0 0 4 3 ")
            .style("stroke", "black");

        parent.append("svg:defs")
            .append("svg:marker")
            .attr("id", "LineArrowChevron")
            .attr("refX", "8")
            .attr("refY", "6")
            .attr("markerWidth", 8)
            .attr("markerHeight", 12)
            .attr("orient", "auto")
            .append("path")
            .attr("d", " M 0,0 L 8,6 0,12")
            .style("stroke", "black")
            .style("fill", "none");
    }

    let renderToNextGlobalConnections = function(parent, data, options) {
        let renderToNext = function(selection){
            selection
                .attr("x1", d => xScale(data.nodes[d.nodeIndex].PosX))
                .attr("y1", d => yScale(data.nodes[d.nodeIndex].PosY - 1, data))
                .attr("x2", d => xScale(data.nodes[d.nodeIndex].PosX))
                .attr("y2", d => yScale(data.nodes[d.nodeIndex].PosY - 1, data) + options.globalConnections.arrowLength);
        }
        let group = parent.select("#toNextGlobalConnections");
        let lines = group.selectAll("line").data(selectToNextGlobalConnections(data.globalConnections));
        lines.join(enter=>{
            enter
                .append("line")
                .call(renderToNext)
                .call(defaultStyles.line)
                .attr("marker-end", "url(#LineArrowChevron)");
        }, update=>{
            update.transition().duration(options.update.transitionDuration)
                .call(renderToNext)
        });
    }

    let renderToPreviousGlobalConnections = function(parent, data, options) {

        let arrowXOffset = 15;

        let renderToPreviousStart = function(selection){
            selection
                .attr("x1", d => xScale(data.nodes[d.nodeIndex].PosX))
                .attr("y1", d => yScale(data.nodes[d.nodeIndex].PosY - 1, data))
                .attr("x2", d => xScale(data.nodes[d.nodeIndex].PosX)+arrowXOffset)
                .attr("y2", d => yScale(data.nodes[d.nodeIndex].PosY - 1, data));
        }

        let renderToPreviousEnd = function(selection){
            selection
                .attr("x1", d => xScale(data.nodes[d.nodeIndex].PosX)+arrowXOffset)
                .attr("y1", d => yScale(data.nodes[d.nodeIndex].PosY - 1, data))
                .attr("x2", d => xScale(data.nodes[d.nodeIndex].PosX)+arrowXOffset)
                .attr("y2", d => yScale(data.nodes[d.nodeIndex].PosY - 1, data) - options.globalConnections.arrowLength);
        }

        let group = parent.select("#toPreviousGlobalConnections");
        let lines = group.selectAll("g").data(selectToPreviousGlobalConnections(data.globalConnections));
        lines.join(enter=>{
            let previousGroup = enter.append("g");
            //line Start
            previousGroup
                .append("line")
                .attr("class", "ToPreviousStart")
                .call(renderToPreviousStart)
                .call(defaultStyles.line)
            //line End
            previousGroup
                .append("line")
                .attr("class", "ToPreviousEnd")
                .call(renderToPreviousEnd)
                .call(defaultStyles.line)
                .attr("marker-end", "url(#LineArrowChevron)");

        }, update=>{
            update.selectAll("line.ToPreviousStart")
                .call(renderToPreviousStart)
                .transition().duration(options.update.transitionDuration);
            update.selectAll("line.ToPreviousEnd")
                .call(renderToPreviousEnd)
                .transition().duration(options.update.transitionDuration);
        })
    }

    let renderGlobalConnections = function(parent, data, options) {
        let group = parent.select("#globalConnectionGroup");
        renderToNextGlobalConnections(group, data, options);
        renderToPreviousGlobalConnections(group, data, options);
    }

    let findClosestNode = function(nodes, mousePos){
        let nearestNode = nodes[0];
        let nearestNodeDistance = 10000;
        nodes.forEach((node)=>{
            let position = {
                x: xScale(node.PosX),
                y: yScale(node.PosY - 1, currentData)
            };
            let posDif = {
                x: position.x - mousePos[0],
                y: position.y - mousePos[1]
            }
            let distance = Math.sqrt(posDif.x*posDif.x + posDif.y*posDif.y);
            if(distance < nearestNodeDistance)
            {
                nearestNode = node;
                nearestNodeDistance = distance;
            }
        });
        return{
            node: nearestNode,
            distance: nearestNodeDistance
        }
    }

    let onNodeClicked = function(data, b, c){
        //let d = d3.select("#FlowDiagramSvg").data;
        //d3.select(this).data({...data, PosX: data.PosX+1});

        /*let newNodes = [...currentData.nodes];
        let index = newNodes.findIndex((element)=>element.PosX === data.PosX && element.PosY === data.PosY);
        newNodes[index].PosX+=1;
        renderUpdate({
            ...currentData,
            nodes: [...newNodes]
        })*/

    }

    let onNodeMoved = function(elementData, index, elements){
        let mousePos = d3.mouse(this)

        let mouseDelta = {x: nodeMoveStartPosition.x - mousePos[0], y: nodeMoveStartPosition.y - mousePos[1]}
        //console.log(nodeMoveStartPosition, mousePos, mouseDelta);
        //console.log(nodeMoveStatus, mouseDelta);
        if(Math.abs(mouseDelta.y)>options.interaction.edgeCreateYOffset){
            let data = currentData;
            if(nodeMoveStatus !== "y")
            {
                //console.log("Resetting data after x move")
                //Reapply nodeMoveStartData
                data = deepmerge({}, nodeMoveStartData);
            }
            nodeMoveStatus = "y";
            let nodePos = data.nodes.find((node)=>{return node.id === elementData.id});

            /*console.log({
                newConnectionTo: {x: mousePos[0], y: mousePos[1]},
                newConnectionFrom: nodePos
            });*/

            renderUpdate({
                ...data,
                newConnectionTo: {x: mousePos[0], y: mousePos[1]},
                newConnectionFrom: nodePos
            })
        }
        else{
            nodeMoveStatus = "x";

            let newNodes = [...currentData.nodes];
            let nodeIndex = newNodes.findIndex((element)=>element.id === elementData.id);
            newNodes[nodeIndex].PosX = xScale.invert(mousePos[0]);

            //console.log("previewPosition", newNodes[nodeIndex].PosX);

            renderUpdate({
                ...currentData,
                newConnectionTo: undefined,
                newConnectionFrom: undefined,
                nodes: [...newNodes]
            });
        }

    }

    let onNodeMoveStart = function(a, b, c){
        //console.log("move start", a, b, c);
        //console.log("onNodeMoveStart");
        let mousePos = d3.mouse(this);
        nodeMoveStartPosition.x = mousePos[0];
        nodeMoveStartPosition.y = mousePos[1];
        nodeMoveStartData = deepmerge({}, currentData)
    }

    let onNodeMoveEnd = function(data, index, elements){
        //console.log("move end", data, index, elements);
        let mousePos = d3.mouse(this)


        let mouseDelta = {x: nodeMoveStartPosition.x - mousePos[0], y: nodeMoveStartPosition.y - mousePos[1]}
        if(Math.abs(mouseDelta.y)>options.interaction.edgeCreateYOffset){
            //Create new Edge
            //Find the nearest node
            let {node, distance} = findClosestNode(currentData.nodes, mousePos);
            //Check if distance to node is close enough
            if(distance < options.interaction.createEdgeMaxDistance){
                //Call Event
                //let startNodeIndex = currentData.nodes.findIndex((element) => element.id === data.id);
                //let endNodeIndex = currentData.nodes.findIndex((element) => element.id === nearestNode.id);
                options.callbacks.onEdgeCreated(data.id, node.id);
            }
        }
        else {
            //Create new Node
            let newNodes = [...currentData.nodes];
            let nodeIndex = newNodes.findIndex((element) => element.id === data.id);
            let posX = Math.round(xScale.invert(mousePos[0]));
            //console.log("End Position", posX);
            newNodes[nodeIndex].PosX = posX;

            options.callbacks.onNodeMoved(data.id, posX);
        }
        /*renderUpdate({
            ...currentData,
            nodes: [...newNodes]
        });*/
    }

    let onNodeMouseEnter = function(data, index, parent){
        let currentNode = d3.select(this);
        currentNode
            .transition("mouseenter")
            .duration(options.nodes.selected.transitionTime)
            .attr("r", options.nodes.selected.radius)
            .style("fill", options.nodes.selected.fillColor)
        //console.log("mouse enter index ", index, "data", data);
        renderUpdate({
            ...currentData,
            nodeMenu: {
                visible: true,
                nodeIndex: index
            }
        })
    }

    let onNodeMouseLeave = function(data, index, parent){
        //console.log("Mouse Leave", data, index, parent)
        d3.select(this)
            .transition("mouseenter")
            .duration(options.nodes.selected.transitionTime)
            .attr("r", options.nodes.radius)
            .style("fill", options.nodes.fillColor)
    }

    let renderGraphNodes = function(parent, data){
        //console.log("Rendering Graph nodes")
        let drag = d3.drag();
        drag.on("drag", onNodeMoved);
        drag.on("start", onNodeMoveStart);
        drag.on("end", onNodeMoveEnd);


        //let graphNodeGroup = parent.append("g").attr("id", "GraphNodes");
        let graphNodeGroup = parent.select("#nodeGroup");
        let nodeCircles = graphNodeGroup.selectAll("circle");
        nodeCircles
            .data(data.nodes)
            .join(
            enter => enter
                .append("circle")
                .attr("class", "node")
                .attr("r", options.nodes.radius)
                .style("fill", options.nodes.fillColor)
                .attr("cx", d=>xScale(d.PosX)+"px")
                .attr("cy", d=>yScale(d.PosY-1, data))
                .call(drag)
                .on("click", onNodeClicked)
                .on("mouseenter", onNodeMouseEnter)
                .on("mouseleave", onNodeMouseLeave),
            update => update.transition().duration(options.update.transitionDuration)
                .attr("cx", d=>xScale(d.PosX)+"px")
                .attr("cy", d=>yScale(d.PosY-1, data))
        );
    }

    let onNodeMenuSelfConnectionClick = function(elementData, index, elements){
        //console.log("Clicked on node Menu self Connection");
        options.callbacks.onToggleSelfConnection(currentData.nodes[currentData.nodeMenu.nodeIndex].id)
    }
    let onNodeMenuSelfConnectionEnter = function(data, index, elements){
        //console.log("Enter on node Menu self Connection")
        renderUpdate({
            ...currentData,
            nodeMenuSelfConnectionSelected: true
        });
    }
    let onNodeMenuSelfConnectionLeave = function(data, index, elements){
        //console.log("Leave on node Menu self Connection")
        renderUpdate({
            ...currentData,
            nodeMenuSelfConnectionSelected: false
        });
    }

    let onNodeMenuToNextToggleClick = function(elementData, index, elements){
        //console.log("Clicked on node Menu ToNext Toggle");
        options.callbacks.onToggleToNextConnection(currentData.nodes[currentData.nodeMenu.nodeIndex].id)
    }

    let onNodeMenuToNextToggleEnter = function(data, index, elements){
        renderUpdate({
            ...currentData,
            nodeMenuToNextToggleSelected: true
        })
    }

    let onNodeMenuToNextToggleLeave = function(data, index, elements){
        renderUpdate({
            ...currentData,
            nodeMenuToNextToggleSelected: false
        })
    }

    let renderNodeMenu = function(parent, data, options){
        if(!data.nodes[data.nodeMenu.nodeIndex])
            return;
        let updateSelfConnectionToggleRect = function(selection){
            selection
                .attr("x", (d)=>{return xScale(data.nodes[data.nodeMenu.nodeIndex].PosX) + options.menu.nodeMenu.offsetX})
                .attr("y", (d)=>{return yScale(data.nodes[data.nodeMenu.nodeIndex].PosY-1, data) + options.menu.nodeMenu.offsetY})
        }
        let size = options.menu.nodeMenuItemSelfConnection.size;
        let updateSelfConnectionTogglePathStart = function(selection){
            selection
                .attr("d", d=>{
                    let x = xScale(data.nodes[data.nodeMenu.nodeIndex].PosX) + options.menu.nodeMenu.offsetX;
                    let y = yScale(data.nodes[data.nodeMenu.nodeIndex].PosY-1, data) + options.menu.nodeMenu.offsetY;
                    let path = `M ${x+options.menu.nodeMenu.width*0.75},${y+options.menu.nodeMenu.height/2} \
                        c 0,${size} -${size},${size} -${size},0`;
                        //C ${(x - options.menu.nodeMenuItemSelfConnection.curveDeltaX)},${(y + options.menu.nodeMenuItemSelfConnection.curveDeltaY)} ${(x-options.menu.nodeMenuItemSelfConnection.curveDeltaX)},${(y + options.menu.nodeMenuItemSelfConnection.curveDeltaY)} ${x-options.menu.nodeMenuItemSelfConnection.curveDeltaX},${y}`;
                    return path;
                })
                .attr("stroke-width", (d)=>{return data.nodeMenuSelfConnectionSelected ? options.menu.nodeMenuItemSelfConnection.strokeWith*2 : options.menu.nodeMenuItemSelfConnection.strokeWith})
        }
        let updateSelfConnectionTogglePathEnd = function(selection){
            selection
                .attr("d", d=>{
                    let x = xScale(data.nodes[data.nodeMenu.nodeIndex].PosX) + options.menu.nodeMenu.offsetX;
                    let y = yScale(data.nodes[data.nodeMenu.nodeIndex].PosY-1, data) + options.menu.nodeMenu.offsetY;
                    let path = `M ${x+options.menu.nodeMenu.width*0.75 - size},${y+options.menu.nodeMenu.height/2} \
                        c 0,-${size} +${size},-${size} +${size},0`;
                    //C ${(x - options.menu.nodeMenuItemSelfConnection.curveDeltaX)},${(y + options.menu.nodeMenuItemSelfConnection.curveDeltaY)} ${(x-options.menu.nodeMenuItemSelfConnection.curveDeltaX)},${(y + options.menu.nodeMenuItemSelfConnection.curveDeltaY)} ${x-options.menu.nodeMenuItemSelfConnection.curveDeltaX},${y}`;
                    return path;
                })
                .attr("stroke-width", (d)=>{return data.nodeMenuSelfConnectionSelected ? options.menu.nodeMenuItemSelfConnection.strokeWith*2 : options.menu.nodeMenuItemSelfConnection.strokeWith})
        }

        let updateToNextConnectionToggleRect = function(selection){
            selection
                .attr("x", (d)=>{return xScale(data.nodes[data.nodeMenu.nodeIndex].PosX) + options.menu.nodeMenu.offsetX})
                .attr("y", (d)=>{return yScale(data.nodes[data.nodeMenu.nodeIndex].PosY-1, data)+options.menu.nodeMenu.offsetY + options.menu.nodeMenu.width + 5})
        }

        let updateToNextConnectionPath = function(selection){
            selection
                .attr("d", d=>{
                    let x = xScale(data.nodes[data.nodeMenu.nodeIndex].PosX) + options.menu.nodeMenu.offsetX;
                    let y = yScale(data.nodes[data.nodeMenu.nodeIndex].PosY-1, data) + options.menu.nodeMenu.offsetY  + options.menu.nodeMenu.width + 5;
                    let w = options.menu.nodeMenu.width;
                    let h = options.menu.nodeMenu.height;
                    let path = `M ${x+0.5*w}, ${y}\
                        l 0, ${h} \
                        l 2, -2\
                        m -2, 2\
                        l -2, -2\
                        M ${x+0.5*w}, ${y+0.5*h}\
                        l ${0.4*w}, 0\
                        l 0, -${0.5*h}\
                        l 2, 2\
                        m -2, -2\
                        l -2, 2`
                    return path;
                })
                .attr("stroke-width", (d)=>{return data.nodeMenuToNextToggleSelected ? options.menu.noeMenuToNextToggle.strokeWith*2 : options.menu.noeMenuToNextToggle.strokeWith})
        }

        let menuContainer = parent.select("#nodeMenuGroup");
        //console.log("Rendering Node Menu ", data.nodeMenu, data);
        let group = menuContainer.selectAll("g").data([data.nodeMenu]);
        group.join(
            enter=>{
                let menuGroup = enter.append("g");//.attr("class", "nodeMenu");
                //SelfConnection
                menuGroup.append("rect")
                    .attr("class", "SelfConnectionToggle")
                    .call(updateSelfConnectionToggleRect)
                    .attr("width", options.menu.nodeMenu.width)
                    .attr("height", options.menu.nodeMenu.height)
                    //.attr("visibility", d=>d.visible ? "visible": "hidden")
                    .call(defaultStyles.menuItem)
                    .on("click", onNodeMenuSelfConnectionClick)
                    .on("mouseenter", onNodeMenuSelfConnectionEnter)
                    .on("mouseleave", onNodeMenuSelfConnectionLeave);
                menuGroup.append("path")
                    .attr("class", "SelfConnectionToggleStart")
                    .call(updateSelfConnectionTogglePathStart)
                    .attr("stroke-width", options.menu.nodeMenuItemSelfConnection.strokeWith)
                    .attr("stroke", options.menu.nodeMenuItemSelfConnection.strokeColor)
                    .attr("fill", "none")
                    .attr("marker-end", "url(#LineArrowTriangleVerySmall)");

                menuGroup.append("path")
                    .attr("class", "SelfConnectionToggleEnd")
                    .call(updateSelfConnectionTogglePathEnd)
                    .attr("stroke-width", options.menu.nodeMenuItemSelfConnection.strokeWith)
                    .attr("stroke", options.menu.nodeMenuItemSelfConnection.strokeColor)
                    .attr("fill", "none");

                //ToNextConnection
                menuGroup.append("rect")
                    .attr("class", "ToNextConnectionToggle")
                    .call(updateToNextConnectionToggleRect)
                    .attr("width", options.menu.nodeMenu.width)
                    .attr("height", options.menu.nodeMenu.height)
                    .call(defaultStyles.menuItem)
                    .on("click", onNodeMenuToNextToggleClick)
                    .on("mouseenter", onNodeMenuToNextToggleEnter)
                    .on("mouseleave", onNodeMenuToNextToggleLeave);

                menuGroup.append("path")
                    .attr("class", "ToNextConnectionTogglePath")
                    .call(updateToNextConnectionPath)
                    .attr("stroke-width", options.menu.noeMenuToNextToggle.strokeWith)
                    .attr("stroke", options.menu.noeMenuToNextToggle.strokeColor)
                    .attr("fill", "none")
                    .on("click", onNodeMenuToNextToggleClick)
                    .on("mouseenter", onNodeMenuToNextToggleEnter)
                    .on("mouseleave", onNodeMenuToNextToggleLeave);



            },
            update=>{
                update
                    //SelfConnection
                    //.selectAll("g")
                    .selectAll("rect.SelfConnectionToggle")
                    .call(updateSelfConnectionToggleRect)
                    //.attr("x", (d)=>{ return xScale(data.nodes[data.nodeMenu.nodeIndex].PosX) + options.menuItem.nodeMenu.offsetX})
                    //.attr("y", (d)=>{return yScale(data.nodes[data.nodeMenu.nodeIndex].PosY-1, data) + options.menuItem.nodeMenu.offsetY})
                    .transition().duration(options.update.transitionDuration)
                    .attr("visibility", d=>{ return data.nodeMenu.visible ? "visible": "hidden"})
                update.selectAll("path.SelfConnectionToggleStart")
                    .call(updateSelfConnectionTogglePathStart)
                    .transition().duration(options.update.transitionDuration)
                    .attr("visibility", d=>{ return data.nodeMenu.visible ? "visible": "hidden"})
                update.selectAll("path.SelfConnectionToggleEnd")
                    .call(updateSelfConnectionTogglePathEnd)
                    .transition().duration(options.update.transitionDuration)
                    .attr("visibility", d=>{  return data.nodeMenu.visible ? "visible": "hidden"})
                //ToNextConnection
                update.selectAll("rect.ToNextConnectionToggle")
                    .call(updateToNextConnectionToggleRect)
                    .transition().duration(options.update.transitionDuration)
                    .attr("visibility", d=>{ return data.nodeMenu.visible ? "visible": "hidden"})

                update.selectAll("path.ToNextConnectionTogglePath")
                    .call(updateToNextConnectionPath)
                    .transition().duration(options.update.transitionDuration)
                    .attr("visibility", d=>{  return data.nodeMenu.visible ? "visible": "hidden"})
            }
        )
    }

    let onEdgeEndClick = function(elementData, index, elements){
        //console.log("Test", this);
    }

    let onEdgeMoved = function(elementData, index, elements){
        let mousePos = d3.mouse(d3.select("#FlowDiagramSvg").node());

        let nodePos = currentData.nodes[elementData.start]

        /*console.log(mousePos[0], mousePos[1], {
            newConnectionTo: {x: mousePos[0], y: mousePos[1]},
            newConnectionFrom: nodePos
        })*/

        renderUpdate({
            ...currentData,
            newConnectionTo: {x: mousePos[0], y: mousePos[1]},
            newConnectionFrom: nodePos
        });
    }
    let onEdgeMoveStart = function(elementData, index, elements){
        //let mousePos = d3.mouse(d3.select("#FlowDiagramSvg"));
        let data = currentData;


        //Remove Moved Edge
        let edges = [...data.edges];
        let filteredEdges =  edges.filter((element)=>{return !(element.start === elementData.start && element.end === elementData.end)})

        //Start new Edge creation
        let nodePos = data.nodes[elementData.start]

        /*console.log("NodePos", nodePos);*/
        renderUpdate({
            ...currentData,
            edges: filteredEdges,
            //newConnectionTo: {x: mousePos[0], y: mousePos[1]},
            //newConnectionFrom: nodePos
        });

    }
    let onEdgeMoveEnd = function(elementData, index, elements){
        let mousePos = d3.mouse(d3.select("#FlowDiagramSvg").node());
        let {node, distance} = findClosestNode(currentData.nodes, mousePos);
        let startNode = {
            start: currentData.nodes[elementData.start].id,
            end: currentData.nodes[elementData.end].id
        }
        if(distance < options.interaction.createEdgeMaxDistance)
        {
            //If close to node delete old connection and create new connection
            options.callbacks.onReplaceConnection(startNode, {start: currentData.nodes[elementData.start].id, end: node.id})
        }
        else{
            //Else only delete old collection
            options.callbacks.onReplaceConnection(startNode, null)
        }



    }

    let renderPreviousEdges = function(parent, data, options) {

        let drag = d3.drag();
        drag.on("drag", onEdgeMoved);
        drag.on("start", onEdgeMoveStart);
        drag.on("end", onEdgeMoveEnd);

        let renderStart = function(selection){
            selection
                .attr("class", "line-start")
                .attr("x1", (d) => xScale(data.nodes[d.start].PosX))
                .attr("y1", (d) => yScale(data.nodes[d.start].PosY - 1, data))
                .attr("x2", (d) => Math.min(xScale(data.nodes[d.start].PosX - 2 / 3), xScale(data.nodes[d.end].PosX - 2 / 3)))
                .attr("y2", (d) => yScale(data.nodes[d.start].PosY - 1, data)-5);
        }
        let renderMid = function(selection){
            selection
                .attr("class", "line-mid")
                .attr("x1", (d) => Math.min(xScale(data.nodes[d.start].PosX - 2 / 3), xScale(data.nodes[d.end].PosX - 2 / 3)))
                .attr("y1", (d) => yScale(data.nodes[d.start].PosY - 1, data)-5)
                .attr("x2", (d) => Math.min(xScale(data.nodes[d.start].PosX - 2 / 3), xScale(data.nodes[d.end].PosX - 2 / 3)))
                .attr("y2", (d) => yScale(data.nodes[d.end].PosY - 1, data)+5);
        }
        let renderEnd = function(selection){
            selection
                .attr("class", "line-end")
                .attr("x1", (d) => Math.min(xScale(data.nodes[d.start].PosX - 2 / 3), xScale(data.nodes[d.end].PosX - 2 / 3)))
                .attr("y1", (d) => yScale(data.nodes[d.end].PosY - 1, data)+5)
                .attr("x2", (d) => xScale(data.nodes[d.end].PosX))
                .attr("y2", (d) => yScale(data.nodes[d.end].PosY - 1, data))
        }
        let renderEndHitbox = function(selection){
            selection
                .attr("class", "line-end-hitbox")
                .attr("x1", (d) => Math.min(xScale(data.nodes[d.start].PosX - 2 / 3), xScale(data.nodes[d.end].PosX - 2 / 3)))
                .attr("y1", (d) => yScale(data.nodes[d.end].PosY - 1, data)+5)
                .attr("x2", (d) => xScale(data.nodes[d.end].PosX))
                .attr("y2", (d) => yScale(data.nodes[d.end].PosY - 1, data))
                .attr("stroke-width", 10)
                .attr("stroke", "red")
                .style("stroke-opacity", 0)
        }

        let group = parent.select("#GraphEdgesToPrevious");
        let update = group.selectAll("g").data(selectEdgesPointToPrevious(data.edges), (d)=>{return d?JSON.stringify(d):this.id});
        update.join(enter=>{
            let lineGroup = enter.append("g");
            lineGroup
                .append("line")
                .call(renderStart)
                .call(defaultStyles.line)
                .attr("marker-start", "url(#LineArrowTriangleSmallFar)");
            lineGroup
                .append("line")
                .call(renderMid)
                .call(defaultStyles.line);
            lineGroup
                .append("line")
                .call(renderEndHitbox)
                .on("click", onEdgeEndClick)
                .call(drag);
            lineGroup
                .append("line")
                .call(renderEnd)
                .call(defaultStyles.line)
                .call(drag)
                .attr("marker-start", "url(#LineArrowTriangleSmallFar)");
        }, update=>{
            update.selectAll("line.line-start")
                .transition().duration(options.update.transitionDuration)
                .call(renderStart)

            update.selectAll("line.line-mid")
                .transition().duration(options.update.transitionDuration)
                .call(renderMid)

            update.selectAll("line.line-hitbox")
                .on("click", onEdgeEndClick)
                .transition().duration(options.update.transitionDuration)
                .call(renderEndHitbox)

            update.selectAll("line.line-end")
                .on("click", onEdgeEndClick)
                .transition().duration(options.update.transitionDuration)
                .call(renderEnd)

        })
        //update.exit().remove();
    }

    let renderSameEdges = function(parent, data, options) {

        let generateSameEdgeStartPath = function(selection, deltaX, curveDeltaX, curveDeltaY){
            selection.attr( "d", (d)=>{
                let x = xScale(data.nodes[d.end].PosX);
                let y = yScale(data.nodes[d.start].PosY - 1, data);
                let path = `M ${x},${y} \
                    C ${(x - curveDeltaX)},${(y + curveDeltaY)} ${(x-deltaX)},${(y + curveDeltaY)} ${x-deltaX},${y}`;
                return path;
            });
        }

        let generateSameEdgeEndPath = function(selection, deltaX, curveDeltaX, curveDeltaY){
            selection.attr( "d", (d)=>{
                let x = xScale(data.nodes[d.end].PosX);
                let y = yScale(data.nodes[d.start].PosY - 1, data);
                let path = `M ${x-deltaX},${y} \
                    C ${(x-deltaX)},${(y - curveDeltaY)} ${(x-curveDeltaX)},${(y - curveDeltaY)} ${x},${y}`;
                return path;
            });
        }

        let group = parent.select("#GraphEdgesToSame");
        let paths = group.selectAll("g")
            .data(selectEdgesPointingToStart(data.edges), (d)=>{return d?JSON.stringify(d):this.id});

        const deltaX = ((Math.abs(xScale(1)-xScale(2)))/3);
        paths.join(enter=>{
            let pathGroup = enter.append("g");
            pathGroup
                .append("svg:path")
                .attr("class", "start")
                .call(generateSameEdgeStartPath, deltaX, 5, 15)
                .attr("stroke-width", options.edges.strokeWidth)
                .attr("stroke", options.edges.strokeColor)
                .attr("fill", "none")
                .attr("marker-end", "url(#LineArrowTriangleSmall)");
            pathGroup
                .append("svg:path")
                .attr("class", "end")
                .call(generateSameEdgeEndPath, deltaX, 5, 15)
                .attr("stroke-width", options.edges.strokeWidth)
                .attr("stroke", options.edges.strokeColor)
                .attr("fill", "none")
        },
        update=>{
            update.selectAll("path.start")
                .transition().duration(options.update.transitionDuration)
                .call(generateSameEdgeStartPath, deltaX, 5, 15);
            update.selectAll("path.end")
                .transition().duration(options.update.transitionDuration)
                .call(generateSameEdgeEndPath, deltaX, 5, 15);

        });

        //paths.exit().remove()
    }

    let renderNextEdges = function(parent, data, options) {

        let drag = d3.drag();
        drag.on("drag", onEdgeMoved);
        drag.on("start", onEdgeMoveStart);
        drag.on("end", onEdgeMoveEnd);

        let renderStart = function(selection, data){
            selection
                .attr("class", "start")
                .attr("x1", d => {return xScale(data.nodes[d.start].PosX) + "px"})
                .attr("y1", d => yScale(data.nodes[d.start].PosY - 1, data))
                .attr("x2", d => xScale(data.nodes[d.start].PosX) + "px")
                .attr("y2", d => (yScale(data.nodes[d.start].PosY - 1, data) + yScale(data.nodes[d.start].PosY, data)) / 2)
        }
        let renderMid = function(selection, data){
            selection
                .attr("class", "mid")
                .attr("x1", d => xScale(data.nodes[d.start].PosX) + "px")
                .attr("y1", d => (yScale(data.nodes[d.start].PosY - 1, data) + yScale(data.nodes[d.start].PosY, data)) / 2)
                .attr("x2", d => xScale(data.nodes[d.end].PosX) + "px")
                .attr("y2", d => (yScale(data.nodes[d.start].PosY - 1, data) + yScale(data.nodes[d.start].PosY, data)) / 2)
        }
        let renderEnd = function(selection, data){
            selection
                .attr("class", "end")
                .attr("x1", d => xScale(data.nodes[d.end].PosX) + "px")
                .attr("y1", d => (yScale(data.nodes[d.start].PosY - 1, data) + yScale(data.nodes[d.start].PosY, data)) / 2)
                .attr("x2", d => xScale(data.nodes[d.end].PosX) + "px")
                .attr("y2", d => yScale(data.nodes[d.end].PosY - 1, data))
        }

        let renderEndHitbox = function(selection){
            selection
                .attr("class", "line-end-hitbox")
                .attr("x1", d => xScale(data.nodes[d.end].PosX) + "px")
                .attr("y1", d => (yScale(data.nodes[d.start].PosY - 1, data) + yScale(data.nodes[d.start].PosY, data)) / 2)
                .attr("x2", d => xScale(data.nodes[d.end].PosX) + "px")
                .attr("y2", d => yScale(data.nodes[d.end].PosY - 1, data))
                .attr("stroke-width", 10)
                .attr("stroke", "blue")
                .style("stroke-opacity", 0)
        }

        let nextGraphEdgesGroup = parent.select("#GraphEdgesToNext");
        let nextEdges = selectEdgesPoringToNext(data.edges);
        //console.log("next Edges", nextEdges);
        let update = nextGraphEdgesGroup.selectAll("g")
            .data(nextEdges, (d)=>{return d?JSON.stringify(d):this.id});
        update.join(
            enter=>{
                let lineGroup = enter.append("g");
                lineGroup
                    .append("svg:line")
                    .call(renderStart, data)
                    .call(defaultStyles.line);
                lineGroup
                    .append("svg:line")
                    .call(renderMid, data)
                    .call(defaultStyles.line);
                lineGroup
                    .append("line")
                    .call(renderEndHitbox)
                    .on("click", onEdgeEndClick)
                    .call(drag);
                lineGroup
                    .append("svg:line")
                    .call(defaultStyles.line)
                    .call(renderEnd, data)
                    .call(drag)
                    .attr("marker-end", "url(#LineArrowTriangle)");
            },
            update=>{
                update.selectAll("line.start")
                    .transition().duration(options.update.transitionDuration)
                    .call(renderStart, data);
                update.selectAll("line.mid")
                    .transition().duration(options.update.transitionDuration)
                    .call(renderMid, data);
                update.selectAll("line.line-hitbox")
                    .on("click", onEdgeEndClick)
                    .transition().duration(options.update.transitionDuration)
                    .call(renderEndHitbox)
                update.selectAll("line.end")
                    .transition().duration(options.update.transitionDuration)
                    .call(renderEnd, data);
            });
    }

    let renderNewEdge = function(parent, data, options){

        let renderStart = function(selection){
            selection
                .attr("class", "start")
                .attrs((d)=>{
                    d = {from: data.newConnectionFrom, to: data.newConnectionTo};
                    let from = {
                        x: xScale(d.from.PosX),
                        y: yScale(d.from.PosY - 1, data)
                    }
                    let to = d.to;
                    if(from.y > to.y){
                        //ToPrevious
                        let xOffset = xScale(1)*(2/3);
                        return {
                            x1: from.x,
                            y1: from.y,
                            x2: Math.min(from.x-xOffset, to.x-xOffset),
                            y2: from.y-5
                        }
                    }
                    else{
                        //ToNext
                        return {
                            x1: from.x,
                            y1: from.y,
                            x2: from.x,
                            y2: (yScale(d.from.PosY - 1, data) + yScale(d.from.PosY, data)) /2
                        }
                    }
                })
        }

        let renderMid = function(selection){
            selection
                .attr("class", "mid")
                .attrs((d)=>{
                    d = {from: data.newConnectionFrom, to: data.newConnectionTo};
                    let from = {
                        x: xScale(d.from.PosX),
                        y: yScale(d.from.PosY - 1, data)
                    }
                    let to = d.to;
                    if(from.y > to.y){
                        //ToPrevious
                        let xOffset = xScale(1)*(2/3);
                        return {
                            x1: Math.min(from.x-xOffset, to.x-xOffset),
                            y1: from.y-5,
                            x2: Math.min(from.x-xOffset, to.x-xOffset),
                            y2: to.y+5
                        }
                    }
                    else{
                        //ToNext
                        return {
                            x1: from.x,
                            y1: (yScale(d.from.PosY - 1, data) + yScale(d.from.PosY, data)) /2,
                            x2: to.x,
                            y2: (yScale(d.from.PosY - 1, data) + yScale(d.from.PosY, data)) /2
                        }
                    }
                })
        }

        let renderEnd = function(selection){
            selection
                .attr("class", "end")
                .attrs((d)=>{
                    d = {from: data.newConnectionFrom, to: data.newConnectionTo};
                    let from = {
                        x: xScale(d.from.PosX),
                        y: yScale(d.from.PosY - 1, data)
                    }
                    let to = d.to;
                    if(from.y > to.y){
                        //ToPrevious
                        let xOffset = xScale(1)*(2/3);
                        return {
                            x1: Math.min(from.x-xOffset, to.x-xOffset),
                            y1: to.y+5,
                            x2: to.x,
                            y2: to.y
                        }
                    }
                    else{
                        //ToNext
                        return {
                            x1: to.x,
                            y1: (yScale(d.from.PosY - 1, data) + yScale(d.from.PosY, data)) /2,
                            x2: to.x,
                            y2: to.y
                        }
                    }
                })
        }

        let newGraphEdgeGroup = parent.select("#GraphEdgesNewEdge");
        let dataArray = [];
        if(data.newConnectionTo) {
            dataArray.push({from: data.newConnectionFrom, to: data.newConnectionTo})
        }
        //console.log("dataArray", dataArray);
        let update = newGraphEdgeGroup.selectAll("g")
            .data(dataArray);
        update.join(
            enter=>{
                let lineGroup = enter.append("g");
                lineGroup
                    .append("svg:line")
                    .call(renderStart)
                    .call(defaultStyles.line);
                lineGroup
                    .append("svg:line")
                    .call(renderMid)
                    .call(defaultStyles.line);
                lineGroup
                    .append("svg:line")
                    .call(defaultStyles.line)
                    .call(renderEnd)
                    .attr("marker-end", "url(#LineArrowTriangle)");
            },
            update=>{
                update.selectAll("line.start")
                    .transition().duration(options.update.transitionDuration)
                    .call(renderStart);
                update.selectAll("line.mid")
                    .transition().duration(options.update.transitionDuration)
                    .call(renderMid);
                update.selectAll("line.end")
                    .transition().duration(options.update.transitionDuration)
                    .call(renderEnd);
            });
    }

    let renderGraphEdges = function(parent, data, options) {
        /*Draw Lines to next element*/
        let graphEdgeGroup = parent.select("#edgeGroup");
        renderNextEdges(graphEdgeGroup, data, options);
        renderSameEdges(graphEdgeGroup, data, options);
        renderPreviousEdges(graphEdgeGroup, data, options);
        renderNewEdge(graphEdgeGroup, data, options);
    }

    let onSvgMouseLeave = function(data, index, elements){
        //console.log("SVG Mouse Leave");
        renderUpdate({
            ...currentData,
            nodeMenu: {
                ...currentData.nodeMenu,
                visible: false
            }
        })
    };

    let renderUpdate = function(data){
        //console.log("Rendering", data);

        if(data.nodeMenu === undefined) {
            data.nodeMenu = currentData.nodeMenu;
        }

        currentData = data;
        currentData.rows = options.callbacks.onGetDiagramRows();

        const canvasWidth = canvasRef.current.clientWidth;
        const canvasHeight = canvasRef.current.clientHeight;
        xScale = d3.scaleLinear()
            .domain([0, data.amountColumns+1])
            .range([0, canvasWidth])


        let svg = d3.select("#FlowDiagramSvg");
        svg.attr("height", canvasHeight);
        svg.attr("width", canvasWidth);

        //Visuals
        renderGraphEdges(svg, data, options);
        renderGlobalConnections(svg, data, options);
        renderGraphNodes(svg, data, options);
        renderNodeMenu(svg, data, options);
    }

    let renderFlowDiagram = function(data){
        console.log("Creating new SVG for Render from canvas", canvasRef)
        const canvasWidth = canvasRef.current.clientWidth;
        const canvasHeight = canvasRef.current.clientHeight;
        console.log("Canvas Size", canvasWidth, "x", canvasHeight)

        //Empty rendered View and recreate View
        d3.select(canvasRef.current).select("*").remove();
        let svg = d3.select(canvasRef.current).append("svg")
            .attr("id", "FlowDiagramSvg")
            .attr("width", canvasWidth)
            .attr("height", canvasHeight)
            .on("mouseleave", onSvgMouseLeave);

        data.nodeMenu = {
            nodeIndex: 0,
            visible: false
        }


        //Definitions
        renderMarkerDefinitions(svg);

        let edges = svg.append("g").attr("id", "edgeGroup");
        edges.append("g").attr("id", "GraphEdgesToPrevious");
        edges.append("g").attr("id", "GraphEdgesToSame");
        edges.append("g").attr("id", "GraphEdgesToNext");
        edges.append("g").attr("id", "GraphEdgesNewEdge");
        let globalConnections = svg.append("g").attr("id", "globalConnectionGroup")
        globalConnections.append("g").attr("id", "toPreviousGlobalConnections");
        globalConnections.append("g").attr("id", "toNextGlobalConnections");
        svg.append("g").attr("id", "nodeMenuGroup");
        svg.append("g").attr("id", "nodeGroup");

        //Initial Render
        renderUpdate(data);
    }

    renderFlowDiagram.onNodeMoved = function(callback){
        if(typeof callback === "function")
            options.callbacks.onNodeMoved = callback;
        return renderFlowDiagram;
    }

    renderFlowDiagram.onEdgeCreated = function(callback){
        if(typeof callback === "function")
            options.callbacks.onEdgeCreated = callback;
        return renderFlowDiagram;
    }

    renderFlowDiagram.onGetDiagramRows = function(callback) {
        if(typeof callback === "function")
            options.callbacks.onGetDiagramRows = callback;
        return renderFlowDiagram;
    }

    renderFlowDiagram.onToggleSelfConnection = function(callback){
        if(typeof callback === "function")
            options.callbacks.onToggleSelfConnection = callback;
        return renderFlowDiagram;
    }

    renderFlowDiagram.onReplaceConnection = function(callback){
        if(typeof callback === "function")
            options.callbacks.onReplaceConnection = callback;
        return renderFlowDiagram;
    }

    renderFlowDiagram.onToggleToNextConnection = function(callback){
        if(typeof callback === "function")
            options.callbacks.onToggleToNextConnection = callback;
        return renderFlowDiagram
    }

    renderFlowDiagram.renderUpdate = renderUpdate;

    return renderFlowDiagram;
}
