/**
 * Created by Sebastian Venhuis on 20.11.2020.
 */
import {SET_ROLES, SET_ROLES_LOADING} from "../../../Actions/Administration/rolesAdministionActions";

let initialState = {
    roles: [],
    loading: true,
}

export function rolesReducer(state = initialState, action){
    let data = action.data;
    switch (action.type){
        case SET_ROLES:{
            return {
                ...state,
                roles: data
            }
        }
        case SET_ROLES_LOADING: {
            return {
                ...state,
                loading: data
            }
        }
        default: return state
    }
}
