import "./Card.css"
import React from "react"
import PropTypes from "prop-types"
import CardData, {CARD_TYPE_CONTENT, CARD_TYPE_TOOL} from "./CardData";
import {Link} from "react-router-dom";
import Tag from "../Utils/Tags/Tag";
import {connect} from "react-redux";
import {GetTags} from "../../State/Selectors/dataSelectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faCogs, faThumbtack} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import {UserIsLoggedIn} from "../../State/Selectors/authSelectors";

library.add(faCogs, faBook, faThumbtack)

class Card extends React.Component{
    render() {
        let headerStyle = {};
        if(this.props.card.image){
            headerStyle.backgroundImage = "url("+this.props.card.image+")";
            headerStyle.backgroundSize = "cover";
        }
        if(this.props.card.tags.length > 0){
            headerStyle.borderTopColor= this.props.tags[this.props.card.mainTag].color;
        }

        let cardStyle = {
            width: this.props.width || "220px",
        };

        let typeIcon = faCogs;
        if(this.props.card.type === CARD_TYPE_CONTENT)
            typeIcon= faBook;

        return (
            <Link to={"/content/"+this.props.card.linkName} style={{textDecoration: "none", color: "black"}}>
                <div className={"Card"} style={cardStyle}>
                    <header style={headerStyle}>
                        <div className={"title"}>
                            {this.props.card.title}
                        </div>
                        <div className={"symbols"}>
                            {this.props.userIsLoggedIn ? <FontAwesomeIcon icon={faThumbtack} size={"xs"}/> : null}
                            <FontAwesomeIcon icon={typeIcon} size={"xs"}/>
                        </div>
                    </header>
                    <div className={"content"}>
                        {this.props.card.short}
                    </div>
                    <footer>
                        {this.props.card.tags.map((tagId)=>{
                            let tagData = this.props.tags[tagId];
                            return (
                                <Tag color={tagData.color} key={tagData.id} label={tagData.content}/>
                            )
                        })}
                    </footer>
                </div>
            </Link>
        )
    }
}

Card.propTypes = {
    card: PropTypes.instanceOf(CardData)
};

const mapStateToProps = state =>{

    let tags = [];
    let StateTags = GetTags(state);
    for(let index = 0; index < StateTags.length; index++){
        tags[StateTags[index].id] = StateTags[index];
    }

    return {
        tags: tags,
        userIsLoggedIn: UserIsLoggedIn(state)
    }
};

export default connect(mapStateToProps)(Card)