
import FlexDeMo_Logo from  "./FlexDeMo_Logo.svg"
import React from "react";
import "./FlexDeMoLogo.css"
import {Link} from "react-router-dom";

export default class FlexDeMoLogo extends React.Component{
    render(){
        return (
            <div className={"FlexDeMoLogo"}>
                <Link to={"/"}>
                    <img src={FlexDeMo_Logo} alt={"FlexDeMo Logo"}/>
                </Link>
            </div>
        )
    }
}