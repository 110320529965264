/**
 * Created by Sebastian Venhuis on 23.10.2020.
 */

import React from "react";
import {connect} from "react-redux";
import {UserIsLoggedIn} from "../../../../State/Selectors/authSelectors";
import {
    AdministrationGetUsers,
    AdministrationGetUsersLoading
} from "../../../../State/Selectors/AdministrationSelectors/usersSelector";
import {GetUsersAsync} from "../../../../State/Actions/Administration/userAdministrationActions";
import Loading from "../../../Misc/LoadingCircle/Loading";
import "./UserManagement.css"
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTrashAlt, faUnlockAlt, faUserEdit, faUserTag} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
import {Link, NavLink, Route} from "react-router-dom";
import {UserEditor} from "./UserEditor";
import {UserPassword} from "./UserPassword";
import Button from "../../../Utils/Inputs/Button";
import {RegisterUser} from "./RegisterUser";
import {UserRoles} from "./UserRoles";

library.add(faTrashAlt, faUnlockAlt, faUserEdit, faUserTag);

class UserManagementClass extends React.Component{

    componentDidMount() {
        this.props.GetUsersAsync();
    }

    render(){

        console.log("Users: ", this.props.users);
        return <div className={"UserManagement"}>
            <div className={"content"}>
                <Route path={"/Administration/Benutzerverwaltung/:userId/edit"}  component={UserEditor}/>
                <Route path={"/Administration/Benutzerverwaltung/:userId/roles"}  component={UserRoles}/>
                <Route path={"/Administration/Benutzerverwaltung/:userId/changePassword"} component={UserPassword}/>
                <Route path={"/Administration/Benutzerverwaltung/:userId/delete"}>Edit</Route>
                <Route path={"/Administration/Benutzerverwaltung/RegisterUser"} component={RegisterUser}/>
                <Route path={"/Administration/Benutzerverwaltung/"} exact={true}>
                    <h2 className={"contentHeader"}>Benutzerverwaltung</h2>
                    {this.props.usersLoading ? <span key={"LoadingIndicatior LoadingWrapper"}><Loading/></span> :
                    <table>
                        <thead>
                            <tr>
                                <th>E-Mail</th>
                                <th>Vorname</th>
                                <th>Nachname</th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.props.users.map((user)=>{
                            return <tr key={user.id}>
                                <td>{user.eMail}</td>
                                <td>{user.firstname}</td>
                                <td>{user.name}</td>
                                <td className={"actions"}>
                                    <Link to={{pathname: `Benutzerverwaltung/${user.id}/edit`}}><FontAwesomeIcon icon={faUserEdit} data-tip data-for={"changeUser"} /></Link>
                                    <Link to={{pathname: `Benutzerverwaltung/${user.id}/changePassword`}}><FontAwesomeIcon icon={faUnlockAlt} data-tip data-for={"changePassword"}/></Link>
                                    <Link to={{pathname: `Benutzerverwaltung/${user.id}/roles`}}><FontAwesomeIcon icon={faUserTag} data-tip data-for={"changeRoles"}/></Link>
                                    <Link to={{pathname: `Benutzerverwaltung/${user.id}/delete`}}><FontAwesomeIcon icon={faTrashAlt} data-tip  data-for={"deleteUser"}/></Link>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    }
                    <br/>

                    <Button link={"/Administration/Benutzerverwaltung/RegisterUser"}>Benutzer hinzufügen</Button>
                </Route>
            </div>
        </div>
    }
}


const mapStateToProps = state=>{
    return {
        userIsLoggedIn: UserIsLoggedIn(state),
        users: AdministrationGetUsers(state),
        usersLoading: AdministrationGetUsersLoading(state)
    }
}

const mapActionsToPorps = {
    GetUsersAsync
}

export const UserManagement = connect(mapStateToProps, mapActionsToPorps)(UserManagementClass);
