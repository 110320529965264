import {v4 as uuid} from "uuid"


export default class TagData{
    /**
     * Creates a new Tag Data entity
     * @param id {string} The uuid of this tag
     * @param content {string} the label, that is displayed in the Tag
     */
    constructor(id, content){
        this.id = id || uuid();
        this.content = content || "";
        this.color = null;
        this.link = "";
    }

    /**
     * Sets the Color of the Tag
     * @param value {string} "#FFFFFF"
     * @returns {TagData}
     */
    Color(value){
        this.color = value;
        return this;
    }

    /**
     * Sets the Link of this Tag. The link will be opend (href) if the Tag is clicked
     * @param value {string}
     * @returns {TagData}
     */
    Link(value){
        this.link = value;
        return this;
    }
}