import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircle, faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import {faFileWord, faPlus} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import Button from "../../Utils/Inputs/Button.js";
import PropTypes from "prop-types"
import {PerformanceMeasurementLogger} from "../../../Utils/PerformanceMeasurementLogger/PerformanceMeasurementLogger";
import RestUtils from "../../../Utils/RestUtils/RestUtils";
import Loading from "../../Misc/LoadingCircle/Loading";
import "./Overview.css"
import "../../Utils/Inputs/Button.css";
import "../../Utils/tables.css"
import {DATA_SAVE_TYPES} from "./FlexIA";
import {ProtocolGenerator} from "./Protocol/ProtocolGenerator";

library.add(faCircle, faCheckCircle, faTimesCircle, faPlus, faFileWord);

class Overview extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            processes: [],
            editingProcessNameId: null,
            editingProcessNameContent: "",
        }
    }

    async componentDidMount() {
        try {
            let processIds = await this.getProcessIds();
            let processes = await Promise.all(processIds.map(async (processId)=>{
                return await this.getProcess(processId);
            }))
            this.setState({
                loading: false,
                processes: processes
            })
        }
        catch(err){
            console.error("Could not retrive Processes", err);
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.analysisId === this.props.analysisId)
            return;

        try {
            this.setState({loading: true});
            let processIds = await this.getProcessIds();
            let processes = await Promise.all(processIds.map(async (processId)=>{
                return await this.getProcess(processId);
            }))
            this.setState({
                loading: false,
                processes: processes
            })
        }
        catch(err){
            console.error("Could not retrive Processes", err);
        }
    }

    async getProcess(processId) {
        let per = new PerformanceMeasurementLogger()
        let response = await RestUtils.Get("/tools/flexia/" + this.props.analysisId + "/" + processId);
        let process = await response.json();
        process.analysisId = process.analysis_id;
        per.log("GetProcess:");
        return process;
    }
    async getProcessIds() {
        let per = new PerformanceMeasurementLogger()
        let response = await RestUtils.Get("/tools/flexia/" + this.props.analysisId + "/Processes");
        response = await response.json();
        per.log("GetProcessIds:");
        return response.data;
    }

    async putProcess(name, department, description) {
        let process = {
            name: name,
            department: department,
            description: description,
        };
        let response = await RestUtils.Put("/tools/flexia/" + this.props.analysisId + "/Process", process);
        let res = await response.json();
        res.statusCode = await response.status;
        return await res;
    }

    async postProcess(name, department, description, processId) {
        let process = {
            name: name,
            department: department,
            description: description,
        };
        let response = await RestUtils.Post("/tools/flexia/" + this.props.analysisId + "/" + processId, process);
        let res = await response.json();
        res.statusCode = await response.status;
        return res;
    }

    activateProcessNameChanging(process) {
        this.setState({
            editingProcessNameId: process.id,
            editingProcessNameContent: process.name
        })
    }
    async updateProcessName(newName, processId){
        let process = this.state.processes.find((process)=>processId===process.id);
        process.name = newName;
        this.props.onSavedStarted();
        let response = await this.postProcess(process.name, process.department, process.description, processId);
        this.props.onSaveFinished({
            saving: false,
            lastSave: new Date(),
            restStatus: response.statusCode,
            retryFkt: ()=>{
                this.updateProcessName(newName, processId);
            },
            dataType: DATA_SAVE_TYPES.PROCESS,
        });
        this.setState(oldState=>{
            return {
                processes: oldState.processes.map((oldProcess)=>{
                    if(oldProcess.id === processId) return process;
                    else return oldProcess
                }),
                editingProcessNameId: null,
                editingProcessNameValue: ""
            }
        })


    }
    textInputOnKeyUp(event, process) {
        if(event.key === "Enter") {
            if(process.id === this.state.editingProcessNameId){
                this.updateProcessName(event.target.value, process.id).then(()=>{
                    //Nothing to do here, just preventing warning :P
                });
            }
        }
    }
    textInputOnBlur(event, process) {
        this.setState({
            editingProcessNameId: null,
        })
    }

    async addNewProcess(){
        this.props.onSavedStarted();
        let newProcess = {
            analysisId: this.props.analysisId,
            department: "Nicht angegeben",
            description: "Keine Beschreibung vorhanden",
            name: "Neuer Prozess"
        };

        let response = await this.putProcess(newProcess.name, newProcess.department, newProcess.description, newProcess.analysisId)
        newProcess.id = response.id;
        this.props.onSaveFinished({
            saving: false,
            lastSave: new Date(),
            restStatus: response.statusCode,
            retryFkt: ()=>{
                this.addNewProcess(this.props.analysisId);
            },
            dataType: DATA_SAVE_TYPES.NEW_PROCESS,
        });
        this.setState(oldState=>{
            return {
                processes: [...oldState.processes, newProcess],
                editingProcessNameId: newProcess.id,
                editingProcessNameValue: "Neuer Prozess"
            }
        });
    }

    generateInterviewCheckBox(process) {
        if (process.checkedInterview === true) {
            return <FontAwesomeIcon icon={faCheckCircle} onClick={()=>{process.checkedInterview = false; this.props.processChanged(process);}}/>;
        }
        else if (process.checkedInterview === false) {
            return <FontAwesomeIcon icon={faTimesCircle} onClick={()=>{process.checkedInterview = null; this.props.processChanged(process);}}/>;
        }
        else {
            return <FontAwesomeIcon icon={faCircle} onClick={()=>{process.checkedInterview = true; this.props.processChanged(process);}}/>;
        }

    }
    generateMatrixCheckBox(process) {
        if (process.checkedMatrix === true) {
            return <FontAwesomeIcon icon={faCheckCircle} onClick={()=>{process.checkedMatrix = false; this.props.processChanged(process);}}/>;
        }
        else if (process.checkedMatrix === false) {
            return <FontAwesomeIcon icon={faTimesCircle} onClick={()=>{process.checkedMatrix = null; this.props.processChanged(process);}}/>;
        }
        else {
            return <FontAwesomeIcon icon={faCircle} onClick={()=>{process.checkedMatrix = true; this.props.processChanged(process);}}/>;
        }
    }
    generateInfoCheckBox(process) {
        if (process.checkedInfofluss === true) {
            return <FontAwesomeIcon icon={faCheckCircle} onClick={()=>{process.checkedInfofluss = false; this.props.processChanged(process);}}/>;
        }
        else if (process.checkedInfofluss === false) {
            return <FontAwesomeIcon icon={faTimesCircle} onClick={()=>{process.checkedInfofluss = null; this.props.processChanged(process);}}/>;
        }
        else {
            return <FontAwesomeIcon icon={faCircle} onClick={()=>{process.checkedInfofluss = true; this.props.processChanged(process);}}/>;
        }
    }

    render(){
        return(
            <div className={"Overview"}>
                <div className={"stepHeader"}>Detailaufnahme </div>
                {this.state.loading ? <div className={"LoadingWrapper"}><Loading/></div> :
                    <table>
                        <thead>
                        <tr>
                            {this.state.processes.map((process)=>{
                                return <td key={process.id} className={"table-header"} onClick={()=>{this.activateProcessNameChanging(process)}}>
                                    {process.id === this.state.editingProcessNameId ? <div>
                                        <input type={"text"}
                                               defaultValue={process.name}
                                               onKeyUp={(event)=>{this.textInputOnKeyUp(event, process)}}
                                               onBlur={(event)=>{this.textInputOnBlur(event, process)}}
                                               autoFocus={true}
                                        />
                                    </div> : <div className={"hiddenTextEditField"}>{process.name}</div> }
                                </td>
                            })}
                            <td className={"table-header"}><div className={"cursorPointer"} onClick={()=>{
                                this.addNewProcess().then(()=>{
                                    //Nothing to do here, just avoiding warning 😇
                                })
                            }}> <FontAwesomeIcon icon={faPlus}/> Prozess hinzufügen </div> </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {this.state.processes.map((process)=>{
                                return <td key={process.id}><span className={"table-row"}>
                                    <div onClick={()=>{this.props.clickedMatrix(process)}} className={"partOfProcess cursorDefault"}>Matrix</div>
                                </span></td>
                            })}
                        </tr>
                        <tr>
                            {this.state.processes.map((process)=>{
                                return  <td key={process.id}><span className={"table-row"}>
                                    <div className={"partOfProcess cursorDefault"}
                                         onClick={()=>{this.props.clickedDiagram(process)}}>
                                        Informationsfluss
                                    </div>
                                </span></td>
                            })}
                        </tr>
                        </tbody>
                    </table>
                }

                <div className={"navigation"}>
                    <div className={"stepTwo"}>
                        <div className={"stepHeader"}>Auswerten und Analysieren </div>
                        <Button name={""}
                                onClick={()=>{this.props.clickedGenerateProtocol()}}
                        >
                            <FontAwesomeIcon icon={faFileWord}/> <span style={{marginLeft: 10}}>Protokollvorlage generieren</span>
                        </Button>
                    </div>
                </div>
            </div>)
    }
}

//Property Documentation
Overview.propTypes = {
};

export default Overview;
