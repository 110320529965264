/**
 * Created by Sebastian Venhuis on 13.07.2020.
 */

import React from "react"
import "./Popup.css"
import PropTypes from 'prop-types';

class PopupClass extends React.Component{
    constructor(props) {
        super(props);

    }

    render(){
        return (<div className={"PopupContainer"} onClick={()=>{if(this.props.onOutsideClick) this.props.onOutsideClick()}}>
            <div className={"Popup"} onClick={(event)=>{event.stopPropagation()}}>
                {this.props.children}
            </div>
        </div>)
    }

}

PopupClass.propTypes = {
    onOutsideClick: PropTypes.func
}

export const Popup = PopupClass
