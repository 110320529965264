/**
 * Created by Sebastian Venhuis on 10.02.2020.
 */

export default class RestOptions {
    /**
     * {String} A Prefix for all Callbacks
     */
    DefaultUrl;
    /**
     * {function} Callback that can be called if a Authentificated error occured
     */
    NotAuthenticatedCallback;

    /**
     * Creates a new RestOptions Object. This is used to store Options for the RestUtils class
     * @param defaultUrl {String}
     * @param notAuthenticatedCallback {function}
     */
    constructor(defaultUrl, notAuthenticatedCallback){
        this.DefaultUrl = defaultUrl;
        this.NotAuthenticatedCallback = notAuthenticatedCallback;
    }
}