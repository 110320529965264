import React from "react";
import "./Tag.css"
import PropTypes from "prop-types"

export default class Tag extends React.Component {
    render(){
        return (
            <div className={"Tag"} style={this.props.color ? {backgroundColor:this.props.color} : {}}>
                {this.props.label}
            </div>
        );
    }
}

Tag.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string.isRequired,
};