import React from "react"
import {Route} from "react-router-dom";
import FlexIA from "../tools/flexia/FlexIA"
import "./ToolView.css"

export default class ToolView extends React.Component{
    render(){
        return(
            <div className={"ToolView"}>
                <Route path={"/tool/informationsanalyse"} component={FlexIA}></Route>
            </div>
        )
    }
}