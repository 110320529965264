/**
 * Created by Sebastian Venhuis on 20.11.2020.
 */


import {connect} from "react-redux";
import React from "react";
import {AdministrationGetUserRoles} from "../../../../State/Selectors/AdministrationSelectors/usersSelector";
import {
    AdministrationGetRoles,
    AdministrationRolesLoading
} from "../../../../State/Selectors/AdministrationSelectors/rolesSelector";
import {GetRolesAsync} from "../../../../State/Actions/Administration/rolesAdministionActions";
import Loading from "../../../Misc/LoadingCircle/Loading";
import "../AdministrationForm.css"
import {
    addUserRoleAsync,
    removeUserRoleAsync
} from "../../../../State/Actions/Administration/userAdministrationActions";
import Button from "../../../Utils/Inputs/Button";

class UserRolesClass extends React.Component{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.GetRolesAsync();
    }

    render(){
        if(this.props.rolesLoading)
            return <div className={"UserRoles"}>
                <Loading/>
            </div>

        console.log("Roles", this.props.roles);

        return <div className={"UserRoles"}>
            <div className={"content"}>
                <div className={"AdministrationForm"}>
                    <header>Rollen ändern</header>
                    <div className={"content"}>
                        {
                            this.props.roles.map((role)=>{
                                let userHasRole = this.props.userRoles.includes(role.id);
                                return <div key={role.id}>
                                    <input type={"checkbox"} checked={userHasRole} onClick={()=>{
                                        if(userHasRole)
                                            this.props.removeUserRoleAsync(this.props.userId, role.id);
                                        else
                                            this.props.addUserRoleAsync(this.props.userId, role.id)
                                    }
                                    }/><span>{role.displayName}</span>
                                </div>
                            })
                        }
                    </div>
                    <footer>
                        <Button link={"/Administration/Benutzerverwaltung"} >Zurück</Button>
                    </footer>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state, props)=>{
    let userId = props.match.params.userId
    return {
        userId,
        userRoles: AdministrationGetUserRoles(state, userId) ?? [],
        roles: AdministrationGetRoles(state),
        rolesLoading: AdministrationRolesLoading(state)
    }
}

const mapActionsToProps = {
    GetRolesAsync,
    removeUserRoleAsync,
    addUserRoleAsync
}

export const UserRoles = connect(mapStateToProps, mapActionsToProps)(UserRolesClass)
