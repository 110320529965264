export function GetCurrentPhase(state){
    return state.view.phaseView.currentPhase;
}

export function GetProjectDoneTools(state){
    return state.view.projectView.done;
}
export function GetProjectCurrentTools(state){
    return state.view.projectView.current;
}
export function GetProjectSuggestedTools(state){
    return state.view.projectView.suggestions;
}