import React from "react";
import Sidebar from "../Utils/Sidebar/Sidebar";

import "./HomeView.css"
import "./MainView.css"
import Search from "../Utils/Inputs/Search";
import CardStack from "../Cards/CardStack";
import CardData from "../Cards/CardData";
import Information from "../Utils/Information/Information";
import TagData from "../Utils/Tags/TagData";
import {connect} from "react-redux";
import {GetCards} from "../../State/Selectors/dataSelectors";
import {UserIsLoggedIn} from "../../State/Selectors/authSelectors";

class HomeView extends React.Component{

    GetCardsInPhase(phase){
        return this.props.cards.filter((card)=>{return card.phaseId === phase})
    }

    render() {
        return(
            <div className={"MainView HomeView"}>
                <Sidebar className={"left"} title={"Empfehlungen"}>

                </Sidebar>
                <div className={"content"}>
                    <div className={"WelcomeMessage"}>Herzlich Willkommen im FlexDeMo Toolkit</div>
                    <div className={"SearchContainer"}>
                        <Search placeholder={" ..."+["Zielsystembestimmung", "Simulation", "Layoutplannung", "Projektaufstellung"][Math.floor(Math.random()*4)]+"..."}/>
                    </div>
                    <div className={"Overview"}>
                        {[0, 1, 2, 3].map((phaseId)=>{
                            return (
                                <div className={"Phase"} key={phaseId}>
                                    <CardStack cards={this.GetCardsInPhase(phaseId)}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Sidebar className={"right"} title={"Infobar"}>
                    {!this.props.userIsLoggedIn ?
                        <Information label={"Speichern nicht möglich"}>
                            Aktuell können keine Daten gespeichert werden. Bitte legen Sie ein Benutzerkonto an.
                        </Information> :
                        null}
                </Sidebar>
            </div>
        )
    }
}

const mapStateToProps = state=>{
    return {
        cards: GetCards(state),
        userIsLoggedIn: UserIsLoggedIn(state)
    }
}

export default connect(mapStateToProps)(HomeView)