
import flexdemoReducer from "./Reducers/flexdemoReducer";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools, devToolsEnhancer} from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import GlobalErrorHandler from "./Actions/GlobalErrorHandlerAction";

const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({ onError: GlobalErrorHandler });

//const store = createStore(flexdemoReducer,  /* preloadedState, */ devToolsEnhancer({}));
const store = createStore(flexdemoReducer, /* preloadedState, */ composeWithDevTools(applyMiddleware(errorHandlerMiddleware, thunk)));
//For Redux Extension see: https://github.com/zalmoxisus/redux-devtools-extension

export default  store;