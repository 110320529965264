export const PHASE_VIEW_SET_CURRENT_PHASE = "PHASE_VIEW_SET_CURRENT_PHASE";

export function PhaseViewSetCurrentPhase(phase) {
    return {
        type: PHASE_VIEW_SET_CURRENT_PHASE,
        data: {
            phase: phase
        }
    }
}
