/**
 * Created by Sebastian Venhuis on 10.11.2020.
 */
import {ADD_ERROR, REMOVE_ERROR} from "../Actions/errorsActions";

let initialState = {
    errors: []
};

export function errorsReducer(state = initialState, action){
    let data = action.data;
    switch(action.type){
        case ADD_ERROR:
            return {
                errors: [...state.errors, data]
            }
        case REMOVE_ERROR:
            return{
                errors: state.errors.filter((error)=>error.id !== data.id)
            }
        default:
            return state;
    }
}
