import React from "react";
import {v4 as uuid} from "uuid"
import ThemeContext from "../../../ThemeContext"
import {library} from "@fortawesome/fontawesome-svg-core";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FlexiaSwitchSidebars} from "../../../State/Actions/view/tools/flexiaActions";
import {connect} from "react-redux";
import InfoIcon from "../../Utils/Information/InfoIcon.js";
import "./InfoMatrix.css"
import "../../Utils/tables.css"
import "../../Utils/Information/InfoIcon.css";
import RestUtils from "../../../Utils/RestUtils/RestUtils";
import {DATA_SAVE_TYPES} from "./FlexIA";
import Loading from "../../Misc/LoadingCircle/Loading";

//todo: autofocus im textarea fehlerhaft: cursor springt wieder an die erste stelle statt hinter den text
//todo: bedienungstext anpassen

library.add(faMinus);

const ROW_PROPERTIES = {
    ACTIVITY: "activity",
    INFO_NAME: "infoName",
    INFO_RESOURCE: "infoResource",
    INFO_PROCESS_PROPERTIES: "infoProcessProperties",
    EVENTS: "events"
};

class InfoMatrix extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            rows: null,
            loading: true,
            autoFocus: null,
        }
    }

    async componentDidMount() {
        this.props.FlexiaSwitchSidebars(true);
        try{
            let matrixRows = await this.getMatrixRows(this.props.processId, this.props.analysisId);
            this.setState({
                rows: matrixRows,
                loading: false
            });
        }
        catch (err){
            console.error("Could not load Matrix", err);
        }

    }

    static contextType = ThemeContext;

    async putMatrixRow(activity, name, resource, processProperties, disordersPotential, processId, analysisId, order) {
        let matrixRow = {
            activity: activity,
            name: name,
            resource: resource,
            processProperties: processProperties,
            disordersPotential: disordersPotential,
            order: order,
        };
        let response = await RestUtils.Put("/tools/flexia/" + analysisId + "/" + processId + "/Matrixrow", matrixRow);
        let res = await response.json();
        res.statusCode = response.status;
        return res;
    }

    async postMatrixRow(activity, name, resource, processProperties, disordersPotential, rowId, processId, analysisId, order) {
        let matrixRow = {
            activity: activity,
            name: name,
            resource: resource,
            processProperties: processProperties,
            disordersPotential: disordersPotential,
            order: order
        };
        let response = await RestUtils.Post("/tools/flexia/" + analysisId + "/" + processId + "/Matrixrow/" + rowId, matrixRow);
        let res = await response.json();
        res.statusCode = response.status;
        return res;
    }

    async deleteMatrixRow(rowId) {
        this.props.onSavedStarted();
        let response = await RestUtils.Delete("/tools/flexia/" + this.props.analysisId +"/" + this.props.processId + "/Matrixrow/" + rowId);
        this.props.onSaveFinished({
            saving: false,
            lastSave: new Date(),
            restStatus: response.status,
            retryFkt: ()=>{
                this.deleteMatrixRow(rowId);
            },
            dataType: DATA_SAVE_TYPES.MATRIX_ROW
        })
    }

    async getMatrixRows(processId, analysisId) {
        let response = await RestUtils.Get("/tools/flexia/"+ analysisId + "/" + processId + "/Matrixrows");
        response = await response.json();
        return response.data.map((listItem)=>{
            return {
                id: listItem.id,
                activity: listItem.activity,
                events: listItem.disordersPotential,
                info: {
                    name: listItem.name,
                    resource: listItem.resource,
                    processProperties: listItem.processProperties,
                },
                order: listItem.order
            };
        }).sort((a, b)=> a.order - b.order);
    }


    async changeMatrixRow(row, matrixAutoFocus) {
        this.props.onSavedStarted();
        let response = await this.postMatrixRow(row.activity, row.info.name, row.info.resource,row.info.processProperties, row.events, row.id, this.props.processId, this.props.analysisId, row.order)
        this.props.onSaveFinished({
            saving: false,
            lastSave: new Date(),
            restStatus: response.statusCode,
            retryFkt: ()=>{
                this.changeMatrixRow(row, matrixAutoFocus);
            },
            dataType: DATA_SAVE_TYPES.MATRIX_ROW,
        });
    }

    async addMatrixRow(row, matrixAutoFocus) {
        this.props.onSavedStarted();
        let response = await this.putMatrixRow(row.activity, row.info.name, row.info.resource, row.info.processProperties, row.events, this.props.processId, this.props.analysisId, row.order);
        this.props.onSaveFinished({
            saving: false,
            lastSave: new Date(),
            restStatus: response.statusCode,
            retryFkt: ()=>{
                this.addMatrixRow(row, matrixAutoFocus);
            },
            dataType: DATA_SAVE_TYPES.NEW_MATRIX_ROW,
        })
    }

    changeRowProp(value, id, prop, autoFocus){
        let newRowAdded = false;
        let changedRow = this.state.rows.find(row=>row.id === id);
        //create new Row if needed:
        if (changedRow === undefined) {
            let maxOrder = 0;
            this.state.rows.forEach((row)=>{
                if(row.order>maxOrder)
                    maxOrder=row.order;
            })
            changedRow = {
                id: id,
                activity: "",
                info: {
                    name: "",
                    resource: "",
                    processProperties: ""
                },
                events: "",
                order: maxOrder+1
            };
            newRowAdded = true;
        }
        //update changed property:
        switch(prop) {
            case ROW_PROPERTIES.ACTIVITY:
                changedRow.activity = value;
                break;
            case ROW_PROPERTIES.INFO_NAME:
                changedRow.info.name = value;
                break;
            case ROW_PROPERTIES.INFO_RESOURCE:
                changedRow.info.resource = value;
                break;
            case ROW_PROPERTIES.INFO_PROCESS_PROPERTIES:
                changedRow.info.processProperties = value;
                break;
            case ROW_PROPERTIES.EVENTS:
                changedRow.events = value;
                break;
            default:
                console.log("wrong usage of changedRowProp(): selected prop does not exist");
                break;
        }
        //save to server and re-render:
        if (!autoFocus) {
            autoFocus = {rowId: changedRow.id, target: prop};
        }

        if (newRowAdded === true) {
            this.addMatrixRow(changedRow, autoFocus);
            this.setState(oldState=>{return {rows: [...oldState.rows, changedRow]}})
        }
        else {
            this.changeMatrixRow(changedRow, autoFocus);
            this.setState(oldState=>{
                let newRows = [...oldState.rows];
                let index = newRows.findIndex((oldRow)=>oldRow.id === changedRow.id);
                newRows[index] = changedRow;
                return{
                    rows: newRows
                }

            })
        }
    }

    hasAutoFocus(rowId, prop) {
        if (this.state.autoFocus && this.state.autoFocus.rowId === rowId && this.state.autoFocus.target === prop) {
            return true;
        }
        else return false;
    }

    renderRow(row, nextRowId){
        let emptyRow = false;
        if(row === undefined || row === null) {
            row = {
                id: uuid(),
                activity: "",
                info: {
                    name: "",
                    resource: "",
                    processProperties: ""
                },
                events: "",
                processId: this.state.processId,
            };
            emptyRow = true;
        }
        return(
            <tr key={row.id}>
                <td className={"table-border-right"}>
                    <input type={"text"}
                           defaultValue={row.activity}
                           onKeyUp={(event)=>{
                               if (event.key === "Escape") {
                                   event.currentTarget.value = row.activity;
                               }
                               else if (event.key === "Enter") {
                                   this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.ACTIVITY);
                               }
                           }}
                           onKeyDown={(event)=>{
                               if (event.key === "Tab") {
                                   this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.ACTIVITY, {rowId: row.id, target: ROW_PROPERTIES.INFO_NAME});
                               }
                           }}
                           onBlur={(event)=>{
                               this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.ACTIVITY, {rowId: row.id, target: ROW_PROPERTIES.INFO_NAME});
                           }}
                           autoFocus={this.hasAutoFocus(row.id, ROW_PROPERTIES.ACTIVITY)}
                    />
                </td>
                <td>
                    <input type={"text"}
                           disabled={emptyRow}
                           defaultValue={row.info.name}
                           onKeyUp={(event)=>{
                               if (event.key === "Escape") {
                                   event.currentTarget.value = row.info.name;
                               }
                               else if (event.key === "Enter") {
                                   this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_NAME);
                               }
                           }}
                           onKeyDown={(event)=>{
                               if (event.key === "Tab") {
                                   this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_NAME, {rowId: row.id, target: ROW_PROPERTIES.INFO_RESOURCE});
                               }
                           }}
                           onBlur={(event)=>{
                               this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_NAME, {rowId: row.id, target: ROW_PROPERTIES.INFO_RESOURCE});
                           }}
                           autoFocus={this.hasAutoFocus(row.id, ROW_PROPERTIES.INFO_NAME)}
                    />
                </td>
                <td>
                    <input type={"text"}
                           disabled={emptyRow}
                        defaultValue={row.info.resource}
                        onKeyUp={(event)=>{
                            if (event.key === "Escape") {
                                event.currentTarget.value = row.info.resource;
                            }
                            else if (event.key === "Enter") {
                                this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_RESOURCE);
                            }
                            else {
                            }
                        }}
                        onKeyDown={(event)=>{
                           if (event.key === "Tab") {
                               this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_RESOURCE, {rowId: row.id, target: ROW_PROPERTIES.INFO_PROCESS_PROPERTIES});
                           }
                        }}
                        onBlur={(event)=>{
                            this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_RESOURCE, {rowId: row.id, target: ROW_PROPERTIES.INFO_PROCESS_PROPERTIES});
                        }}
                        autoFocus={this.hasAutoFocus(row.id,ROW_PROPERTIES.INFO_RESOURCE)}
                    />
                </td>
                <td className={"table-border-right"}>
                    <textarea
                        disabled={emptyRow}
                        defaultValue={row.info.processProperties}
                        onKeyUp={(event)=>{
                           if (event.key === "Escape") {
                               event.currentTarget.value = row.info.processProperties;
                           }
                           else if (event.key === "Enter") {
                               this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_PROCESS_PROPERTIES);
                           }
                           else {
                           }
                        }}
                        onKeyDown={(event)=>{
                           if (event.key === "Tab") {
                               this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_PROCESS_PROPERTIES, {rowId: row.id, target: ROW_PROPERTIES.EVENTS});
                           }
                        }}
                        onBlur={(event)=>{
                            this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.INFO_PROCESS_PROPERTIES, {rowId: row.id, target: ROW_PROPERTIES.EVENTS});
                        }}
                        autoFocus={this.hasAutoFocus(row.id, ROW_PROPERTIES.INFO_PROCESS_PROPERTIES)}
                    />
                </td>
                <td className={"table-border-right"}>
                    <textarea
                        disabled={emptyRow}
                       defaultValue={row.events}
                       onKeyUp={(event)=>{
                           if (event.key === "Escape") {
                               event.currentTarget.value = row.events;
                           }
                           else if (event.key === "Enter") {
                               this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.EVENTS);
                           }
                           else {
                           }
                       }}
                       onKeyDown={(event)=>{
                           if (event.key === "Tab") {
                               this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.EVENTS, {rowId: nextRowId, prop: ROW_PROPERTIES.ACTIVITY});
                           }
                       }}
                       onBlur={(event)=>{
                           this.changeRowProp(event.currentTarget.value, row.id, ROW_PROPERTIES.EVENTS, {rowId: nextRowId, prop: ROW_PROPERTIES.ACTIVITY});
                       }}
                       autoFocus={this.hasAutoFocus(row.id, ROW_PROPERTIES.EVENTS)}
                    />
                </td>
                {emptyRow ? <td className={"deleteIcon"}/> :
                    <td className={"deleteIcon"}>
                        <div className={"deleteButton"} onClick={() => {
                            this.deleteMatrixRow(row.id).then(()=>{
                                this.setState(oldState=> {
                                    let newRows = oldState.rows.filter((oldRow) => row.id !== oldRow.id);
                                    return {
                                        rows: newRows
                                    }
                                })
                            });
                        }}><FontAwesomeIcon icon={faTrashAlt}/></div>
                    </td>
                }
            </tr>
        );
    }

    render() {
        if (this.state.loading) {
            return <div className={"LoadingWrapper"}><Loading/></div>
        }
        else {
            return <div className={"infoMatrix"}>
                <div className={"matrix"}>
                    <table>
                        <thead>
                        <tr className={"firstHeaderRow"}>
                            <td className={"table-border-right"}>Analyseobjekt</td>
                            <td colSpan={3} className={"table-border-right"}>Informationsobjekt</td>
                            <td colSpan={1} className={".table-border-right"}>Aktivitätsauffälligkeiten</td>
                        </tr>
                        <tr className={"secondHeaderRow"}>
                            <td className={"table-border-right activity"}> Aktivität</td>
                            <td>Name</td>
                            <td>
                                <div className={"infoIconWrapper"}>
                                    Ressource
                                    <InfoIcon className={"info"}>
                                        Erfassen Sie die Informationsquelle. Z.B. Papierdokument, ERP-System, etc.
                                    </InfoIcon>
                                </div>
                            </td>
                            <td className={"table-border-right"}>
                                Wichtige Informationen
                            </td>
                            <td className={".table-border-right events"}>
                                <div className={"infoIconWrapper"}>
                                    Störereignisse/ Potentiale
                                    <InfoIcon  className={"info"}>
                                        Erfassen Sie die Eigenschaft(en) der Informationsobjekte mit der aktiv gearbeitet wird.
                                    </InfoIcon>
                                </div>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.rows.map((row)=>{
                                return this.renderRow(row);
                            })
                        }
                        {
                            this.renderRow()
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        }
    }
}


const mapActionsToProps = {
    FlexiaSwitchSidebars
};
export default connect(null, mapActionsToProps)(InfoMatrix)


