import CardData from "../../../Components/Cards/CardData";
import TagData from "../../../Components/Utils/Tags/TagData";
import { GetInformationsanalyseContent } from '../../../Mock/DemoData/fakedata';

import { LoremIpsum } from "lorem-ipsum";
const lorem = new LoremIpsum({
    sentencesPerParagraph: {
        max: 8,
        min: 4
    },
    wordsPerSentence: {
        max: 12,
        min: 10
    }
});

let initialState = [
    // 0
    new CardData("b864c391-795d-43d0-ad00-22ce3f5bfb71", 0, "fceccb79-93e6-4603-9dbb-1cdddc87a9f2", "projectaufstellung")
        .Title("Projektaufstellung")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
        .SetContent(),
    // 1
    new CardData("37d380f8-0fa8-4df2-8c39-662bebb5f5ad", 0, "fceccb79-93e6-4603-9dbb-1cdddc87a9f2", "digitalisierungscheck")
        .Title("Digitalisierungscheck")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
        .SetTool(),
    // 2
    new CardData("1893d9ce-fb5f-4428-8fd7-6fb44b8cb56c", 1, "6e48a738-0721-4f36-971c-7cc3790cea98", "wertstromanalyse")
        .Title("Wertstromanalyse")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
        .SetTool(),
    // 3
    new CardData("ee96c8b1-0435-46e5-9609-fb67ad740848", 1, "6e48a738-0721-4f36-971c-7cc3790cea98", "informationsanalyse")
        .Title("Informationsanalyse")
        .Short("Die Informationsflussanalyse ist eine Möglichkeit den Fluss der Informationen in einem Prozess abzubilden. Hiebei wird die Quelle und das Ziel der Information abgebildet.")
        .SuggestedCards(["37d380f8-0fa8-4df2-8c39-662bebb5f5ad", "1893d9ce-fb5f-4428-8fd7-6fb44b8cb56c"])
        .SetContent(GetInformationsanalyseContent())
        .SetTool(),
    // 4
    new CardData("7f7a6213-e0b8-4d3e-aa54-d7abfc4e6c11", 1, "6e48a738-0721-4f36-971c-7cc3790cea98", "dempgraphieanalyse")
        .Title("Demographieanalyse")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
        .SetTool()
        .SetImage("/Images/demografie.jpg"),
    // 5
    new CardData("b2b1c5fb-6313-4b3b-9ed7-3ae9d06b6614", 1, "6e48a738-0721-4f36-971c-7cc3790cea98", "zielsystem")
        .Title("Zielsystembestimmung")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
        .SetTool(),
    // 6
    new CardData("34f29dd6-d450-47eb-8e60-4d9f83951bcf", 2, "a8bc3336-9fea-4647-b721-a8038733af42", "simulation")
        .Title("Simulation")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
        .SetTool(),
    // 7
    new CardData("9907b565-4aa9-428b-bd6a-4f5d81bc960e", 2, "a8bc3336-9fea-4647-b721-a8038733af42", "layoutplanung")
        .Title("Layoutplanung")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
        .SetTool()
        .SetImage("/Images/blueprint.jpg"),
    // 8
    new CardData("fd106ef9-db83-49e9-b1bd-1d6ecba0e39d", 3, "e4057aa9-9bca-4810-b37f-5c0454b50dd2", "cardboardengineering")
        .Title("Cardboard Engineering")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
        .SetImage("/Images/cardboard.jpg"),
    // 9
    new CardData("465527b3-5164-4a6d-a546-2060083365e6", 3, "e4057aa9-9bca-4810-b37f-5c0454b50dd2", "3dprinting")
        .Title("3D Druck")
        .Short(lorem.generateSentences(2))
        .SetContent(GetInformationsanalyseContent())
];

export default function cardsReducer(state=initialState, action){
    switch(action.type) {
        default:
            return state
    }
}