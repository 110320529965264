/**
 * Created by Sebastian Venhuis on 19.10.2020.
 */



export class PerformanceMeasurementLogger {

    static enabled = false;

    /**
     * Initializes the PerformanceMeasurementLogger
     * @param enable {boolean}  If false, disables the time Measurements and the logging output
     */
    static initialize(enable){
        this.enabled = enable;
    }

    startTime;

    constructor(){
        this.reset();
    }

    /**
     * Resets the timer to now;
     */
    reset(){
        if(PerformanceMeasurementLogger.enabled)
            this.startTime = performance.now();
    }

    /**
     * Logs the Output to the console and inserts the current time since the last Reset (or construnction)
     * @param output {string} The string that will be outputed. The time till now will be inserted at %TIME or if not present at the end. The Start Time can be inserted with %START_TIME
     */
    log(output){
        if(!PerformanceMeasurementLogger.enabled)
            return;
        if(output.includes("%TIME"))
            output = output.replaceAll("%TIME", `(${performance.now()-this.startTime})`);
        else{
            output = `${output} (${performance.now()-this.startTime})`;
        }
        output = output.replaceAll("%START_TIME", `(${this.startTime})`);
        console.log(output);

    }

}
