import "./DeleteProcessesPopUp.css";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import Button from "../../Utils/Inputs/Button.js";
import "../../Utils/Inputs/Button.css";
import {PerformanceMeasurementLogger} from "../../../Utils/PerformanceMeasurementLogger/PerformanceMeasurementLogger";
import RestUtils from "../../../Utils/RestUtils/RestUtils";
import Loading from "../../Misc/LoadingCircle/Loading";

export default class DeleteProcessesPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            processes: []
        }
    }

    async componentDidMount() {
        try {
            let processIds = await this.getProcessIds();
            let processes = await Promise.all(processIds.map(async (processId)=>{
                return await this.getProcess(processId);
            }))
            console.log("Processes", processes);
            this.setState({
                loading: false,
                processes: processes
            })
        }
        catch(err){
            console.error("Could not retrive Processes", err);
        }
    }


    async getProcess(processId) {
        let per = new PerformanceMeasurementLogger()
        let response = await RestUtils.Get("/tools/flexia/" + this.props.analysisId + "/" + processId);
        let process = await response.json();
        process.analysisId = process.analysis_id;
        per.log("GetProcess:");
        return process;
    }
    async getProcessIds() {
        let per = new PerformanceMeasurementLogger()
        let response = await RestUtils.Get("/tools/flexia/" + this.props.analysisId + "/Processes");
        response = await response.json();
        per.log("GetProcessIds:");
        return response.data;
    }


    async deleteProcess(processId) {
        await RestUtils.Delete("/tools/flexia/" + this.props.analysisId + "/" + processId);
    }

    render() {
        return <div className={"deleteProcessesBackground"}>
            <div className={"deleteProcessesPopUp"}>
                <div className={"deleteProcessesHeader"}> Welche Prozesse möchten sie löschen?</div>
                <div className={"deleteProcessesList"}>
                    {this.state.loading ? <div className={"LoadingWrapper"}/> :
                        this.state.processes.map((process) => {
                            return <div className={"deleteProcessesListItem"} key={process.id}>
                                <div className={"deleteProcessesListItemName"}> {process.name} </div>
                                <div className={"deleteProcessesListItemIcon"} onClick={() => {
                                    this.deleteProcess(process.id).then(()=>{
                                        this.props.onClose()
                                    });
                                }}><FontAwesomeIcon icon={faTrashAlt}/></div>
                            </div>
                        })
                    }
                </div>
                <div className={"deleteProcessesCloseButton"}>
                    <button onClick={() => {
                        this.props.onClose()
                    }}>
                        Schließen
                    </button>
                </div>
            </div>
        </div>
}
}