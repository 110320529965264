/**
 * Created by Sebastian Venhuis on 05.03.2021.
 */

import {
    HeadingLevel,
    Packer,
    Paragraph,
    TextRun,
    Document,
    ConcreteNumbering,
    LevelFormat,
    AlignmentType,
    Table, TableRow, TableCell, VerticalAlign, convertMillimetersToTwip
} from "docx"
import RestUtils from "../../../../Utils/RestUtils/RestUtils";
import fileSaver from "file-saver"
import {InformationActivity} from "../FlowDiagram/InfoDiagram";

export class ProtocolGenerator {
    constructor() {
        this.worker = null;
    }

    async generate(analyseID, finishedCallback = ()=>{}, statusCallback = ()=>{}){
        try {
            let analysis = await this.assembleAllData(analyseID, statusCallback)
            let protocolDoc = await this.generateProtocol(analysis, statusCallback)
            statusCallback({step: 3, substep: 1, substepmax: 1, message: "Download wird vorbereitet", submessage: "Renderere Datei"});
            let protocolBlob = await Packer.toBlob(protocolDoc);
            statusCallback({step: 4, substep: 1, substepmax: 1, message: "Download", submessage: "Start"});
            fileSaver(protocolBlob, `Protokoll ${analysis.topic}.docx`, {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8"})
        }
        catch (e){
            finishedCallback(false, e)
        }
        finishedCallback(true)

    }

    async assembleAllData(analyseID, statusCallback = ()=>{}){
        statusCallback({step: 1, substep: 1, substepmax: 4, message: "Daten werden gesammelt", submessage: "Downloading analysis"});
        let analysisResult = await (await RestUtils.Get(`/tools/flexia/${analyseID}`)).json();
        let analysis = {
            createdAt: analysisResult.createdAt,
            description: analysisResult.description,
            id: analysisResult.id,
            topic: analysisResult.topic,
            updatedAt: analysisResult.updatedAt
        }
        //console.log("Generate Protocoll: Analysis details", analysis);
        statusCallback({step: 1, substep: 2, substepmax: 4, message: "Daten werden gesammelt", submessage: "Downloading processes"});
        let processIds = (await (await RestUtils.Get(`/tools/flexia/${analyseID}/Processes`)).json()).data
        //console.log("Generate Protocoll: processIds", processIds);
        analysis.processes = await Promise.all(processIds.map(async processId=>{
            let processResult = await (await RestUtils.Get(`/tools/flexia/${analyseID}/${processId}`)).json()
            return {
                analysisId: processResult.analysis_id,
                department: processResult.department,
                description: processResult.description,
                id: processResult.id,
                name: processResult.name
            }
        }))
        //console.log("Generate Protocoll: processes", analysis.processes);
        statusCallback({step: 1, substep: 3, substepmax: 4, message: "Daten werden gesammelt", submessage: "Downloading Matrix"});
        for(let proc of analysis.processes){
            let matrixRowsResult = await (await RestUtils.Get(`/tools/flexia/${analyseID}/${proc.id}/Matrixrows`)).json();
            proc.matrix = matrixRowsResult.data.map((row)=>{
                return {
                    activity: row.activity,
                    disordersPotential: row.disordersPotential,
                    id: row.id,
                    name: row.name,
                    order: row.order,
                    processId: row.processID,
                    processProperties: row.processProperties,
                    resource: row.resource
                }
            })
        }
        statusCallback({step: 1, substep: 4, substepmax: 4, message: "Daten werden gesammelt", submessage: "Downloading Flow"});
        for(let proc of analysis.processes){
            let flowResult = await (await RestUtils.Get(`/tools/flexia/${analyseID}/${proc.id}/Flows`)).json();
            proc.flow = flowResult.data.map((row)=>{
                row.connections = row.connections.map((connection)=> {
                    return {
                        type: connection.connectionType,
                        to: connection.connectionTo,
                    }
                });
                row.events = row.events.map((event)=>{
                    return {
                        key: event.eventKey,
                        text: event.eventText,
                    }
                });
                row.informationActivity = row.informationActivity.map((activity)=>{
                    return activity.activity;
                });

                return row;
            })
            //console.log("Generate Protocoll: Flow", proc.flow);
        }
        return analysis;
    }

    async generateProtocol(analysis, statusCallback = ()=>{}){
        statusCallback({step: 2, substep: 1, substepmax: 5, message: "Protokol wird generiert", submessage: "Dokument erstellen"});
        let doc = new Document(
           {
            creator: "FlexDeMo Tookit - FlexIA",
            description: "Protokoll einer FlexIA analyse",
            title: `Protokoll ${analysis.topic}`,
            numbering: {
                config: [
                    {
                        reference: "heading-numbering",
                        levels: [
                            {
                                level: 0,
                                format: LevelFormat.DECIMAL,
                                text: "%1.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: 0, hanging: 260 },
                                        heading: HeadingLevel.HEADING_1,
                                        size: 30
                                    },
                                    run: {

                                    }
                                }
                            },
                        ],
                    },
                ],
            },
        }
        );

        doc.addSection({
            children: [
                ...this.generateProtocolTitle(analysis, statusCallback),
                ...this.generateProtocolCurrentDigitalizationState(analysis, statusCallback),
                ...this.generateProtocolAnalysisInformationFlow(analysis, statusCallback),
                ...this.generateProtocolAnalysisResults(analysis, statusCallback),
                ...this.generateProtocolSummary(analysis, statusCallback)
            ]
        })
        return doc;
    }

    generateProtocolTitle(analysis, statusCallback = ()=>{}){
        statusCallback({step: 2, substep: 2, substepmax: 5, message: "Protocol wird generiert", submessage: "Titel erstellen"});
        return [
            new Paragraph({
                text: `Protokoll ${analysis.topic}`,
                heading: HeadingLevel.TITLE
            }),
            new Paragraph({
                children: [
                    new TextRun({
                        text: "<Firma>, <Ort>",
                        color: "818181",
                        size: 28
                    })
                ]

            }),
            new Paragraph(({
                children: [
                    new TextRun({
                        text: `Stand: ${(new Date()).toLocaleDateString("de-DE", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric"
                        })}`,
                        color: "818181",
                        size: 28
                    })
                ]
            })),
            new Paragraph({text: " "}),
            new Paragraph({text: " "})
        ]
    }
    generateProtocolCurrentDigitalizationState(analysis, statusCallback = ()=>{}){
        statusCallback({step: 2, substep: 2, substepmax: 5, message: "Protokol wird generiert", submessage: "DigitalisationsStand"});
        return [
            new Paragraph({
                text: "Aktueller Stand der Digitalisierung",
                heading: HeadingLevel.HEADING_1
            }),
            new Paragraph({
                text: ""
            })
        ]
    }
    generateProtocolAnalysisInformationFlow(analysis, statusCallback = ()=>{}){
        statusCallback({step: 2, substep: 3, substepmax: 5, message: "Protokol wird generiert", submessage: "Informationsflüsse auflisten"});
        return [
            new Paragraph({
                text: "Analyse des Informationsflusses",
                heading: HeadingLevel.HEADING_1,
            }),
            ...analysis.processes.flatMap((proc)=>{
                return [
                    new Paragraph({
                        text: `Informationsquellen und -fluss (${proc.name})`,
                        heading: HeadingLevel.HEADING_2,
                    }),
                    new Paragraph({
                        text:""
                    })
                ]
            })

        ]

    }
    getFilledTableCell(text, cellOptions = {}, paragraphOptions = {}){
        let content = [];
        if(text){
            content.push(new Paragraph({
                text: text,
                ...paragraphOptions
            }))
        }
        const margin = {
            top: convertMillimetersToTwip(1),
            left: convertMillimetersToTwip(1),
            right: convertMillimetersToTwip(1),
            bottom: convertMillimetersToTwip(1)
        }

        return new TableCell({
            margins: margin,
            ...cellOptions,
            children: content
        })
    }
    generateProtocolAnalysisResults(analysis, statusCallback = ()=>{}){



        statusCallback({step: 2, substep: 4, substepmax: 5, message: "Protokol wird generiert", submessage: "Ergebnisse auflisten"});
        return [
            new Paragraph({
                text: "Protokollierung der Ergebnisse",
                heading: HeadingLevel.HEADING_1
            }),
            new Paragraph({
                text: "Zusammenfassung der Analyseergebnisse",
                heading: HeadingLevel.HEADING_2
            }),
            new Table({
                rows: [
                    new TableRow({
                        children: [
                            this.getFilledTableCell("Prozess", {rowSpan: 2, verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.CENTER}),
                            this.getFilledTableCell("Kennzahlen", {columnSpan: 5, verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.CENTER}),
                        ]
                    }),
                    new TableRow({
                        children: [
                            //this.getFilledTableCell(),
                            this.getFilledTableCell("Anzahl Medienbrüche", {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.CENTER}),
                            this.getFilledTableCell("ohne prozessrelevantes Attribut", {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.CENTER}),
                            this.getFilledTableCell("ohne Informationsobjekt", {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.CENTER}),
                            this.getFilledTableCell("Anzahl Bearbeitungen", {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.CENTER}),
                            this.getFilledTableCell("Anzahl Bewegungen", {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.CENTER}),
                        ],
                        tableHeader: true
                    }),
                    ...analysis.processes.map(proc=>{
                        let mediaSwitch = 0;
                        let missingAttribute = 0;
                        let missingInformationObject = 0;
                        let amountEditing = 0;
                        let amountMoves = 0;

                        proc.matrix.forEach(row=>{
                            if(row.processProperties.trim() === "")
                                missingAttribute += 1;
                        })

                        proc.flow.forEach(row=>{
                            if(row.informationObject.trim().length === 0)
                                missingInformationObject +=1;
                            if(row.informationActivity.includes(InformationActivity.TRANSFORM))
                                mediaSwitch +=1;
                            if(row.informationActivity.includes(InformationActivity.EDIT))
                                amountEditing +=1;
                            if(row.informationActivity.includes(InformationActivity.MOVE))
                                amountMoves += 1;
                        })

                        return new TableRow({
                            children: [
                                this.getFilledTableCell(proc.name, {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.RIGHT}),
                                this.getFilledTableCell(mediaSwitch.toString(), {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.RIGHT}),
                                this.getFilledTableCell(missingAttribute.toString(), {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.RIGHT}),
                                this.getFilledTableCell(missingInformationObject.toString(), {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.RIGHT}),
                                this.getFilledTableCell(amountEditing.toString(), {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.RIGHT}),
                                this.getFilledTableCell(amountMoves.toString(), {verticalAlign: VerticalAlign.CENTER}, {alignment: AlignmentType.RIGHT}),
                            ]
                        })
                    }),


                ]
            }),
            new Paragraph({
                text: "Protokoll Ergebnissworkshop",
                heading: HeadingLevel.HEADING_2
            }),
        ]
    }
    generateProtocolSummary(analysis, statusCallback = ()=>{}) {
        statusCallback({step: 2, substep: 5, substepmax: 5, message: "Protokol wird generiert", submessage: "Zusammenfassung erstellen"});
        return [

            new Paragraph({
                text: "Zusammenfassung / Informationsstrategie",
                heading: HeadingLevel.HEADING_1
            }),
        ]
    }
}
