import "./PhaseSelector.css"
import React from "react";
import {connect} from "react-redux";
import {GetCurrentPhase} from "../../../State/Selectors/viewSelectors";
import {PhaseViewSetCurrentPhase} from "../../../State/Actions/view/phaseViewActions";
import PropType from "prop-types"

class PhaseSelector extends React.Component{

    handleOnPhaseClick(phaseIndex){
        console.log("Test");
        this.props.PhaseViewSetCurrentPhase(phaseIndex);
    }

    render(){
        return(
            <div className={"PhaseSelector"}>
                {this.props.phases.map((phase, index)=>{
                    return(
                        <div className={"Phase pointer " + (this.props.currentPhase===index? " selected" : "")} key={phase} onClick={this.handleOnPhaseClick.bind(this, index)}>{phase}</div>
                    )
                })}
            </div>
        )
    }
}

PhaseSelector.propTypes = {
    phases: PropType.arrayOf(PropType.string).isRequired
};

const mapStateToProps = state => {return {currentPhase: GetCurrentPhase(state)}};
const mapDispatchToState = {
    PhaseViewSetCurrentPhase
};

export default connect(mapStateToProps, mapDispatchToState)(PhaseSelector);