import "./PhaseView.css"
import React from "react";
import Sidebar from "../Utils/Sidebar/Sidebar";
import {connect} from "react-redux";
import {GetCurrentPhase} from "../../State/Selectors/viewSelectors";
import PhaseSelector from "../Utils/Inputs/PhaseSelector";
import "../Utils/Utils.css"
import {GetCards, GetTags} from "../../State/Selectors/dataSelectors";
import CardStack from "../Cards/CardStack";
import Card from "../Cards/Card";

class PhaseView extends React.Component{

    constructor(props){
        super(props);

        this.phases = ["Projektaufstellung", "Ist-Analyse", "Simulation", "Durchführung"];
    }

    GetCurrentCards(){
        return this.props.cards.filter((card)=>card.phaseId === this.props.currentPhase);
    }
    GetPreviousCards(){
        return this.props.cards.filter((card)=>card.phaseId === this.props.currentPhase-1);
    }
    GetNextCards(){
        return this.props.cards.filter((card)=>card.phaseId === this.props.currentPhase+1);
    }


    render(){
        return(
            <div className={"MainView PhaseView"}>
                <PhaseSelector phases={this.phases}/>
                <div className={"content"}>
                    {this.props.currentPhase !== 0 ? (
                        <Sidebar title={this.phases[this.props.currentPhase-1] || ""}>
                            <CardStack cards={this.GetPreviousCards()}/>
                        </Sidebar>
                    ): null}
                    <div className={"content"}>
                        <header className={"columnHeader"}>{this.phases[this.props.currentPhase]}</header>
                        <div className={"content"}>
                            {this.GetCurrentCards().map((card)=>{
                                return <Card card={card} key={card.id}/>
                            })}
                        </div>
                    </div>
                    {this.props.currentPhase !== this.phases.length-1 ?
                        <Sidebar title={this.phases[this.props.currentPhase+1] || ""}>
                            <CardStack cards={this.GetNextCards()}/>
                        </Sidebar>  : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentPhase: GetCurrentPhase(state),
        cards: GetCards(state),
        tags: GetTags(state)
    }
};

export default connect(mapStateToProps)(PhaseView);