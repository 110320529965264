/**
 * Created by Sebastian Venhuis on 27.04.2020.
 */


import React, {Component} from "react";
import "./FlowDiagram.css"
import {FlowDiagramRenderer} from "./FlowDiagramRenderer";
import PropTypes from 'prop-types';

class flowDiagram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possibleXCoordinates: [],
            possibleYCoordinates: [],

        }

        this.canvas = React.createRef();
        this.flowDiagramRenderer = FlowDiagramRenderer(this.canvas)
            .onNodeMoved(this.props.onNodeMoved)
            .onEdgeCreated(this.props.onEdgeCreated)
            .onGetDiagramRows(this.props.onGetDiagramRows)
            .onToggleSelfConnection(this.props.onToggleSelfConnection)
            .onReplaceConnection(this.props.onReplaceConnection)
            .onToggleToNextConnection(this.props.onToggleToNextConnection);

    }

    componentDidMount() {
        this.flowDiagramRenderer(this.props.data);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.flowDiagramRenderer.renderUpdate(this.props.data);
    }

    render(){
        return (
            <div className='FlowDiagram' ref={this.canvas} onClick={()=>{this.setState({test: Date.now()})}}/>
        );
    }
}

flowDiagram.propTypes = {
    data: PropTypes.object.isRequired,
    onNodeMoved: PropTypes.func,
    onEdgeCreated: PropTypes.func,
    onGetDiagramRows: PropTypes.func,
    onToggleSelfConnection: PropTypes.func,
    onReplaceConnection: PropTypes.func,
    onToggleToNextConnection: PropTypes.func
}

export const FlowDiagram = flowDiagram;
