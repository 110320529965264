import {PHASE_VIEW_SET_CURRENT_PHASE} from "../../Actions/view/phaseViewActions";

const initialState = {
    currentPhase: 0
};

export default function phaseViewReducer(state = initialState, action){
    switch(action.type){
        case PHASE_VIEW_SET_CURRENT_PHASE:
            return{
                ...state,
                currentPhase: action.data.phase
            };
        default:
            return state;
    }
}