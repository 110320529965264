/**
 * Created by Sebastian Venhuis on 20.11.2020.
 */

import RestUtils from "../../../Utils/RestUtils/RestUtils";
import {addError} from "../errorsActions";

export const SET_ROLES = "ADMINISTRATION_SET_ROLES";
export function setRoles(roles){
    return{
        type: SET_ROLES,
        data: roles
    }
}

export function GetRolesAsync(){
    return async dispatch=>{
        try{
            dispatch(setRolesLoading(true))
            let result = await RestUtils.Get("/roles");
            switch(result.status){
                case 200:
                    let resultData = await result.json();
                    console.log("Received Roles", resultData.roles, resultData, result);
                    dispatch(setRoles(resultData.roles));
                    break;
                default:
                    dispatch(addError(`Rollen konnten nicht geladen werden (${result.status})`))
            }

        }
        catch (e){
            dispatch(addError("Rollen konnten nicht heruntergeladen werden"))
        }
        dispatch(setRolesLoading(false))
    }
}

export const SET_ROLES_LOADING = "ADMINISTRATION_SET_ROLES_LOADING";
export function setRolesLoading(loading){
    return{
        type: SET_ROLES_LOADING,
        data: loading
    }
}
