/**
 * Created by Sebastian Venhuis on 26.10.2020.
 */

import RestUtils from "../../../Utils/RestUtils/RestUtils";
import ServiceUnavailableException from "../../../Utils/Exceptions/ServiceUnavailableException";
import UnauthorizedException from "../../../Utils/Exceptions/UnauthorizedException";
import {addError} from "../errorsActions";


export const SET_USERS = "USER_ADMINISTRATION_SET_USERS";

/**
Sets the list of all available Users should contain the id, username and tenantId of the User
@param userList {[{
id: string,
eMail: string,
tenantId: string,
name: string,
firstname: string,
roles: [string],
tenantRoles: [string]
 }]}
 */
export function SetUsers(userList){
    return{
        type: SET_USERS,
        data: userList
    }
}

export function GetUsersAsync(){
    return async function(dispatch, getState){
        dispatch(SetUsersLoading(true));
        let userIdsResponse = await RestUtils.Get("/users");
        let userIds = await userIdsResponse.json();
        let users = await Promise.all(userIds.ids.map(async (userId)=>{
            let userResponse = await RestUtils.Get(`/user/${userId}`);
            let userData = await userResponse.json();
            console.log("Reveived User", userData);
            return {
                id: userId,
                name: userData.user.name,
                firstname: userData.user.firstName,
                eMail: userData.user.eMail,
                tenantId: userData.user.tenantId,
                roles: userData.user.roles,
                tenantRoles: userData.user.tenantRoles
            }
        }))
        dispatch(SetUsersLoading(false));
        dispatch(SetUsers(users));
    }
}

export const SET_USERS_LOADING = "USERS_ADMINISTRATION_SET_LOADING"

/**
 * Sets a flag that indicates, that the users are currently loading
 * @param loading {boolean}
 * @returns {{data: *, type: string}}
 */
export function SetUsersLoading(loading){
    return {
        type: SET_USERS_LOADING,
        data: loading
    }
}


export const DELETE_USER = "USER_ADMINISTRATION_DELETE_USER"

/**
 * Deletes a User by its UserId
 * @param userId {string} The Id of the user to delete
 * @returns {{data: {userId: *}, type: string}}
 */
export function deleteUser(userId){
    return{
        type: DELETE_USER,
        data:{
            userId: userId
        }
    }
}

export const UPDATE_USER = "USER_ADMINISTRATION_UPDATE_USER";

/**
 * Updates a user localy
 * @param user {{id: string, name: string, firstname: string, eMail: string}}
 * @returns {{data: {user: *}, type: string}}
 */
export function updateUser(user){
    return {
        type: UPDATE_USER,
        data: {
            user: user
        }
    }
}

/**
 * Updates a user locally and pushes the change to the server
 * @param user {{id: string, name: string, firstname: string, eMail: string}}
 * @returns {function(*): Promise<undefined>}
 */
export function updateUserAsync(user){
    return async (dispatch) => {
        let userData = {
            id: user.id,
            name: user.name,
            firstName: user.firstname,
            eMail: user.eMail
        }

        let result = null;
        try{
            result = await RestUtils.Put(`/user/${user.id}`, userData);
        }
        catch (e){
            console.error("Could not save userdata", e);
            dispatch(addError("Benutzerdaten konnten nicht gespeichert werden"));
            return;
        }
        if(!result){
            console.error("Could not save userdata, fetch result was not defined");
            dispatch(addError("Benutzerdaten konnten nicht gespeichert werden"));
            return;
        }
        let resultData = await result.json();
        if(resultData.status === 200){
            dispatch(updateUser(user))
        } else {
            console.error(`Could not save userdata, statusCode ${result.status}`);
            dispatch(addError("Benutzerdaten konnten nicht gespeichert werden"));
        }
    }
}

/**
 * Updates the password of a user
 * @param user {{id: string, newPassword: string}}
 * @returns {function(*)}
 */
export function updateUserPasswordAsync(user){
    return async dispatch => {
        try{
            let result = await RestUtils.Put(`/user/${user.id}/password`, {password: user.newPassword});
            switch(result.status) {
                case 200:
                    //Do nothing
                    break;
                case 404:
                    dispatch(addError("Benutzer konnte nicht gefunden werden (Status: 404)"));
                    break;
                case 401:
                    dispatch(addError("Nicht eingeloggt (Status: 401)"));
                    break;
                case 403:
                    dispatch(addError("Das passwort konnte aufgrund fehlender rechte nicht aktualisiert werden (Status: 403)"));
                    break;
                default:
                    dispatch(addError(`Passwort konnte nicht aktualisiert werden (Status: ${result.status})`));
                    break;
            }
        }
        catch (e){
            dispatch(addError("Passwort konnte nicht aktualisiert werden"))
        }
    }
}

export const ADD_USER = "USER_MANAGER_ADD_USER";

export function addUser(user){
    return{
        type: ADD_USER,
        data: {
            user: user
        }
    }
}

export function addUserAsync(user){
    return async dispatch =>{
        try{
            let result = await RestUtils.Post("/user", user)
            switch (result.status){
                case 200:
                    dispatch(addUser(user));
                    break;
                default:
                    dispatch(addError(`Benutzer konnte nicht hinzugefügt werden (${result.status})`));
            }
        }
        catch (e){
            dispatch(addError("Benutzer konnte nicht registriert werden"))
        }
    }
}

export const ADD_USER_ROLE = "USER_ADMINISTRATION_ADD_USER_ROLE";
export const REMOVE_USER_ROLE = "USER_ADMINISTRATION_REMOVE_USER_ROLE";

export function addUserRole(userId, roleId){
    return{
        type: ADD_USER_ROLE,
        data:{
            userId,
            roleId
        }
    }
}

export function addUserRoleAsync(userId, roleId){
    return async dispatch =>  {
        try{
            let result = await RestUtils.Put(`/user/${userId}/roles/${roleId}`)
            if(result.status === 200)
                dispatch(addUserRole(userId, roleId))
            else
                dispatch(addError(`Rolle konnte nicht hinzugefügt werden (${result.status}`))
        }
        catch (e){
            dispatch(addError("Rolle konnte nicht hinzugefügt werden (unbekannt)"))
        }
    }
}

export function removeUserRole(userId, roleId){
    return {
        type: REMOVE_USER_ROLE,
        data:{
            userId,
            roleId
        }
    }
}

export function removeUserRoleAsync(userId, roleId){
    return async dispatch =>  {
        try{
            let result = await RestUtils.Delete(`/user/${userId}/roles/${roleId}`)
            if(result.status === 200)
                dispatch(removeUserRole(userId, roleId))
            else
                dispatch(addError(`Rolle konnte nicht hinzugefügt werden (${result.status}`))
        }
        catch (e){
            dispatch(addError("Rolle konnte nicht hinzugefügt werden (unbekannt)"))
        }
    }
}
