import React from "react";
import {connect} from "react-redux";
import {UserIsLoggedIn} from "../../../State/Selectors/authSelectors";
import Sidebar from "../../Utils/Sidebar/Sidebar";

import "../MainView.css"
import {Link, NavLink, Route} from "react-router-dom";
import {UserManagement} from "./UserManagement/UserManagement";
import "./Administration.css"
import {GetRoleIds} from "../../../State/Selectors/userSelector";
import {currentConfig} from "../../../index";
import {Redirect} from "react-router";

class Administration extends React.Component{


    render(){
        if(!this.props.userIsLoggedIn || !this.props.currentUserRoles.includes(currentConfig.adminRole)){
            return <Redirect to={"/"}/>
        }

        return <div className={"Administration MainView"}>
            <Sidebar className={"NavigationSidebar"}  title={"Navigation"}>
                <div className={"NavigationList"}>
                    <NavLink to={"/Administration/"} activeClassName={"isActive"} exact={true}>
                        <span>Dashboard</span>
                    </NavLink>
                    <NavLink to={"/Administration/Benutzerverwaltung"} activeClassName={"isActive"} >
                        <span>Benutzerverwaltung</span>
                    </NavLink>
                </div>
            </Sidebar>
            <div className={"content"}>
                <Route path={"/Administration/Benutzerverwaltung"} component={UserManagement}/>
                <Route path={"/Administration/"} exact={true} >
                    <span>Dashbord</span>
                </Route>
            </div>
            <Sidebar title={""}></Sidebar>
        </div>
    }
}


const mapStateToProps = state=>{
    return {
        userIsLoggedIn: UserIsLoggedIn(state),
        currentUserRoles: GetRoleIds(state)
    }
}

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(Administration);
