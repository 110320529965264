/**
 * Created by Sebastian Venhuis on 26.10.2020.
 */


import {AdministrationSelector} from "./AdministrationSelector";

export const AdministrationGetUsers = (state)=>AdministrationSelector(state).users.users;
export const AdministrationGetUsersLoading = state => AdministrationSelector(state).users.loading;
export const AdministrationGetUser = (state, userId)=>AdministrationGetUsers(state).find((user)=>user.id === userId);
export const AdministrationGetUserRoles = (state, userId)=>AdministrationGetUser(state, userId)?.roles;
