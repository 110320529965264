export function GetCards(state){
    return state.data.cards;
}

/**
 * Returns all Cards, that are specified in ids
 * @param state {Object} The current state of the application
 * @param ids {string[]} The Ids of the Cards that should be returned
 */
export function GetCardsForIds(state, ids){
    return state.data.cards.filter((card)=>{
        return ids.includes(card.id);
    })
}

export function GetTags(state){
    return state.data.tags;
}

export function GetTag(state, id){
    return state.data.tags.find((tag)=>tag.id === id);
}