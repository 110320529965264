/**
 * Created by Sebastian Venhuis on 10.11.2020.
 */

import React from "react";
import {connect} from "react-redux";
import {addError, removeError} from "../../../State/Actions/errorsActions";
import {getErrors} from "../../../State/Selectors/errorsSelectors";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./ErrorsView.css"

library.add(faTimes)


class ErrorsViewClass extends React.Component{

    render(){
        return <div className={"ErrorsView"}>
            {this.props.errors.map((error)=>{
                return <div key={error.id} className={"Error"}>
                    <header><FontAwesomeIcon onClick={()=>{
                        this.props.removeError(error.id)
                    }} icon={faTimes}/></header>
                    <div className={"content"}>{error.message}</div>
                </div>
            })}
        </div>
    }
}

const mapStateToProps = (state)=>{
    return {
        errors: getErrors(state)
    }
}

const mapActionsToProps = {
    removeError,
    addError
}

export const ErrorsView = connect(mapStateToProps, mapActionsToProps)(ErrorsViewClass);
