/**
 * Created by Sebastian Venhuis on 12.11.2019.
 */

export const FLEXIA_SWITCH_SIDEBARS = "FLEXIA_SWITCH_SIDEBARS";

/***
 * Toogles the Sidebars for the FlexIA View making it possible to show wider Objects on the Screen. Hidden Sidebars can still be Toggled open to display over the Content
 * @param {boolean} showSidebars If True the Sidebars will be permanently shown, if false the sidebars will be hidden
 * @returns {{showSidebars: *, type: *}}
 */
export const FlexiaSwitchSidebars = (showSidebars) => {
    return {
        type: FLEXIA_SWITCH_SIDEBARS,
        showSidebars: showSidebars
    }
};
