import React from "react";
import {faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Information from "../../Utils/Information/Information";
import "./InfoIcon.css";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";


library.add(faQuestionCircle);

export default class InfoIcon extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"infoIcon"}>
                <FontAwesomeIcon icon={faQuestionCircle}/>
                <div className={"infoPopUpContainer"}>
                    <div className={"infoPopUp"}>
                        <Information label={this.props.infoLabel}>
                            <div className={"infoContent"}>
                                {this.props.children}
                            </div>
                        </Information>
                    </div>
                </div>
            </div>
        );
    }
}
