import React from 'react';
import './App.css';
import FlexDeMo from "./FlexDeMo";
import ThemeContext, {DefaultTheme} from "./ThemeContext";

function App() {
  return (
    <div className="App">
        <ThemeContext.Provider value={DefaultTheme}>
            <FlexDeMo/>
        </ThemeContext.Provider>
    </div>
  );
}

export default App;
