/**
 * Created by Sebastian Venhuis on 03.07.2020.
 */

import React from "react";
import PropTypes from 'prop-types';
import "./MultiSelect.css"
import {library} from "@fortawesome/fontawesome-svg-core";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";

library.add(faTimes, faCheckSquare, faSquare, faPlus)

class MultiSelectClass extends  React.Component{
    constructor(props) {
        super(props);
    }

    renderAddContainer(){
        if(this.props.allowElementAdd){
            return (<div className={"MultiSelectAddContainer"}>
                <div className={"MultiSelectAddInput"}>
                    {this.props.onElementAdd()}
                </div>
                <div className={"MultiSelectAddButton"} onClick={()=>{this.props.onElementAdded()}}>
                    <FontAwesomeIcon icon={faPlus}/>
                </div>
            </div>)
        }
        else{
            return null;
        }
    }

    render(){
        return(
            <div className={"MultiSelect"}>
                <div className={"MultiSelectHeader"}>
                    {this.props.title ? <div className={"MultiSelectTitle"}>{this.props.title}</div> : null}
                    <div className={"MultiSelectClose"} onClick={()=>{if (this.props.onClose){this.props.onClose()}}}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>
                <div className={"MultiSelectItemContainer"}>
                    {(this.props.suggestions || []).map((suggestion)=>{
                        if(this.props.elements.find((element)=>{return element.key === suggestion.key})){
                            return null
                        }
                        else{
                            return <div className={"MultiSelectChild"} key={suggestion.key} onClick={(event)=>{
                                if(this.props.onSelectionChanged){
                                    this.props.onSelectionChanged(suggestion.key, true)
                                }
                            }}>
                                <FontAwesomeIcon icon={faSquare}/>
                                {suggestion.element}
                            </div>
                        }
                    })
                    }
                    {this.props.elements.map(element=>{
                        let classes = "MultiSelectChild";
                        let selected = false;
                        if(this.props.selectedChildren && this.props.selectedChildren[element.key]){
                            classes += " selected"
                            selected = true;
                        }
                        return (
                            <div className={classes} key={element.key} onClick={(event)=>{
                                if(this.props.onSelectionChanged){
                                    this.props.onSelectionChanged(element.key, !selected, false)
                                }
                            }}>
                                {selected ? <FontAwesomeIcon icon={faCheckSquare}/> : <FontAwesomeIcon icon={faSquare}/>}
                                {element.element}
                            </div>
                        )
                    })}
                </div>
                {this.renderAddContainer()}
            </div>
        )
    }
}

MultiSelectClass.propTypes= {
    elements: PropTypes.arrayOf(PropTypes.shape({
        element: PropTypes.element.isRequired,
        key: PropTypes.any.isRequired
    })).isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.shape({
        element: PropTypes.element.isRequired,
        key: PropTypes.any.isRequired
    })),
    title: PropTypes.string,
    selectedChildren: PropTypes.array,

    allowElementAdd: PropTypes.bool,

    onSelectionChanged: PropTypes.func,
    onClose: PropTypes.func,
    onElementAdd: PropTypes.func,
    onElementAdded: PropTypes.func,
}

export const MultiSelect = MultiSelectClass;