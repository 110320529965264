/**
 * Created by Sebastian Venhuis on 26.10.2020.
 */
import {
    ADD_USER_ROLE, REMOVE_USER_ROLE,
    SET_USERS,
    SET_USERS_LOADING,
    UPDATE_USER
} from "../../../Actions/Administration/userAdministrationActions";

let initialState = {
    users: [],
    loading: true
};

export function usersReducer(state = initialState, action){
    let data = action.data;
    switch(action.type){
        case SET_USERS:
            return {
                ...state,
                users: data
            }
        case SET_USERS_LOADING:{
            return{
                ...state,
                loading: data
            }
        }
        case UPDATE_USER: {
            return{
                ...state,
                users: state.users.map((user)=>{
                    if(user.id === data.user.id)
                        return data.user
                    else
                        return user
                })
            }
        }
        case ADD_USER_ROLE:{
            return {
                ...state,
                users: state.users.map((user)=>{
                    if(user.id === data.userId){
                        return {
                            ...user,
                            roles: [...user.roles, data.roleId]
                        }
                    } else {
                        return user;
                    }
                })
            }
        }
        case REMOVE_USER_ROLE:{
            return {
                ...state,
                users: state.users.map((user)=>{
                    if(user.id === data.userId){
                        return {
                            ...user,
                            roles: user.roles.filter((role)=>role!==data.roleId)
                        }
                    } else {
                        return user;
                    }
                })
            }
        }
        default:
            return state;
    }
}
