import "./Search.css"
import React from "react";
import PropTypes from "prop-types"
import Button from "./Button";

export default class Search extends React.Component{
    render(){
        return (
            <div className={"SearchBox"}>
                <input type={"text"} placeholder={this.props.placeholder}/> <Button name={"Suchen"}/>
            </div>
        )
    }
}

Search.propTypes = {
        placeholder: PropTypes.string,
        onSearch: PropTypes.func,
        searchOnType: PropTypes.bool
};