import "./MainView.css"
import "./ContentView.css"

import React from "react";
import Sidebar from "../Utils/Sidebar/Sidebar";
import CardStack from "../Cards/CardStack";
import Button from "../Utils/Inputs/Button";
import Information from "../Utils/Information/Information";
import {GetCards} from "../../State/Selectors/dataSelectors";
import {connect} from "react-redux";
import parse from 'html-react-parser';
import {UserIsLoggedIn} from "../../State/Selectors/authSelectors";



class ContentView extends React.Component{
    card;


    constructor(props) {
        super(props);
    }




    render(){
        let card = this.props.cards.find((card)=>{return card.id===this.props.cardId});
        let suggestions = this.props.cards.filter((element)=>{
            return card.suggestedCards.includes(element.id);

        });

        return(
            <div className={"MainView ContentView"}>
                <Sidebar title={"Empfehlungen"}>
                    <CardStack cards={suggestions}/>
                </Sidebar>
                <div className={"content"}>
                    <header>
                        <div className={"title"}>
                            {card.title}
                        </div>
                    </header>
                    <div className={"content"}>
                        {parse(card.content)}
                    </div>
                    <footer>
                        <Button name={"Zur Anleitung"} color={"#307eb7"}/>
                        <Button name={"Zum Tool"} link={"/tool/informationsanalyse"}/>
                    </footer>
                </div>
                <Sidebar title={"Infobox"}>
                    {!this.props.UserIsLoggedIn ?
                    <Information label={"Speichern nicht möglich"}>
                        Aktuell können keine Daten gespeichert werden. Bitte legen Sie ein Benutzerkonto an.
                    </Information> : null
                    }
                    <Information label={"Wertstromanalyse"}>
                        Die Daten, die Sie während der Informationsflussanalyse erheben, können Teilweise in der Wertstromanalyse wiederverwendet werden!
                    </Information>
                </Sidebar>
            </div>
        )
    }
}

const mapStateToProps = state=>{
    return {
        cards: GetCards(state),
        UserIsLoggedIn: UserIsLoggedIn(state)
    }
};

export default connect(mapStateToProps)(ContentView);