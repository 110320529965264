import React from "react";
import "../../Misc/LoadingCircle/LoadingBars.css"
import Loading from "../../Misc/LoadingCircle/Loading.js";

export default class SaveState extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastSave: this.props.saveState.lastSave,
            restStatus: this.props.saveState.restStatus,
            data: this.props.saveState.data,
            retryFkt: this.props.saveState.retryFkt,
            dataType: this.props.saveState.dataType,
            saving: this.props.saveState.saving,
        }
    }

    switchRestStatus() {
        if (!this.state.restStatus) {
            return <div className={"SaveState"}>
                <div> Es gibt keine ungespeicherten Änderungen </div>
            </div>
        }
        else if (this.state.restStatus === 200) {
            return <div className={"SaveState"}>
                <div> Zuletzt gespeichert um: {this.state.lastSave ? this.state.lastSave.toLocaleTimeString() : ""} </div>
            </div>
        }
        else if (this.state.restStatus === 400) {
            return <div className={"SaveStateWarning"}>
                <div> Speichern fehlgeschlagen </div>
                <div> Zu speichernde Daten: {this.state.dataType} </div>
                <button onClick={()=>{this.state.retryFkt()}}>  Erneut versuchen  </button>
            </div>

        }
        else if (this.state.restStatus === 401) {
            return <div className={"SaveStateWarning"}>
                <div> Speichern fehlgeschlagen </div>
                <div> Zu speichernde Daten: {this.state.dataType} </div>
                <button onClick={()=>{this.state.retryFkt()}}>  Erneut versuchen  </button>
            </div>

        }
        else if (this.state.restStatus === 403) {
            return <div className={"SaveStateWarning"}>
                <div> Speichern fehlgeschlagen </div>
                <div> Zu speichernde Daten: {this.state.dataType} </div>
                <button onClick={()=>{this.state.retryFkt()}}>  Erneut versuchen  </button>
            </div>

        }
        else if (this.state.restStatus === 500) {
            return <div className={"SaveStateWarning"}>
                <div> Speichern fehlgeschlagen </div>
                <div> Zu speichernde Daten: {this.state.dataType} </div>
                <button onClick={()=>{this.state.retryFkt()}}> Erneut versuchen </button>
            </div>

        }
        else if (this.state.restStatus === 503) {
            return <div className={"SaveStateWarning"}>
                <div> Speichern fehlgeschlagen </div>
                <div> Zu speichernde Daten: {this.state.dataType} </div>
                <button onClick={()=>{this.state.retryFkt()}}>  Erneut versuchen  </button>
            </div>

        }
        else {
            return <div className={"SaveStateWarning"}>
                <div> Speichern fehlgeschlagen </div>
                <div> Zu speichernde Daten: {this.state.dataType} </div>
                <button onClick={()=>{this.state.retryFkt()}}>  Erneut versuchen  </button>
            </div>
        }
    }
    render() {
        return <div>
            <div>{this.switchRestStatus()}</div>
            {this.state.saving ? <div className={"saveStateLoadingWrapper"}><Loading/></div> : null}
        </div>
    }
}
