import React from "react"
import PropTypes from "prop-types"
import "./Sidebar.css"
import "../Utils.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";
export default class Sidebar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            shown: !props.initiallyHidden
        }
    }

    toggleSidebar(event){
        this.setState((oldState)=>{
            return {
                shown: !oldState.shown
            }
        });
        event.stopPropagation();
    }
    render(){
        if(!this.props.toggleable) {
            return (
                <div className={"sidebar " + (this.props.className || "")}>
                    <header className={"columnHeader"}>
                        {this.props.title}
                    </header>
                    <div className={"content"}>
                        {this.props.children}
                    </div>
                </div>
            );
        }
        else {
            if (this.props.side === "left") {
                if (this.state.shown) {
                    return (
                        <div className={"sidebar " + (this.props.className || "")}>
                            <header className={"columnHeader closeSidebar"} onClick={(event) => {
                                this.toggleSidebar(event)
                            }}>
                                {this.props.title}
                                <div className={"sidebarToggle"} >
                                    <FontAwesomeIcon icon={faCaretLeft}/>
                                </div>
                            </header>
                            <div className={"content"}>
                                {this.props.children}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className={"sidebarClosed sidebarToggle"} onClick={this.toggleSidebar.bind(this)}>
                            <FontAwesomeIcon icon={faCaretRight}/>
                        </div>
                    )
                }
            }
            else if (this.props.side === "right") {
                if (this.state.shown) {
                    return (
                        <div className={"sidebar " + (this.props.className || "")}>
                            <header className={"columnHeader closeSidebar"} onClick={(event) => {
                                this.toggleSidebar(event)
                            }}>
                                <div className={"sidebarToggle"}>
                                    <FontAwesomeIcon icon={faCaretRight}/>
                                </div>
                                {this.props.title}
                            </header>
                            <div className={"content"}>
                                {this.props.children}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className={"sidebarClosed sidebarToggle"} onClick={this.toggleSidebar.bind(this)}>
                            <FontAwesomeIcon icon={faCaretLeft}/>
                        </div>
                    )
                }
            }
        }
        /* else {
            if (this.props.side === "left") {
                if (this.state.shown) {
                    return (
                        <div className={"sidebarOpened"}>
                            <div className={"sidebarToggle"} onClick={(event) => {
                                this.toggleSidebar(event)
                            }}>
                                <FontAwesomeIcon icon={faCaretLeft}/>
                            </div>
                            <div className={"sidebar sidebarOverlay " + (this.props.className || "")}>
                                <header className={"columnHeader"}>
                                    {this.props.title}
                                </header>
                                <div className={"content"}>
                                    {this.props.children}
                                </div>
                            </div>

                        </div>
                    );
                } else {
                    return (
                        <div className={"sidebarClosed sidebarToggle"} onClick={this.toggleSidebar.bind(this)}>
                            <FontAwesomeIcon icon={faCaretRight}/>
                        </div>
                    )
                }
            }
            else if (this.props.side === "right") {
                if (this.state.shown) {
                    return (
                        <div className={"sidebarOpened"}>
                            <div className={"sidebar sidebarOverlay " + (this.props.className || "")}>
                                <header className={"columnHeader"}>
                                    {this.props.title}
                                </header>
                                <div className={"content"}>
                                    {this.props.children}
                                </div>
                            </div>
                            <div className={"sidebarToggle"} onClick={(event) => {
                                this.toggleSidebar(event)
                            }}>
                                <FontAwesomeIcon icon={faCaretRight}/>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className={"sidebarClosed sidebarToggle"} onClick={this.toggleSidebar.bind(this)}>
                           <FontAwesomeIcon icon={faCaretLeft}/>
                        </div>
                    )
                }
            }
        } */

    }

    render_old(){
        if(!this.props.toggleable) {
            return (
                <div className={"sidebar " + (this.props.className || "")}>
                    <header className={"columnHeader"}>{this.props.title}</header>
                    <div className={"content"}>
                        {this.props.children}
                    </div>
                </div>
            );
        }
        else{
            if(this.state.shown){
                return (
                    <div className={"sidebarToggle"} onClick={this.toggleSidebar.bind(this)}>
                        {this.props.unhideElement}
                        <div className={"sidebarTogglePlane"} onClick={this.toggleSidebar.bind(this)}>
                            <div className={"sidebar sidebarOverlay " + (this.props.className || "")}>
                                <header className={"columnHeader"}>{this.props.title}</header>
                                <div className={"content"}>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else{
                return(
                    <div className={"sidebarToggle"} onClick={this.toggleSidebar.bind(this)}>
                        {this.props.unhideElement}
                    </div>
                )
            }
        }
    }
}

Sidebar.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    toggleable: PropTypes.bool,
    initiallyHidden: PropTypes.bool,
    direction: PropTypes.string,
    side: PropTypes.string
};
