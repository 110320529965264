import React from "react";
import "./ConclusionTable.css";


export default class ConclusionTable extends React.Component {
    constructor(props) {
        super(props);
        this.mockData = {
              processId: "9dbef5a8-9da3-4fa2-b080-e78b20e20b1f",                //random
              date: "31-03-2020",
              company: "Mustermacher GmBh",
              editor: "Max Mustermann",
              rows: [{
                  process: "Prozess",
                  persons: ["max mustermann", "Lili Blütenblatt"],
                  informationFields: "Dies ist ein Muster",
                  informationObjectActivity: "sich informieren",
                  mediaBreaks: 1,
                  possibleEvents: 2,
                  informationObjects: 3,
                  edits: 4,
                  movements: 5,
                  withoutInformationObjects: 6,
              }]
        };
        this.columnNames = [
            {name: "process", label: "Prozess"},
            {name: "persons", label: "Prozessbeteiligte"},
            {name: "informationFields", label: "Informationsfelder"},
            {name: "informationObjectActivity", label: "Aktivität"},
            {name: "mediaBreaks", label: "Medienbrüche"},
            {name: "possibleEvents", label: "Mögliche Störungen"},
            {name: "informationObjects", label: "Informationsobjekte"},
            {name: "edits", label: "Bearbeitungen (B)"},
            {name: "movements", label: "Bewegungen"},
            {name: "withoutInformationObjects", label: "Ohne Informationsobjekt"},
        ];
        if (this.props.conclusion !== null && this.props.conclusion !== undefined) {
            this.state = {
                hasConclusion: true,
                conclusion: this.props.conclusion,
            }
        }
        else {
            this.state = {
                hasConclusion: false,
                conclusion: this.mockData,
            };
        }
    }
    renderRows(){
        let rows = [];
        for (let i = 0; i < this.state.conclusion.rows.length; i++) {
            let row = this.state.conclusion.rows[i];
            rows.push(
                <tr className={"tableDataRow"} key={row.id}>
                    <td className={"tableData"}> {row.process} </td>
                    <td className={"tableData"}> {row.persons.map((person, index) => {
                        if (index !== (row.persons.length - 1)) {
                            return <div key={person}>{person + ","}</div>
                        } else {
                            return <div key={person}>{person}</div>
                        }
                    })} </td>
                    <td className={"tableData"}> {row.informationFields} </td>
                    <td className={"tableData"}> {row.informationObjectActivity} </td>
                    <td className={"tableData"}> <div className={"centralizeHorizontal"}> <div className={"centralizeVertical"}> {row.mediaBreaks} </div> </div> </td>
                    <td className={"tableData"}> <div className={"centralizeHorizontal"}> <div className={"centralizeVertical"}> {row.possibleEvents} </div> </div> </td>
                    <td className={"tableData"}> <div className={"centralizeHorizontal"}> <div className={"centralizeVertical"}>  {row.informationObjects} </div> </div> </td>
                    <td className={"tableData"}> <div className={"centralizeHorizontal"}> <div className={"centralizeVertical"}>  {row.edits} </div> </div> </td>
                    <td className={"tableData"}> <div className={"centralizeHorizontal"}> <div className={"centralizeVertical"}>  {row.movements} </div> </div> </td>
                    <td className={"tableData"}> <div className={"centralizeHorizontal"}> <div className={"centralizeVertical"}>  {row.withoutInformationObjects} </div> </div> </td>
                </tr>
            );
        }
        return rows;
    }
    render() {
        if (this.state.hasConclusion) {
            return <div className={"ConclusionTable"}>
                <div className={"tableMoreInformation"}>
                    <div className={"conclusionTableHeader"}> Auswertung FlexIA: Informationsflussanalyse </div>
                    <div className={"tableMoreInformationRow"}>
                        <div className={"tableInfo"}> Betrieb: {this.state.conclusion.company}</div>
                        <div className={"tableInfo"}> Aufnahmedatum: {this.state.conclusion.date}</div>
                        <div className={"tableInfo"}> Bearbeiter: {this.state.conclusion.editor}</div>
                    </div>
                </div>
                <div className={"actualTable"}>
                    <table>
                        <thead>
                            <tr className={"tableHeaderRow"}>
                                {this.columnNames.map((element, index)=>{
                                    if (index < 4) {
                                        return <th className={"tableHeader"} key={element.name}><div>{element.label}</div></th>
                                    }
                                    else {
                                        return <th className={"tableHeader"} key={element.name}><div className={"rotate90"}>{element.label}</div></th>
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        }
        else {
            return <div className={"missingConclusionData"}>
                <div> something went wrong, no data arrived </div>
            </div>
        }
    }
}