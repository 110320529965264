/**
 * Created by Sebastian Venhuis on 20.11.2020.
 */
import {AdministrationSelector} from "./AdministrationSelector";


export const AdministrationGetRoleState = (state)=>AdministrationSelector(state).roles;
export const AdministrationGetRoles = (state)=>AdministrationGetRoleState(state).roles;
export const AdministrationRolesLoading = (state)=>AdministrationGetRoleState(state).loading;
export const AdministrationGetRole = (state, roleId) => AdministrationGetRoles(state).find((role)=>role.id === roleId);
