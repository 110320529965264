import { combineReducers } from 'redux';
import authReducer from "./authReducer";
import viewReducer from "./viewReducer";
import dataReducer from "./dataReducer";
import {errorsReducer} from "./errorsReducer";

const flexdemoReducer = combineReducers({
    auth: authReducer,
    view: viewReducer,
    data: dataReducer,
    errors: errorsReducer
});

export default flexdemoReducer;
