import React from "react";
import "./LoadingBars.css"

export default class Loading extends React.Component{
    render(){
        return (
        <div className={"LoadingBars Container"}>
            <div id={"Bar1"} className={"LoadingBars Bar"}/>
            <div id={"Bar2"} className={"LoadingBars Bar"}/>
            <div id={"Bar3"} className={"LoadingBars Bar"}/>
            <div id={"Bar4"} className={"LoadingBars Bar"}/>
            <div id={"Bar5"} className={"LoadingBars Bar"}/>
        </div>
        );
    }
}