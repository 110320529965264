import React from "react";
import {connect} from "react-redux";
import {
    IsEmergencyLoginPanel, LoginHasFailed, LoginHasFailedReason,
    ShowLoginInProgress,
    ShowLoginWindow,
    UserIsLoggedIn
} from "../../State/Selectors/authSelectors";
import Button from "../Utils/Inputs/Button";
import {
    AsyncLoginRequest, AsyncLogout,
    CloseLoginWindow,
    Logout, OpenEmergencyLoginWindow,
    OpenLoginWindow,
    SetLoginInProgress,
    TestAuth
} from "../../State/Actions/authActions";

import "./Login.css"
import RestUtils from "../../Utils/RestUtils/RestUtils";
import Loading from "../Misc/LoadingCircle/Loading";
import UserMenu from "../UserButton/UserButton";

class Login extends  React.Component{

    constructor(props){
        super(props);

        this.state = {
            username: "max@mustermann.de",
            password: "12345678901234567890",
            loginWindowVisible: false,
        }
    }

    componentDidMount() {
    }

    handleUsernameChanged(event){
        this.setState({
            username: event.target.value
        })
    }

    handlePasswordChanged(event){
        this.setState({
            password: event.target.value
        })
    }

    handleOnLoginClick(event){
        this.props.AsyncLogin(this.state.username, this.state.password);
        this.props.SetLoginInProgress(true);
    }

    handleOnRegisterClick(event){

    }

    openLoginPanel(){
        this.props.OpenLoginWindow();
    }

    closeLoginPanel(){
        this.props.CloseLoginWindow();
    }

    handleOnLogoutClick(event){
        this.props.AsyncLogout();
    }

    render(){
        if(this.props.UserIsLoggedIn){
            return (
                <div className={"Login"} style={this.props.style}>
                    <div className={"content"}>
                        <span className={"UserButtonContainer"}><UserMenu/></span>
                    </div>
                </div>
            )
        }

        return (
            <div className={"Login"} style={this.props.style}>
                <div className={"content"}>
                    <Button name={"Login"} onClick={this.openLoginPanel.bind(this)}/>
                </div>
                <form className={"LoginPanel "}
                     style={{visibility: this.props.ShowLoginWindow ? "visible" : "hidden"}}>
                    <div className={"LoginPanelTitle"}><span>Login</span></div>
                    <div><input type={"text"} autoComplete={"username"} placeholder={"E-Mail: benutzer@beispiel.de"} value={this.state.username} onChange={this.handleUsernameChanged.bind(this)}/></div>
                    <div><input type={"password"} autoComplete={"current-password"} placeholder={"Pasword"} value={this.state.password} onChange={this.handlePasswordChanged.bind(this)}/></div>
                    <div style={{display: !this.props.ShowLoginInProgress && this.props.ShowLoginWindow ? "flex" : "none"}}>
                        <Button name={"Login"} onClick={this.handleOnLoginClick.bind(this)}/>
                        {this.props.IsEmergencyLoginPanel ?
                            <Button name={"Abmelden"} onClick={()=>{
                                this.props.AsyncLogout();
                                this.props.CloseLoginWindow();
                            }}
                            /> :
                            <Button name={"Registrieren"} onClick={this.handleOnRegisterClick.bind(this)}/>
                        }
                    </div>
                    <div style={{display: this.props.ShowLoginInProgress && this.props.ShowLoginWindow ? "block" : "none"}}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                            <div style={{height: 10, width: 70, margin: 20, padding: 0}}>
                                <Loading/>
                            </div>
                        </div>
                    </div>
                    <div style={{display: this.props.LoginHasFailed ? "block" : "none"}}>
                        <div className={"LoginErrorContainer"}>
                            {this.props.LoginHasFailedReason}
                        </div>
                    </div>
                </form>
                <div className={"LoginBlurPanel"} onClick={this.closeLoginPanel.bind(this)} style={{visibility: this.props.ShowLoginWindow ? "visible" : "hidden"}}/>
            </div>
        )
    }
}

const mapStateToProps = state=> {return {
    UserIsLoggedIn: UserIsLoggedIn(state),
    ShowLoginWindow: ShowLoginWindow(state),
    ShowLoginInProgress: ShowLoginInProgress(state),
    IsEmergencyLoginPanel: IsEmergencyLoginPanel(state),
    LoginHasFailed: LoginHasFailed(state),
    LoginHasFailedReason: LoginHasFailedReason(state),
}};
const mapDispatchToProps = {
    TestAuth, AsyncLogout, OpenLoginWindow, CloseLoginWindow, SetLoginInProgress, AsyncLogin: AsyncLoginRequest, OpenEmergencyLoginWindow
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
