export const UserIsLoggedIn = state => {
    return state.auth.jwt !== null;
}
export const ShowLoginWindow = state => state.auth.loginWindowOpen;
export const ShowLoginInProgress = state => state.auth.showLoginInProgress;
export const LoginHasFailed = state => state.auth.loginFailed;
export const LoginHasFailedReason = state => state.auth.loginFailedReason;
export const IsEmergencyLoginPanel = state => state.auth.emergencyLogin;
export const EmergencyLoginResolve = (state, data) => state.auth.emergencyLoginPromiseResolve(data);
export const EmergencyLoginReject = (state, data) => state.auth.emergencyLoginPromiseReject(data);

export const GetCurrentUserId = (state) => state.auth.jwtData.user.id;