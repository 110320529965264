import React from "react"
import PropTypes from "prop-types"
import "./Button.css"
import {Link} from "react-router-dom";
import "../Utils.css"

export default class Button extends React.Component{

    handleOnClick(event){
        this.props.onClick(event);
    }

    render(){
        let style = {};
        if(this.props.color){
            style.backgroundColor= this.props.color
        }
        if(this.props.link){
            return (
                <Link to={this.props.link} className={"noLinkStyle"}>
                    <div className={"Button"} style={style}>
                        {this.props.name}
                        {this.props.children}
                    </div>
                </Link>
            );
        }
        else {
            return (
                <div onClick={this.handleOnClick.bind(this)} className={"Button"} style={style}>
                    {this.props.name}
                    {this.props.children}
                </div>
            );
        }
    }
}

Button.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func,
};