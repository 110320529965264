import "./ProjectView.css"
import React from "react";
import Sidebar from "../Utils/Sidebar/Sidebar";
import "../Utils/Utils.css"
import CardStack from "../Cards/CardStack";
import {connect} from "react-redux";
import {
    GetProjectCurrentTools,
    GetProjectDoneTools,
    GetProjectSuggestedTools
} from "../../State/Selectors/viewSelectors";
import {GetCards, GetCardsForIds} from "../../State/Selectors/dataSelectors";
import Card from "../Cards/Card";


class ProjectView extends React.Component{
    render(){
        return (
            <div  className={"MainView ProjectView"}>
                <Sidebar title={"Abgeschlossen"}>
                    <CardStack cards={this.props.doneTools}/>
                </Sidebar>
                <div className={"content"}>
                    <header className={"columnHeader"}>In Arbeit</header>
                    <div className={"content"}>
                        {this.props.currentTools.map(card=>{
                            return (
                                <Card card={card} key={card.id}/>
                            )
                        })}
                    </div>
                </div>
                <Sidebar title={"Empfehlungen"}>
                    <CardStack cards={this.props.suggestedTools}/>
                </Sidebar>
            </div>
        )
    }
}

const mapStateToProps = state =>{
    return{
        doneTools: GetCardsForIds(state, GetProjectDoneTools(state)),
        currentTools: GetCardsForIds(state, GetProjectCurrentTools(state)),
        suggestedTools: GetCardsForIds(state, GetProjectSuggestedTools(state)),
        cards: GetCards(state)
    }
};

export default connect(mapStateToProps)(ProjectView);