/**
 * Created by Sebastian Venhuis on 29.10.2020.
 */

import React from "react";
import {
    AdministrationGetUsers
} from "../../../../State/Selectors/AdministrationSelectors/usersSelector";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import "../AdministrationForm.css"
import Button from "../../../Utils/Inputs/Button";
import RestUtils from "../../../../Utils/RestUtils/RestUtils";
import {updateUserPasswordAsync} from "../../../../State/Actions/Administration/userAdministrationActions";
import dumpPasswords from "dumb-passwords"
import ProgressBar from "@ramonak/react-progress-bar";
import scaleRange from "scale-number-range";
import "./UserPassword.css"

//https://gist.github.com/johnelliott/cf77003f72f889abbc3f32785fa3df8d
const v4Regex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

export function checkPassword(password1, password2){
    let validationResult = {
        success: true,
        minLength:{
            required: 12, //This can be used to change the min length required
            actual: 0
        },
        areEqual: true,
        blacklisted:false
    }
    validationResult.minLength.actual = password1.length;
    if(validationResult.minLength.actual < validationResult.minLength.required){
        validationResult.success = false
    }
    if(password1 !== password2){
        validationResult.success = false;
        validationResult.areEqual = false;
    }
    if(dumpPasswords.check(password1)){
        validationResult.success = false;
        validationResult.blacklisted = true;
    }
    return validationResult;
}

class UserPasswordClass extends React.Component{
    constructor(props) {
        super(props);

        let error = false;
        if(!(this.props.match.params.userId && this.props.match.params.userId.match(v4Regex))){
            error = true;
        }
        let currentUser = this.props.users.find((user)=>{return user.id === this.props.match.params.userId});
        if(currentUser === undefined){
            error = true;
        }
        if(error){
            this.state={
                error: true,
                errorMessage: null,
                success: false
            }
            return;
        }

        this.state = {
            error: false,
            errorMessage: null,
            id: currentUser.id,
            newPassword: "",
            newPassword1: "",
            success: false,
            passwordValidation: {
                success: false,
                minLength:{
                    required: 12, //This can be used to change the min length required
                    actual: 0
                },
                areEqual: true,
                blacklisted:false
            }
        }

    }

    savePassword(){
        if(!this.state.passwordValidation.success)
            return;

        this.props.updateUserPasswordAsync({
            id: this.state.id,
            newPassword: this.state.newPassword
        });

       this.setState({success: true});
    }

    passwordChanged(password1, password2){
        if(password1 !== this.state.newPassword)
            this.setState({newPassword: password1})
        if(password2 !== this.state.newPassword1)
            this.setState({newPassword1: password2})

        this.setState({passwordValidation: checkPassword(password1, password2)})
    }

    renderPasswordValidity(){
        let completed = 10;
        let color = "#ff0000"

        if(this.state.passwordValidation.success){
            completed = 80;
            color="#6eb71e";
            if(this.state.passwordValidation.minLength.actual >= 16) {
                completed = 100
                color="#00ff00";
            }
        }
        else{
            if(!this.state.passwordValidation.areEqual || this.state.passwordValidation.blacklisted) {
                completed = 5;
                let color = "#ff0000"
            }
            else {
                completed = scaleRange(this.state.passwordValidation.minLength.actual, 0, 11, 5, 79);
                color = "#ff1e00"
            }
        }

        return [<div key={"UserManagementPasswordProgress"} className={"userField full"}>
            <span/><ProgressBar completed={completed} width={"300px"} bgcolor={color} labelSize={0}/>
        </div>, <div key={"UserManagementPasswordErrors"} className={"passwordErrors"}>
            {this.state.passwordValidation.minLength.actual < this.state.passwordValidation.minLength.required ? <span>Das Passwort muss mindestens {this.state.passwordValidation.minLength.required} Zeichen lang sein</span> : null}
            {(!this.state.passwordValidation.areEqual) ? <span>Die Passwörter müssen gleich sein</span> : null}
            {this.state.passwordValidation.blacklisted ? <span>Das Passwort ist auf der Blacklist, da es sehr oft verwendet wird. Bitte wählen Sie ein anderes Passwort</span> : null}
        </div> ]
    }


    render(){
        if(this.state.error){
            if(this.state.errorMessage)
                return <div className={"error"}>{this.state.errorMessage}</div>
            else
                return <div className={"error"}>Unzulässige Benutzer Id</div>
        }

        if(this.state.success)
            return <Redirect to={"/Administration/Benutzerverwaltung"}/>

        //console.log(this.state);

        return <div className={"UserEditor"}>
            <div className={"content"}>
                <div className={"AdministrationForm"}>
                    <header>Password für {this.props.firstname} {this.props.name} ändern</header>
                    <div className={"content"}>
                        <div className={"userField"}><span>Password</span><input type={"password"} value={this.state.newPassword} onChange={(event)=>{this.passwordChanged(event.target.value, this.state.newPassword1)}}/></div>
                        <div className={"userField"}><span>Password wiederholen</span><input type={"password"} value={this.state.newPassword1} onChange={(event)=>{this.passwordChanged(this.state.newPassword, event.target.value)}}/></div>
                        {this.renderPasswordValidity()}
                    </div>
                    <footer>
                        <Button color={"#910303"} link={"/Administration/Benutzerverwaltung"} >Abbrechen</Button><Button color={this.state.passwordValidation.success ? null : "#8d8d8d"} onClick={()=>{this.savePassword()}}>Speichern</Button>
                    </footer>
                </div>
            </div>
        </div>
    }
}


const mapStateToProps = state=>{
    return {
        users: AdministrationGetUsers(state)
    }
}

const mapActionsToProps = {
    updateUserPasswordAsync
}

export const UserPassword = connect(mapStateToProps, mapActionsToProps)(withRouter(UserPasswordClass))
