import {combineReducers} from "redux";
import flexiaReducer from "./Tools/flexiaReducer";

/**
 * Created by Sebastian Venhuis on 12.11.2019.
 */

const toolViewReducer = combineReducers({
    flexia: flexiaReducer
});

export default toolViewReducer;