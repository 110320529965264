import React from "react";
import "./FlexIA.css"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircle, faTimesCircle, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {v4 as uuidv4} from 'uuid';
import "../tool.css"
import "../../Utils/Utils.css"
import {
    faBookOpen,
    faChartLine,
    faDotCircle,
    faEdit,
    faMinus,
    faPlus,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../Utils/Sidebar/Sidebar";
import Information from "../../Utils/Information/Information";
import Overview from "./Overview";
import InfoMatrix from "./InfoMatrix";
import {connect} from "react-redux";
import {SidebarsHidden} from "../../../State/Selectors/toolSelectors/flexiaSelector";
import {FlexiaSwitchSidebars} from "../../../State/Actions/view/tools/flexiaActions";
import EditPopUp from "./EditPopUp.js"
import ConclusionTable from "./ConclusionTable.js";
import RestUtils from "../../../Utils/RestUtils/RestUtils";
import Button from "../../Utils/Inputs/Button.js";
import "../../Utils/Inputs/Button.css";
import SaveState from "../../Utils/Information/SaveState.js";
import "../../Utils/Information/SaveState.css";
import "../../Misc/LoadingCircle/LoadingBars.css"
import Loading from "../../Misc/LoadingCircle/Loading.js";
import {InfoDiagram} from "./FlowDiagram/InfoDiagram";
import DeleteProcessesPopUp from "./DeleteProcessesPopUp";
import "./DeleteProcessesPopUp.css";
import {DataValidationException} from "../../../Utils/Exceptions/DataValidationException";
import {PerformanceMeasurementLogger} from "../../../Utils/PerformanceMeasurementLogger/PerformanceMeasurementLogger";
import {ProtocolGenerator} from "./Protocol/ProtocolGenerator";
import {Popup} from "../../Utils/Popup/Popup"

library.add(faCircle, faCheckCircle, faTimesCircle, faPlus, faTimes, faChartLine, faBookOpen, faEdit);

const VIEWS = {
    NONE: "none",
    OVERVIEW: "overview",
    MATRIX: "matrix",
    DIAGRAM: "diagram",
    SUMMARY: "summary",
    ERROR: "error"
};

export const DATA_SAVE_TYPES = {
    ANALYSIS: "Analyse",
    MATRIX_ROW: "Zeile der Matrix",
    PROCESS: "Prozess",
    FLOW: "Zeile des Diagramms",
    NEW_MATRIX_ROW: "neue Zeile der Matrix",
    NEW_PROCESS: "neuer Prozess",
    NEW_ANALYSIS: "neue Analyse",
    NEW_FLOW: "neue Zeile des Diagramms",
};

class FlexIA extends React.Component{

    ProtocolGenerator = new ProtocolGenerator()

    constructor(props) {
        super(props);
        this.state = {
            //Data
            currentAnalysis: null,
            currentProcess: null,
            analyses: [],

            saveState:{
                lastSave: null,
                restStatus: null,
                data: null,
                retryFkt: null,
                dataType: null,
                saving: false,
            },

            //View
            currentView: VIEWS.NONE,
            toggleableSidebars: false,
            sidebarsInitiallyHidden: [false, false],
            editingAnalysisTopic: false,
            editingAnalysisInfo: false,
            editPopUpCoordinates: [],
            analysisDeleteRequest: false,
            processesDeleteRequest: false,

        };
    }

    async componentDidMount() {
        this.setState({loading: true,});

        try{
            let analyses = await this.getAllAnalyses();
            let currentAnalysis = this.findLatestAnalysis(analyses);

            this.setState((oldState)=>{
                return{
                    ...oldState,
                    analyses: analyses,
                    currentAnalysis: currentAnalysis,
                    currentView: VIEWS.OVERVIEW,
                    loading: false,
                    overviewKey: uuidv4()
                }
            });
        }
        catch(err){
            console.error(err);
            this.setState({
                currentView: VIEWS.ERROR,
                errorMessage: "Die Verbindung zum Server wurde unterbrochen, bitte überprüfen Sie Ihre Internetbernindung."
            })
        }
    }

    findLatestAnalysis(analyses) {
        let latestAnalysis = analyses[0];
        for (let i = 1; i < analyses.length; i++) {
            if (new Date(analyses[i].updatedAt) >= new Date(latestAnalysis.updatedAt)) {
                latestAnalysis = analyses[i];
            }
        }
        return latestAnalysis;
    }

    //views
    GetResetStateObject(){
        return {
            toggleableSidebars: false,
            sidebarsInitiallyHidden: [false, false],

            matrixAutoFocus: null,
            editingAnalysisTopic: false,
            editingAnalysisInfo: false,
            analysisDeleteRequest: false,

            currentView: VIEWS.NONE

        }
    }
    changeCurrentAnalysis(analysis) {
        this.setState({
            ...this.GetResetStateObject(),
            currentAnalysis: analysis,
            currentProcess: null,
            currentView: VIEWS.OVERVIEW,
            loading: false,
        });
    }
    changeToOverviewOfCurrentAnalysis() {
        this.setState({
            ...this.GetResetStateObject(),
            currentProcess: null,
            currentView: VIEWS.OVERVIEW
        });
    }
    changeCurrentProcess(process) {
        this.setState({
            ...this.GetResetStateObject(),
            currentProcess: process,
            currentView: VIEWS.OVERVIEW,
        });
    }
    changeToMatrix(process) {
        this.setState({
            ...this.GetResetStateObject(),
            toggleableSidebars: true,
            sidebarsInitiallyHidden: true,
            currentProcess: {
                ...process
            },
            currentView: VIEWS.MATRIX
        })
    }
    changeToDiagram(process){
        this.setState({
            ...this.GetResetStateObject(),
            toggleableSidebars: true,
            sidebarsInitiallyHidden: true,
            currentProcess: {
                ...process
            },
            currentView: VIEWS.DIAGRAM
        })
    }


    //create new data
    // todo: direct auf server posten, mit allen nötigen bestandteilen wie rohlinge für matrix
    setSavingState(){
        this.setState(oldState=>{
            return{
                saveState: {
                    ...oldState.saveState,
                    saving: true
                }
            }
        });
    }

    async addNewAnalysis() {
        this.setSavingState()
        let newAnalysis = {
            topic: "Neue Analyse",
            description: "Keine Beschreibung angegeben",
            processes: [],
        };

        try {
            let response = await this.putAnalysis(newAnalysis.topic, newAnalysis.description)
            newAnalysis.id = response.id;
            console.log(newAnalysis, response)
            this.setState(state => {
                return {
                    ...this.GetResetStateObject(),
                    analyses: [...state.analyses, newAnalysis],
                    currentAnalysis: newAnalysis,
                    editingAnalysisTopic: true,
                    editingAnalysisInfo: false,
                    currentProcess: null,
                    currentView: VIEWS.OVERVIEW,
                    saveState: {
                        saving: false,
                        lastSave: new Date(),
                        restStatus: response.statusCode,
                        retryFkt: () => {
                            this.addNewAnalysis();
                        },
                        dataType: DATA_SAVE_TYPES.NEW_ANALYSIS,
                    },
                }
            });
        }
        catch (err){
            console.error("Could not save Analysis");
        }

    }
    //change data
    changeAnalysis(analysis, editingAnalysisTopic=false, editingAnalysisInfo=false){
        this.setSavingState()
        this.postAnalysis(analysis.topic, analysis.description, analysis.id).then((response)=>{
            this.setState(oldState => {
                return {
                    currentAnalysis: analysis,
                    editingAnalysisTopic: editingAnalysisTopic,
                    editingAnalysisInfo: editingAnalysisInfo,
                    saveState:{
                        saving: false,
                        lastSave: new Date(),
                        restStatus: response.statusCode,
                        retryFkt: ()=>{
                            this.changeAnalysis({topic: analysis.topic, description: analysis.description, id: analysis.id,}, editingAnalysisTopic, editingAnalysisInfo);
                            //this.changeAnalysis(analysis, editingAnalysisTopic, editingAnalysisInfo);
                        },
                        dataType: DATA_SAVE_TYPES.ANALYSIS,
                    },
                    analyses: oldState.analyses.map((element)=> {
                        if(element.id === analysis.id) {
                            return analysis;
                        }
                        else {
                            return element;
                        }
                    })
                };
            });
        });
    }
    //delete data
    removeAnalysis(analysisId) {
        let newAnalyses = this.state.analyses.filter((analysis)=>analysis.id !== analysisId);

        this.deleteAnalysis(analysisId).then((response)=>{

        });

        if (newAnalyses.length) {
            let newCurrentAnalysis = this.findLatestAnalysis(newAnalyses);
            this.setState({
                ...this.GetResetStateObject(),
                currentAnalysis: newCurrentAnalysis,
                loading: false,
                analyses: newAnalyses,
                currentView: VIEWS.OVERVIEW,
            })
        }
        else {
            this.setState({
                loading: false,
                analyses: [],
                currentAnalysis: {},
                currentView: VIEWS.NONE,
            })
        }
    }


//server connection
    async getAllAnalyses() {
        let per = new PerformanceMeasurementLogger()
        let analysesIds = await this.getAnalysesIds();
        if (analysesIds && analysesIds.length>=0) {
            let analyses = await Promise.all(analysesIds.map(async (id) => {
                let analysis = await this.getAnalysis(id);
                analysis.processes = [];
                return analysis;
            }));
            analyses = analyses.sort((a, b)=>{
                let ad = new Date(a.createdAt);
                let bd = new Date(b.createdAt);
                return (ad > bd) - (ad < bd);
            });
            per.log("GetAllAnalyses:")
            return analyses;
        }else
        {
            throw new DataValidationException("The Server answered with invalid Data");
        }
    }
    async getAllProcesses(analysisId) {
        let per = new PerformanceMeasurementLogger()
        let processIds = await this.getProcessIds(analysisId);
        if (processIds && processIds.length) {
            let processesPromise = processIds.map(async (id) => {
                return await this.getProcess(id, analysisId);
            });
            let processes = await Promise.all(processesPromise);
            per.log("GetAllProcesses:")
            return processes;
        }
        else {
            per.log("GetAllProcesses:")
            return [];
        }
    }

    //gets
    async getAnalysesIds() {
        let per = new PerformanceMeasurementLogger()
        let response = await RestUtils.Get("/tools/flexia/Analyses");
        per.log(`GetAnalysesIds: API response`);
        response = await response.json();
        per.log("GetAnalysesIds:")
        return response.data;
    }
    async getAnalysis(id) {
        let per = new PerformanceMeasurementLogger()
        let response = await RestUtils.Get("/tools/flexia/" + id);
        let result = await response.json();
        per.log("GetAnalyses");
        return result;
    }
    async getProcess(processId, analysisId) {
        let per = new PerformanceMeasurementLogger()
        let response = await RestUtils.Get("/tools/flexia/" + analysisId + "/" + processId);
        let process = await response.json();
        process.analysisId = process.analysis_id;
        per.log("GetProcess:");
        return process;
    }
    async getProcessIds(analysisId) {
        let per = new PerformanceMeasurementLogger()
        let response = await RestUtils.Get("/tools/flexia/" + analysisId + "/Processes");
        response = await response.json();
        per.log("GetProcessIds:");
        return response.data;
    }
    //puts (hinzufügen von neuen elementen)
    async putAnalysis(topic, description) {
        let analysis = {
            topic: topic,
            description: description,
        };
        let response = await RestUtils.Put("/tools/flexia/Analysis", analysis);
        let res = await response.json();
        res.statusCode = await response.status;
        return await res;
    }
    //posts (überschreiben von vorhanden elementen)
    async postAnalysis(topic, description, analysisId) {
        let analysis = {
            topic: topic,
            description: description,
        };
        let response = await RestUtils.Post("/tools/flexia/" + analysisId, analysis);
        let res = await response.json();
        res.statusCode = await response.status;
        return res;
    }
    //deletes
    async deleteAnalysis(analysisId) {
        let response = await RestUtils.Delete("/tools/flexia/" + analysisId);
    }

    textInputKeyUp_AnalysisTopic(event, analysis) {
        if(event.key === "Enter") {
            if (this.state.editingAnalysisTopic === true) {
                analysis.topic = event.target.value;
            }
            this.changeAnalysis(analysis, false);
        }
    }
    textInputOnBlur_AnalysisTopic(event, analysis) {
        this.setState({editingAnalysisTopic: false});
    }

    //rendering
    generateHeaderContent(){
        switch(this.state.currentView){
            case VIEWS.NONE:
                return <span/>;
            case VIEWS.OVERVIEW:
                if (this.state.currentAnalysis) {
                    return <span>
                        {this.state.editingAnalysisTopic ?
                            <div>Gesamtübersicht: <input type={"text"}
                                                         defaultValue={this.state.currentAnalysis.topic}
                                                         onKeyUp={(event) => {
                                                             this.textInputKeyUp_AnalysisTopic(event, this.state.currentAnalysis)
                                                         }}
                                                         onBlur={(event) => {
                                                             this.textInputOnBlur_AnalysisTopic(event)
                                                         }}
                                                         autoFocus={true}
                            />
                            </div>
                            :
                            <div className={"hiddenTextEditField"}
                                 onClick={() => {
                                     this.setState({editingAnalysisTopic: true})
                                 }}
                                 onMouseOver={() => {
                                 }}
                            > Gesamtübersicht: {this.state.currentAnalysis.topic}
                            </div>
                        }
                    </span>;
                }
                else return <span> Gesamtübersicht </span>;
            case VIEWS.MATRIX:
                return <span>
                    <div className={"layerUpButton"}
                         onClick={()=>{this.changeToOverviewOfCurrentAnalysis()}}> ⮤
                            </div>
                            Matrix: {this.state.currentAnalysis.topic} - {this.state.currentProcess.name}
                </span>;
            case VIEWS.DIAGRAM:
                return <span>
                    <div className={"layerUpButton"}
                         onClick={()=>{this.changeToOverviewOfCurrentAnalysis()}}> ⮤
                    </div>
                    Informationsfluss: {this.state.currentAnalysis.topic} - {this.state.currentProcess.name}
                </span>;
            case VIEWS.SUMMARY:
                return <span>Zusammenfassung</span>;
            default: return <span> Header </span>
        }
    }
    generateInfoBarContent() {
        switch(this.state.currentView){
            case VIEWS.NONE:
                return [];
            case VIEWS.OVERVIEW:
                return [
                    <Information key={uuidv4()}  label={"Analysebeschreibung"}>
                        <div>Beschreibung: {this.state.currentAnalysis.description}</div>
                        <br/>
                        <div onClick={(event)=>{this.setState({editingAnalysisInfo: true, editPopUpCoordinates: [event.clientX, event.clientY]})}}
                             className={"editAnalysisInfo actionLink"}>
                            <FontAwesomeIcon icon={faEdit}/> Beschreibung ändern
                        </div>
                    </Information>,
                    <Information key={uuidv4()} label={"Speicherstatus"}>
                        <SaveState key={uuidv4()}
                                   saveState={this.state.saveState}
                        />
                    </Information>,
                    <Information key={uuidv4()}  label={"Löschen"}>
                        <div onClick={()=>{this.setState({processesDeleteRequest: true})}}
                             className={"deleteProcesses actionLink"}>
                            <FontAwesomeIcon icon={faTrashAlt}/> Prozesse löschen
                        </div>
                        <div onClick={()=>{this.setState({analysisDeleteRequest: true})}}
                             className={"deleteAnalysis actionLink"}>
                            <FontAwesomeIcon icon={faTrashAlt}/> Analyse löschen
                        </div>
                    </Information>,
                    <Information key={uuidv4()}  label={"Weiterführende Links"}>
                        <div> <FontAwesomeIcon icon={faBookOpen}/> <a style={{marginLeft: 10}} href={"#"}>TODO: Hilfetext einbauen</a></div>
                    </Information>
                ];
            case VIEWS.MATRIX:
                return [
                    <Information key={uuidv4()} label={"Speicherstatus"}>
                        <SaveState key={uuidv4()}
                                   saveState={this.state.saveState}
                        />
                    </Information>,
                ];
            case VIEWS.DIAGRAM:
                return [
                    <Information key={uuidv4()} label={"Speicherstatus"}>
                        <SaveState key={uuidv4()}
                                   saveState={this.state.saveState}
                        />
                    </Information>,];
            default: return [
                <Information key={uuidv4()} label={"Speicherstatus"}>
                    <SaveState key={uuidv4()}
                               saveState={this.state.saveState}
                    />
                </Information>,
            ];
        }
    }

    renderAnalysesList() {
        if (this.state.analyses !== []) {
            return <div className={"analysesList"}>
                {this.state.analyses.map((analyses) => {
                    let openedClass = "";
                    let topic = analyses.topic;

                    if (this.state.currentAnalysis && analyses.id === this.state.currentAnalysis.id) {
                        openedClass = "opened";
                        topic = this.state.currentAnalysis.topic;
                    }

                    return <div className={"analysesListItem "+openedClass} key={analyses.id} onClick={() => {
                            this.changeCurrentAnalysis(analyses)
                        }}>
                        {/*<ChevronRightIcon/>*/}
                            <FontAwesomeIcon icon={faChartLine}/>
                            <div style={{marginLeft: 10}} className={"analysesListItemName " + openedClass}>
                                {topic}
                            </div>
                        </div>
                })}
            </div>
        }
        else {
            return <div className={"analysesListItem"}> loading analyses </div>
        }
    }

    renderNavigationSidebar() {
        return <Sidebar title={"Navigation"}
                        className={"navigation"}
                        toggleable={this.state.toggleableSidebars}
                        side={"left"}
                        initiallyHidden={this.state.sidebarsInitiallyHidden[0]}
                        key={uuidv4()}>
            {this.renderAnalysesList()}
            <br/>
            <br/>
            <Button onClick={()=>{this.addNewAnalysis()}}>
                <div><FontAwesomeIcon icon={faPlus}/> Analyse hinzufügen </div>
            </Button>
        </Sidebar>
    }
    renderInfoSidebar() {
        let sidebarContent = [];
        sidebarContent.push(
            <Information key={uuidv4()} label={"Stammdaten"}>
                {this.state.currentAnalysis ?
                    <div>
                        <div> <b>Analyse:</b> {this.state.currentAnalysis.topic} </div>
                        <div> <b>Erstellt am:</b> {new Date(this.state.currentAnalysis.createdAt).toLocaleDateString()} </div>
                        <div> <b>Bearbeitet am:</b> {new Date(this.state.currentAnalysis.updatedAt).toLocaleDateString()} </div>
                    </div> :
                    <div>
                        Klicken sie auf eine Analyse oder Prozess auf der linken Seite um mehr Informationen zu bekommen.
                    </div>
                }
                <br/>
                {this.state.currentProcess ?
                    <div>
                        <div> Prozess: {this.state.currentProcess.name} </div>
                    </div> : null}
            </Information>
        );

        sidebarContent.push(this.generateInfoBarContent());

        return <Sidebar className={"infobar"}
                        title={"Infobar"}
                        toggleable={this.state.toggleableSidebars}
                        side={"right"}
                        initiallyHidden={this.state.sidebarsInitiallyHidden[1]}
                        key={uuidv4()}
        > {sidebarContent} </Sidebar>
    }

    renderContentDiv() {
        switch(this.state.currentView){
            case VIEWS.NONE:
                return (
                    <div className={"content"}>
                        {this.state.loading ?
                            <div className={"loadingWrapper"}> <Loading/> </div>
                            :
                            <div> No Analysis found </div>}
                    </div>);
            case VIEWS.OVERVIEW:
                if (this.state.currentAnalysis) {
                    return (
                        <div className={"content"}>
                            <header> {this.generateHeaderContent()} </header>
                            <div className={"contentOverview"}>
                                <Overview
                                    key={this.state.overviewKey}
                                    analysisId={this.state.currentAnalysis.id}
                                    onSavedStarted={()=>{
                                        let newState = this.state;
                                        newState.saveState.saving = true;
                                        this.setState(newState);
                                    }}
                                    onSaveFinished={(saveState)=>{
                                        this.setState({
                                            saveState: saveState
                                        })
                                    }}
                                    clickedMatrix={(process) => {
                                        this.changeToMatrix(process)
                                    }}
                                    clickedDiagram={(process) => {
                                        this.changeToDiagram(process)
                                    }}
                                    editDescription={(event) => {
                                        this.setState({editingAnalysisInfo: true, editPopUpCoordinates: [event.clientX, event.clientY]})
                                    }}
                                    deleteRequestAnalysis={() => {
                                        this.setState({analysisDeleteRequest: true})
                                    }}
                                    clickedGenerateProtocol={(analysisId) => {
                                        //TODO: Implement the generation of the Protokoll
                                        this.ProtocolGenerator.generate(this.state.currentAnalysis.id,
                                        (finished, error)=>{
                                            this.setState({
                                                protocolPopupOpen: false,
                                                protocolState: {
                                                    finished: finished,
                                                    error: error,
                                                    step: 5,
                                                    subStep: 0,
                                                    subStepMax: 0,
                                                    message: "Fertig",
                                                    subMessage: ""
                                                }
                                            })
                                        },
                                        (status)=>{
                                            this.setState({
                                                protocolState: {
                                                    finished: null,
                                                    error: null,
                                                    step: status.step,
                                                    subStep: status.substep,
                                                    subStepMax: status.substepmax,
                                                    message: status.message,
                                                    subMessage: status.submessage
                                                }
                                            })
                                        })

                                        this.setState({
                                            protocolPopupOpen: true,
                                            protocolState: {
                                                finished: null,
                                                error: null,
                                                step: 0,
                                                subStep: 0,
                                                message: "",
                                                subMessage: ""
                                            }
                                        })
                                    }}
                                />
                            </div>
                            {this.state.editingAnalysisInfo ?
                                <EditPopUp hasData={!!this.state.currentAnalysis}
                                           analysis={this.state.currentAnalysis}
                                           onSave={(analysis) => {
                                               this.changeAnalysis(analysis, false, true)
                                           }}
                                           onSaveAndClose={(analysis) => {
                                               this.changeAnalysis(analysis, false, false)
                                           }}
                                           onClose={()=>{
                                               this.setState({editingAnalysisInfo: false})
                                           }}
                                /> : null
                            }
                            {this.state.analysisDeleteRequest ?
                                <div className={"deleteAnalysisPopUpBackground"}>
                                    <div className={"deleteAnalysisPopUp"}>
                                        <div className={"deleteAnalysisPopUpText"}>
                                            Sind Sie sicher, dass Sie die Analyse "{this.state.currentAnalysis.topic}" löschen wollen?
                                        </div>
                                        <div className={"deleteAnalysisPopUpButtons"}>
                                            <button onClick={()=>{this.removeAnalysis(this.state.currentAnalysis.id)}}> Löschen </button>
                                            <button onClick={()=>{this.setState({analysisDeleteRequest: false})}}> Abbrechen </button>
                                        </div>
                                    </div>
                                </div>: null
                            }
                            {this.state.processesDeleteRequest ?
                                <DeleteProcessesPopUp
                                    analysisId={this.state.currentAnalysis.id}
                                    onSavedStarted={()=>{
                                        let newState = this.state;
                                        newState.saveState.saving = true;
                                        this.setState(newState);
                                    }}
                                    onSaveFinished={(saveState)=>{
                                        this.setState({
                                            saveState: saveState
                                        })
                                    }}
                                    onClose={()=>{this.setState({processesDeleteRequest: false, overviewKey: uuidv4()})}}

                                /> : null
                            }
                        </div>
                    );
                }
                else return (
                  <div className={"content"}>
                      <header> Gesamtübersicht Analyse </header>
                      <div> Bitte wählen Sie eine Analyse auf der linken Seite aus oder erstellen Sie eine neue.</div>
                  </div>
                );
            case VIEWS.MATRIX:
                return (
                    <div className={"content"}>
                        <header> {this.generateHeaderContent()} </header>
                        <InfoMatrix
                                    analysisId={this.state.currentProcess.analysisId}
                                    processId={this.state.currentProcess.id}
                                    onSavedStarted={()=>{
                                        let newState = this.state;
                                        newState.saveState.saving = true;
                                        this.setState(newState);
                                    }}
                                    onSaveFinished={(saveState)=>{
                                        this.setState({
                                            saveState: saveState
                                        })
                                    }}
                        />
                    </div>
                );
            case VIEWS.DIAGRAM:
                return (
                  <div className={"content"}>
                      <header> {this.generateHeaderContent()} </header>
                      <InfoDiagram analysisId={this.state.currentProcess.analysisId}
                                   processId={this.state.currentProcess.id}
                                   onSaveStarted={()=>{
                                       let newState = this.state;
                                       newState.saveState.saving = true;
                                       this.setState(newState);
                                   }}
                                   onSaveFinished={(saveState)=>{this.setState({
                                       saveState: saveState,
                                   })}}
                      />
                  </div>
                );
            case VIEWS.SUMMARY:
                return(
                    <div className={"content"}>
                        <header> {this.generateHeaderContent()} </header>
                        <ConclusionTable key={uuidv4()}
                                         conclusion={this.state.currentAnalysis.conclusion}/>
                    </div>
                );
            case VIEWS.ERROR:
                return(
                    <div className={"content"}>
                        <header>Fehler</header>
                        <div>{this.state.errorMessage}</div>
                    </div>
                )
            default:
                return (<div className={"content"}>No View Selected</div>)
        }
    }
    render() {

        return <div className={"Tool FlexIA"}>
            <header>
                Informationsflussanalyse
            </header>
            <div className={"contentWrapper"}>
                {this.state.protocolPopupOpen ?
                    <Popup onOutsideClick={()=>{}}>
                        <div className={"messageContainer"}>
                            <header><h2>Generiere Protokoll</h2><FontAwesomeIcon className={"close"} icon={faTimes} onClick={()=>{
                                this.setState({protocolPopupOpen: false})
                            }}/></header>
                            <div className={"messageContainerContent"}>
                                <div className={"progress"}>
                                    <progress max={5} value={this.state.protocolState.step}/>
                                    <progress max={this.state.protocolState.subStepMax} value={this.state.protocolState.subStep}/>
                                    <span className={"progressMessage"}>{this.state.protocolState.message}</span>
                                    <span className={"progressMessage small"}>{this.state.protocolState.subMessage}</span>
                                    {this.state.protocolState.error ?
                                    <span style={{color: "#980808"}} className={"progressMessage small"}>{this.state.protocolState.error}</span> :null}
                                </div>
                            </div>
                        </div>
                    </Popup> : null
                }
                {this.renderNavigationSidebar()}
                <div className={"contentAndActions"}>
                    {this.renderContentDiv()}
                    <div className={"actions"}>
                    </div>
                </div>
                {this.renderInfoSidebar()}
            </div>

        </div>

    }
}

function mapStateToProps(state){
    return {
        hideSidebars: SidebarsHidden(state)
    }
}

const mapActionsToProps = {
    FlexiaSwitchSidebars
};

export default connect(mapStateToProps, mapActionsToProps)(FlexIA);
