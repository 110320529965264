/**
 * Created by Sebastian Venhuis on 17.11.2020.
 */


import React from "react";
import Button from "../../../Utils/Inputs/Button";
import scaleRange from "scale-number-range";
import ProgressBar from "@ramonak/react-progress-bar";
import {checkPassword} from "./UserPassword";
import {addUserAsync} from "../../../../State/Actions/Administration/userAdministrationActions";
import {connect} from "react-redux";
import {Redirect} from "react-router";

const eMailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class RegisterUserClass extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            name: "",
            firstName: "",
            password1: "",
            password2: "",
            eMail: "",
            passwordValidation: {
                success: false,
                minLength:{
                    required: 12, //This can be used to change the min length required
                    actual: 0
                },
                areEqual: true,
                blacklisted:false
            }
        }
    }

    passwordChanged(password1, password2){
        if(password1 !== this.state.password1)
            this.setState({password1: password1})
        if(password2 !== this.state.password2)
            this.setState({password2: password2})

        this.setState({passwordValidation: checkPassword(password1, password2)})
    }

    registerUser(){
        if(this.registerIsPossible()){
            this.props.addUserAsync({
                eMail: this.state.eMail,
                firstName: this.state.firstName,
                name: this.state.name,
                password: this.state.password1,
            })
            this.setState({success: true})
        }
    }

    renderPasswordValidity(){
        let completed = 10;
        let color = "#ff0000"

        if(this.state.passwordValidation.success){
            completed = 80;
            color="#6eb71e";
            if(this.state.passwordValidation.minLength.actual >= 16) {
                completed = 100
                color="#00ff00";
            }
        }
        else{
            if(!this.state.passwordValidation.areEqual || this.state.passwordValidation.blacklisted) {
                completed = 5;
                let color = "#ff0000"
            }
            else {
                completed = scaleRange(this.state.passwordValidation.minLength.actual, 0, 11, 5, 79);
                color = "#ff1e00"
            }
        }

        return [<div key={"UserManagementRegisterPasswordProgress"} className={"userField full"}>
            <span/><ProgressBar completed={completed} width={"300px"} bgcolor={color} labelSize={0}/>
        </div>, <div key={"UserManagementRegisterPasswordErrors"} className={"passwordErrors"}>
            {this.state.passwordValidation.minLength.actual < this.state.passwordValidation.minLength.required ? <span>Das Passwort muss mindestens {this.state.passwordValidation.minLength.required} Zeichen lang sein</span> : null}
            {(!this.state.passwordValidation.areEqual) ? <span>Die Passwörter müssen gleich sein</span> : null}
            {this.state.passwordValidation.blacklisted ? <span>Das Passwort ist auf der Blacklist, da es sehr oft verwendet wird. Bitte wählen Sie ein anderes Passwort</span> : null}
            {(!this.state.eMail.match(eMailReg)) ? <span>Keine E-Mail angegeben</span> : null}
        </div> ]
    }

    registerIsPossible(){
        return (this.state.eMail.match(eMailReg)) && (this.state.passwordValidation.success)
    }

    render(){
        return <div className={"RegisterUser"}>
            {this.state.success ? <Redirect to={"/Administration/Benutzerverwaltung"}/> : null}
            <div className={"content"}>
                <div className={"AdministrationForm"}>
                    <header>Neuen Benutzer anlegen</header>
                    <form className={"content"}>
                        <div className={"userField"}><span>Vorname (optional)</span><input type={"text"} value={this.state.firstName} onChange={(event)=>{this.setState({firstName: event.target.value})}}/></div>
                        <div className={"userField"}><span>Name (optional)</span><input type={"text"} value={this.state.name} onChange={(event)=>{this.setState({name: event.target.value})}}/></div>
                        <div className={"userField"}><span>E-Mail</span><input type={"text"} autoComplete={"username"} value={this.state.eMail} onChange={(event)=>{this.setState({eMail: event.target.value})}}/></div>
                        <div className={"userField"}><span>Password</span><input type={"password"} autoComplete={"new-password"} value={this.state.password1} onChange={(event)=>{this.passwordChanged(event.target.value, this.state.password2)}}/></div>
                        <div className={"userField"}><span>Password wiederholen</span><input type={"password"} autoComplete={"new-password"} value={this.state.password2} onChange={(event)=>{this.passwordChanged(this.state.password1, event.target.value)}}/></div>
                        {this.renderPasswordValidity()}
                    </form>
                    <footer>
                        <Button color={"#910303"} link={"/Administration/Benutzerverwaltung"} >Abbrechen</Button><Button color={this.registerIsPossible() ? null : "#8d8d8d"} onClick={()=>{this.registerUser()}}>Speichern</Button>
                    </footer>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state)=>{
    return {}
}

const mapActionsToProps = {
    addUserAsync
}

export const RegisterUser = connect(mapStateToProps, mapActionsToProps)(RegisterUserClass)
