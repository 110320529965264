import {
    CLOSE_LOGIN,
    LOGIN, LOGIN_FAILED,
    LOGIN_STARTED,
    LOGIN_STOPPED,
    LOGOUT,
    OPEN_EMERGENCY_LOGIN,
    OPEN_LOGIN, REJECT_EMERGENCY_LOGIN, RESOLVE_EMERGENCY_LOGIN,
    TEST_AUTH
} from "../Actions/authActions";


let initialState = {
    jwt: null,
    jwtData: null,
    loginWindowOpen:  false,
    loginFailed: null,
    loginFailedReason: "",
    showLoginInProgress: false,
    emergencyLogin: false,
    emergencyLoginPromiseReject: null,
    emergencyLoginPromiseResolve: null,
};

function authReducer(state = initialState, action){
    let data = action.data;
    switch(action.type){
        case TEST_AUTH:
            return {
                ...state,
                jwt: data.jwt
            };
        case LOGOUT:
            return {
                ...state,
                jwt: null,
                jwtData: null,
            };
        case LOGIN:
            return{
                ...state,
                jwt: data.jwt,
                jwtData: data.jwtData,
                loginWindowOpen: false,
                showLoginInProgress: false,
                emergencyLogin: false
            };
        case OPEN_LOGIN:
            return{
                ...state,
                loginWindowOpen: true
            };
        case CLOSE_LOGIN:
            return{
                ...state,
                loginWindowOpen: false
            };
        case LOGIN_STARTED:
            return{
                ...state,
                showLoginInProgress: true,
            };
        case LOGIN_STOPPED:
            return {
                ...state,
                showLoginInProgress: false
            };
        case LOGIN_FAILED:
            return{
              ...state,
                showLoginInProgress: false,
                loginFailed: true,
                loginFailedReason: data.reason
            };
        case OPEN_EMERGENCY_LOGIN:
            return{
                ...state,
                loginWindowOpen: true,
                emergencyLogin: true,
                emergencyLoginPromiseReject: data.emergencyLoginPromiseReject,
                emergencyLoginPromiseResolve: data.emergencyLoginPromiseResolve
            };
        case RESOLVE_EMERGENCY_LOGIN:
            return{
                ...state,
                emergencyLogin: false,
                emergencyLoginPromiseReject: null,
                emergencyLoginPromiseResolve: null,
            };
        case REJECT_EMERGENCY_LOGIN:
            return{
                ...state,
                emergencyLogin: false,
                emergencyLoginPromiseReject: null,
                emergencyLoginPromiseResolve: null
            };
        default:
            return state;
    }
}

export default authReducer;