/**
 * Created by Sebastian Venhuis on 10.02.2020.
 */

import RestOptions from "./RestOptions";
import urljoin from "url-join";
import {AuthStore} from "./AuthStore";
import ServiceUnavailableException from "../Exceptions/ServiceUnavailableException";
import UnauthorizedException from "../Exceptions/UnauthorizedException";

export default class RestUtils{

    /**
     * The Options for the RestUtils
     * @type {RestOptions|null}
     * @private
     */
    static _options = null;

    /**
     * The Store in with the Auth Token is stored
     * @type {AuthStore}
     */
    static authStore = null;

    /**
     * Initialises the RestUtils
     * @param defaultUrl {String} The URL that is added to the beginning of every Request
     * @param onTokenChanged {function(string):void} Callback that is called, when the JwtToken changed
     * @param notAuthenticatedCallback {function():Promise<void>} Callback that is called, when a Request could not be executed, because the user
     * was not logged in. It should open a Dialog to let the user Re Login and after the user logged in successfully it should resolve the Promise!
     */
    static initialise(defaultUrl, onTokenChanged ,notAuthenticatedCallback){
        RestUtils._options = new RestOptions(defaultUrl, notAuthenticatedCallback);
        this.authStore = new AuthStore({onTokenChanged});
    }

    /**
     * Logs the Rest Utils "Out". Removes the Token from the Token Storage and removes the Token Refresher.
     * @constructor
     */
    static Logout(){
        if(this.authStore !== null){
            this.authStore.clearRefresher();
            this.authStore.setToken("");
        }
    }

    /**
     * Sends a Put Requst to the specified URL
     * @param url {String} The URL of the Resource (excluding the Static Domain and api part); For Example: /session, /user/password
     * @param [data] {Object} The Object that is send to the Server
     * @param [options] {Object} Additional Options
     * @param [options.overwriteDefaultUrl] {Boolean} If set to a truthy value, the preset Domain and api path is skipped, and only the url Parameter is used
     * @param [options.headers] {[{String name, String data}]} An array of addiotional headers.
     * @param [options.noAuth] {Boolean} If set to a truthy Value, the default jwt Auth header is not included.
     * @returns {Promise<Object>} Returns the Fetch result of the Post
     * @throws {ServiceUnavailableException} if there was no Response
     * @throws {UnauthorizedException} if the User was not logged in
     */
    static async Put(url, data, options){
        if(!url){
            throw new Error("url is not set");
        }
        options = options || {};
        options.headers = options.headers || [];

        let path = urljoin(options.overwriteDefaultUrl ? "" : this._options.DefaultUrl,  url);

        let headers =  this.CreateHeaders(options.headers, {noAuth: options.noAuth});

        try{
            let result = await fetch(path, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data),
                mode: "cors"
            });

            if(result.status === 401){
                //Check if the NotAuthenticatedCallback was set. If yes call it and await a response. Then redo the Request
                if(this._options.NotAuthenticatedCallback){
                    try {
                        await this._options.NotAuthenticatedCallback();
                        headers = this.CreateHeaders(options.headers);
                        result = await fetch(path, {
                            method: "PUT",
                            headers: headers,
                            body: JSON.stringify(data),
                            mode: "cors"
                        });
                    }
                    catch (e) {
                        throw new Error("Not Authenticated");
                    }
                }
            }
            return result;
        }
        catch (e) {
            console.error(`Could not Post to ${url}`, e);
        }
    }

    /**
     * Sends a Post Requst to the specified URL
     * @param url {String} The URL of the Resource (excluding the Static Domain and api part); For Example: /session, /user/password
     * @param [data] {Object} The Object that is send to the Server
     * @param [options] {Object} Additional Options
     * @param [options.overwriteDefaultUrl] {Boolean} If set to a truthy value, the preset Domain and api path is skipped, and only the url Parameter is used
     * @param [options.headers] {[{String name, String data}]} An array of addiotional headers.
     * @param [options.noAuth] {Boolean} If set to a truthy Value, the default jwt Auth header is not included.
     * @returns {Promise<Object>} Returns the Fetch result of the Post
     * @throws {ServiceUnavailableException} if there was no Response
     * @throws {UnauthorizedException} if the User was not logged in
     */
    static async Post(url, data, options){
        if(!url){
            throw new Error("url is not set");
        }
        options = options || {};
        options.headers = options.headers || [];

        let path = urljoin(options.overwriteDefaultUrl ? "" : this._options.DefaultUrl,  url);

        let headers =  this.CreateHeaders(options.headers, {noAuth: options.noAuth});

        try{
            let result = await fetch(path, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data),
                mode: "cors"
            });

            if(result.status === 401){
                //Check if the NotAuthenticatedCallback was set. If yes call it and await a response. Then redo the Request
                if(this._options.NotAuthenticatedCallback){
                    try {
                        await this._options.NotAuthenticatedCallback();
                        headers = this.CreateHeaders(options.headers);
                        result = await fetch(path, {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(data),
                            mode: "cors"
                        });
                    }
                    catch (e) {
                        throw new Error("Not Authenticated");
                    }
                }
            }
            return result;
        }
        catch (e) {
            console.error(`Could not Post to ${url}`, e);
        }
    }

    /**
     * Creates the Headers for a Request
     * @param customHeaders {Array<{name: string, data: string}>} Array of Custom Headers
     * @param [options] {Object} Optional Options
     * @param [options.noAuth] {boolean} Setting this options disables the default jwt Auth
     * @param [options.contentType=application/json] {string} The Content Type of the request
     * @returns {Headers}
     * @constructor
     */
    static CreateHeaders(customHeaders, options){
        options = options || {};

        let headers = new Headers();
        if(!options.noAuth)
            headers.append("Authorization", `Bearer ${this.authStore.getToken()}`);

        options.contentType = options.contentType || "application/json";
        headers.append("content-type", options.contentType);

        if(Array.isArray(customHeaders)){
            customHeaders.forEach((item)=>{
                headers.append(item.name, item.data);
            });
        }
        return headers;
    }

    /**
     * Sends a Get Request to the specified URL
     * @param url {String} The URL of the Resource (excluding the Static Domain and api part); For Example: /session, /user/password
     * @param [parameters] {Object} The Query Parameters that are send to the Server. Needs to be a flat Object that will be interpreted as a hashmap
     * @param [options] {Object} Additional Options
     * @param [options.overwriteDefaultUrl] {Boolean} If set to a truthy value, the preset Domain and api path is skipped, and only the url Parameter is used
     * @param [options.headers] {[{String name, String data}]} An array of addiotional headers.
     * @param [options.noAuth] {Boolean} If set to a truthy Value, the default jwt Auth header is not included.
     * @returns {Promise<Object>} returns the Result of the fetched data
     * @throws {ServiceUnavailableException} if the Server does not Respond
     */
    static async Get(url, parameters, options){
        if(!url){
            throw new Error("url is not set");
        }
        parameters = parameters || {};
        options = options || {};

        options.headers = options.headers || [];

        let headers = this.CreateHeaders(options.headers, {noAuth: options.noAuth});

        //Create Url and set Params
        let path = new URL(urljoin(options.overwriteDefaultUrl ? "" : this._options.DefaultUrl,  url));
        Object.keys(parameters).forEach(key => path.searchParams.append(key, parameters[key]));

        try{
            let result = await fetch(path, {
                method: "GET",
                headers: headers,
                mode: "cors"
            });

            if(result === null || result === undefined){
                throw new ServiceUnavailableException();
            }

            if(result.status === 401){
                //Check if the NotAuthenticatedCallback was set. If yes call it and await a response. Then redo the Request
                if(this._options.NotAuthenticatedCallback){
                    await this._options.NotAuthenticatedCallback();

                    headers = this.CreateHeaders(options.headers);
                    result = await fetch(path, {
                        method: "GET",
                        headers: headers,
                        mode: "cors"
                    });
                }
            }
            return result;
        }
        catch (e) {
            console.error(`Could not Get to ${url}`, e);
            throw new ServiceUnavailableException();
        }
    }

    /**
     * Sends a Delete Request to the specified URL
     * @param url {String} The URL of the Resource (excluding the Static Domain and api part); For Example: /session, /user/password
     * @param [options] {Object} Additional Options
     * @param [options.overwriteDefaultUrl] {Boolean} If set to a truthy value, the preset Domain and api path is skipped, and only the url Parameter is used
     * @param [options.headers] {[{String name, String data}]} An array of addiotional headers.
     * @param [options.noAuth] {Boolean} If set to a truthy Value, the default jwt Auth header is not included.
     * @returns {Promise<Object>} returns the Result of the fetched data
     * @throws {ServiceUnavailableException} if the Server does not Respond
     */
    static async Delete(url, options){
        if(!url){
            throw new Error("url is not set");
        }
        options = options || {};

        options.headers = options.headers || [];

        let headers = this.CreateHeaders(options.headers, {noAuth: options.noAuth});

        //Create Url and set Params
        let path = new URL(urljoin(options.overwriteDefaultUrl ? "" : this._options.DefaultUrl,  url));

        try{
            let result = await fetch(path, {
                method: "DELETE",
                headers: headers,
                mode: "cors"
            });

            if(result === null || result === undefined){
                throw new ServiceUnavailableException();
            }

            if(result.status === 401){
                //Check if the NotAuthenticatedCallback was set. If yes call it and await a response. Then redo the Request
                if(this._options.NotAuthenticatedCallback){
                    await this._options.NotAuthenticatedCallback();

                    headers = this.CreateHeaders(options.headers);
                    result = await fetch(path, {
                        method: "DELETE",
                        headers: headers,
                        mode: "cors"
                    });
                }
            }
            return result;
        }
        catch (e) {
            console.error(`Could not Get to ${url}`, e);
            throw new ServiceUnavailableException();
        }
    }

    /**
     * Sends a Login Request to the specified Url and stores the received Session token in the Session Token Storage.
     * This will trigger regular token refreshes
     * @param url {string} The URL the login request is send to.
     * @param username {string} The Username with witch to login. Might be an E-Mail
     * @param password {string} The password (in clear) to login
     * @param [options] {Object} Optional Parameters for the Login Call
     * @param [options.headers] {[{String name, String data}]} Additional headers for the Login
     * @returns {Promise<Response>} returns a Promise that will provide the fetch result
     * @throws {ServiceUnavailableException} if the Server is not Available
     * @throws {UnauthorizedException} if the Login was not successful
     */
    static async Login(url, username, password, options){
        try{
            options = options || {};
            let path = urljoin(options.overwriteDefaultUrl ? "" : this._options.DefaultUrl,  url);
            options.headers = options.headers || [];
            let base64Credentials = btoa(`${username}:${password}`);
            options.headers.push({name:"Authorization", data:`Basic ${base64Credentials}`});

            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            if(Array.isArray(options.headers)){
                options.headers.forEach((item)=>{
                    headers.append(item.name, item.data);
                })
            }
            let result = await fetch(path, {
                method: "GET",
                headers: headers,
                mode: "cors",
                credentials: "include"
            });



            if(result === null || result === undefined){
                throw new ServiceUnavailableException();
            }

            let body = await result.json();
            if (typeof(body.jwt) !== "string")
                throw new UnauthorizedException("Server did not respond with a JWT Token");

            this.authStore.setToken(body.jwt);
            //Overwrite json Method, as Body was already parsed
            result.json = ()=>{
                return Promise.resolve(body);
            };
            return result;
        }catch (e) {
            if(e instanceof ServiceUnavailableException){
                throw e;
            }
            else if(e instanceof UnauthorizedException)
                throw e;
            else {
                console.warn(e);
                throw new Error("Could not login");
            }
        }
    }

    static async Logout(url, options){
        try{
            options = options || {};
            let path = urljoin(options.overwriteDefaultUrl ? "" : this._options.DefaultUrl,  url);
            options.headers = options.headers || [];

            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            if(Array.isArray(options.headers)){
                options.headers.forEach((item)=>{
                    headers.append(item.name, item.data);
                })
            }
            let result = await fetch(path, {
                method: "DELETE",
                headers: headers,
                mode: "cors",
                credentials: "include"
            });
        }
        catch {

        }
    }

    /***
     * Tries to login the user with a preset login cookie
     * @param url {string} The URL the login request is send to.
     * @param [options] {Object} Optional Parameters for the Login Call
     * @param [options.headers] {[{String name, String data}]} Additional headers for the Login
     * @returns {Promise<void>}
     * @constructor
     */
    static async LoginWithCookies(url, options){
        try{
            options = options || {};
            let path = urljoin(options.overwriteDefaultUrl ? "" : this._options.DefaultUrl,  url);
            options.headers = options.headers || [];

            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            if(Array.isArray(options.headers)){
                options.headers.forEach((item)=>{
                    headers.append(item.name, item.data);
                })
            }
            let result = await fetch(path, {
                method: "GET",
                headers: headers,
                mode: "cors",
                credentials: "include"
            });



            if(result === null || result === undefined){
                throw new ServiceUnavailableException();
            }

            let body = await result.json();
            if (typeof(body.jwt) !== "string")
                throw new UnauthorizedException("Server did not respond with a JWT Token");

            this.authStore.setToken(body.jwt);
            //Overwrite json Method, as Body was already parsed
            result.json = ()=>{
                return Promise.resolve(body);
            };
            return result;
        }catch (e) {
            console.error(e);
            if(e instanceof ServiceUnavailableException){
                throw e;
            }
            else if(e instanceof UnauthorizedException)
                throw e;
            else {
                console.warn(e);
                throw new Error("Could not login");
            }
        }
    }
};


