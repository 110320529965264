import RestUtils from "../../Utils/RestUtils/RestUtils";
import {EmergencyLoginReject, EmergencyLoginResolve, IsEmergencyLoginPanel} from "../Selectors/authSelectors";
import ServiceUnavailableException from "../../Utils/Exceptions/ServiceUnavailableException";
import UnauthorizedException from "../../Utils/Exceptions/UnauthorizedException";

const jsonwebtoken = require("jsonwebtoken");

export const LOGIN = "LOGIN";

export function Login(jwt, jwtData) {
    return {
        type: LOGIN,
        data:{
            jwt: jwt,
            jwtData: jwtData
        }
    }
}

export const LOGIN_FAILED = "LOGIN_FAILED";

export function LoginFailed(reason){
    return {
        type: LOGIN_FAILED,
        data:{
            reason
        }
    }
}

export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_STOPPED = "LOGIN_STOPPED";

/**
 * @param show {Boolean}
 * @constructor
 */
export function SetLoginInProgress(show){
    return{
        type: show ? LOGIN_STARTED : LOGIN_STOPPED
    }
}

/**
 * Set the login Token and resolve any ongoing Emergency Logins, etc
 * @param token {String} The Jwt Token
 * @returns {Function}
 */
export function AsyncLogin(token){
    return function(dispatch){
        //Get Token data
        let tokenData = jsonwebtoken.decode(token);

        dispatch(AsyncResolveEmergencyLogin());
        dispatch(SetLoginInProgress(false));
        dispatch(Login(token, tokenData));
    }
}

/**
 * Action that will create a new login Request, get the Resonse and set the SessionToken
 * @param username
 * @param password
 * @returns {Function}
 */
export function AsyncLoginRequest(username, password){
    return async function(dispatch){
        try{
            let result = await RestUtils.Login("/session", username, password);
            if(result.status === 401){
                dispatch(LoginFailed("Anmeldung Fehlgeschlagen. Der Benutzername oder das Password ist nicht korrekt"));
            }
            else if(result.status === 503){
                dispatch(LoginFailed("Anmeldung derzeit nicht möglich. Bitte versuchen Sie es später erneut"));
            }
            let body = await result.json();
            dispatch(AsyncLogin(body.jwt))
        }
        catch (e) {
            if(e instanceof ServiceUnavailableException){
                dispatch(LoginFailed("Anmeldung fehlgeschlagen. Die Server konnten nicht erreicht werden."))
            }else if (e instanceof UnauthorizedException)
            {
                dispatch(LoginFailed("Anmeldung fehlgeschlagen. Benutzername oder Password inkorrect."))
            }
            else {
                console.log(e);
                dispatch(LoginFailed("Anmeldung fehlgeschlagen. Die Server konnten nicht erreicht werden."));
            }
        }
    }
}

export function AsyncTryLoginWithCookie(){
    return async function(dispatch){
        try{
            let result = await RestUtils.LoginWithCookies("/session")
            if(result.status !== 200)
                return;
            let body = await result.json();
            let tokenData = jsonwebtoken.decode(body.jwt);

            dispatch(AsyncResolveEmergencyLogin());
            dispatch(SetLoginInProgress(false));
            dispatch(Login(body.jwt, tokenData))
        }
        catch (e){

        }
    }
}

export const OPEN_LOGIN = "AUTH_OPEN_LOGIN";

export function OpenLoginWindow(){
    return {
        type: OPEN_LOGIN
    }
}

export const CLOSE_LOGIN = "AUTH_CLOSE_LOGIN";
export function CloseLoginWindow(){
    return{
        type: CLOSE_LOGIN
    }
}

export const TEST_AUTH = "TEST_AUTH";

export  function TestAuth(){
    return (
        {
            type: TEST_AUTH,
            data: {
                jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjMyMzFiZTcyLTk2NmQtNDlmNC1hNjZmLTdiYzliMmM5NWJhOSIsIm5hbWUiOiJTZWJhc3RpYW4gVmVuaHVpcyIsImlhdCI6MTUxNjIzOTAyMn0.u1p1h1qnMxAI3VxjBNqZoEw2hQgphNiwMI95ufQjaU8"
            }
        }
    )
}

export const LOGOUT = "LOGOUT";

export function AsyncLogout(){
    return async function(dispatch, getState){
        let state = getState();
        await RestUtils.Logout("/session")
        if(IsEmergencyLoginPanel(state)){
            dispatch(AsyncRejectEmergencyLogin())
        }
        dispatch(Logout())
    }
}

export function Logout(){
    return {
          type: LOGOUT
    };
}



export const OPEN_EMERGENCY_LOGIN = "OPEN_EMERGENCY_LOGIN";

/**
 * Action that should trigger a Login Display for a user to login into the server
 * @param resolve {function} Function that needs to be called, once the user is logged in
 * @param reject {function} Function that needs to be called, if the user cannot login
 * @returns {{data: {emergencyLoginPromiseResolve: *,emergencyLoginPromiseReject: *}, type: *}}
 */
export function OpenEmergencyLoginWindow(resolve, reject){
    return{
        type: OPEN_EMERGENCY_LOGIN,
        data:{
            emergencyLoginPromiseResolve: resolve,
            emergencyLoginPromiseReject: reject
        }
    }
}

export const RESOLVE_EMERGENCY_LOGIN = "RESOLVE_EMERGENCY_LOGIN";

/**
 * This function will resolve and remove the Emergency Login Status
 * @returns {Function}
 */
export function AsyncResolveEmergencyLogin(){
    return function(dispatch, getState)
    {
        let state = getState();
        if (IsEmergencyLoginPanel(state))
        {
            EmergencyLoginResolve(state);
            dispatch(ResolveEmergencyLogin())
        }
    }
}

/**
 * This function will remove the Emergency Login Status, but not call the resolve function.
 * If you want to automatically call the Resolve, please use the AsyncResolveEmergencyLogin function
 */
export function ResolveEmergencyLogin(){
    return{
        type: RESOLVE_EMERGENCY_LOGIN
    }
}

export const REJECT_EMERGENCY_LOGIN = "REJECT_EMERGENCY_LOGIN";
/**
 * This function will reject and remove the Emergency Login Status
 * @returns {Function}
 */
export function AsyncRejectEmergencyLogin(){
    return function(dispatch, getState)
    {
        let state = getState();
        if (IsEmergencyLoginPanel(state))
        {
            EmergencyLoginReject(state);
            dispatch(RejectEmergencyLogin())
        }
    }
}

/**
 * This function will remove the Emergency Login Status, but not call the reject function.
 * If you want to automatically call the Reject, please use the AsyncResolveEmergencyLogin function
 */
export function RejectEmergencyLogin(){
    return {
        type: REJECT_EMERGENCY_LOGIN
    }
}
