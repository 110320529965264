import React from "react";

export const DefaultTheme = {
    Select:{
        /*container(provided, state){
            return {
                ...provided,
                width: "200px"
            }
        },
        control(provided, state){
            return{
                ...provided,
                minHeight: "1.6em",
                height: "1.6em"
            }
        }*/
        container: (provided) => ({
            ...provided,
            display: 'inline-block',
            width: '180px',
            minHeight: '1px',
            textAlign: 'left',
            border: 'none',
        }),
        control: (provided) => ({
            ...provided,
            border: '1px solid #757575',
            borderRadius: '0',
            minHeight: '1px',
            height: '1.6em',
        }),
        input: (provided) => ({
            ...provided,
            minHeight: '1px',
            height: '1.6em',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            minHeight: '1px',
            paddingTop: '0',
            paddingBottom: '0',
            color: '#757575',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            minHeight: '1px',
            height: '24px',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            minHeight: '1px',
            paddingTop: 0,
            paddingBottom: 0,
        }),
        valueContainer: (provided) => ({
            ...provided,
            minHeight: '1px',
            height: '1.6em',
            paddingTop: '0',
            paddingBottom: '0'
        }),
        indicatorsContainer: (provided) => ({
            minHeight: '1px',
            height: '1.6em',
            paddingTop: '0',
            paddingBottom: '0',
        }),
        singleValue: (provided) => ({
            ...provided,
            minHeight: '1px',
            paddingBottom: '2px',
        }),
    }

};

const ThemeContext = React.createContext(DefaultTheme);

export default ThemeContext;