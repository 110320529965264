/**
 * Created by Sebastian Venhuis on 26.10.2020.
 */

import {combineReducers} from "redux";
import {usersReducer} from "./usersReducer";
import {rolesReducer} from "./rolesReducer";

export const administrationReducer = combineReducers({
    users: usersReducer,
    roles: rolesReducer,
})
