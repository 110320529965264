import React from "react"
import HomeView from "./Components/Views/HomeView";
import {connect} from "react-redux";
import {Link, Route} from "react-router-dom";
import FlexDeMoLogo from "./Components/Misc/Logo/FlexDeMoLogo";
import "./FlexDeMo.css"
import Login from "./Components/Login/Login";
import ContentView from "./Components/Views/ContentView";
import ToolView from "./Components/Views/ToolView";
import {UserIsLoggedIn} from "./State/Selectors/authSelectors";
import ViewSelector from "./Components/Utils/Inputs/ViewSelector";
import PhaseView from "./Components/Views/PhaseView";
import ProjectView from "./Components/Views/ProjectView";
import {GetCards} from "./State/Selectors/dataSelectors";
import Administration from "./Components/Views/Administration/Administration";
import UserProfile from "./Components/Views/Profile/UserProfile";
import TenantProfile from "./Components/Views/Profile/TenantProfile";
import {ErrorsView} from "./Components/Utils/ErrorsView/ErrorsView";
import {AsyncTryLoginWithCookie} from "./State/Actions/authActions";

class FlexDeMo extends React.Component {

    componentDidMount() {
        this.props.AsyncTryLoginWithCookie();
    }

    render() {
        return (
            <div className={"FlexDeMo"}>
                <header>
                    <FlexDeMoLogo/>
                    {this.props.UserIsLoggedIn ?
                        <ViewSelector style={{marginLeft: "auto", marginRight: "20px"}}/> : null}
                    <Login style={{marginLeft: "auto", marginRight: "20px"}}/>
                </header>
                <div className={"content"}>
                    <ErrorsView/>
                    <Route exact={true} path={"/"}>
                        <HomeView/>
                    </Route>
                    <Route path={"/phases"} component={PhaseView}/>
                    <Route path={"/project"} component={ProjectView}/>

                    {
                        this.props.cards.map((card) => {
                            return (
                                <Route key={card.id} path={"/content/" + card.linkName}>
                                    <ContentView cardId={card.id}/>
                                </Route>
                            )
                        })
                    }

                    <Route path={"/Administration"} component={Administration}/>
                    <Route path={"/Profil"} component={UserProfile}/>
                    <Route path={"/Firmenprofil"} component={TenantProfile}/>


                    <Route path={"/tool"}>
                        <ToolView/>
                    </Route>

                    <Route path={"/Impressum"}>
                        Impressum
                    </Route>
                    <Route path={"/Datenschutz"}>
                        Datenschutzerklärung
                    </Route>
                </div>
                <footer>
                    <span className={"Credit"}>© FlexDeMo 2019</span>
                    <span className={"FooterLinks"}>
                        <Link to={"/Impressum"}>
                            Impressum
                        </Link>
                        <Link to={"/Datenschutz"}>
                            Datenschutzerklärung
                        </Link>
                    </span>
                </footer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {UserIsLoggedIn: UserIsLoggedIn(state), cards: GetCards(state)}
};

const mapActionsToProps = {
    AsyncTryLoginWithCookie
}

export default connect(mapStateToProps, mapActionsToProps)(FlexDeMo);
