import PropTypes from "prop-types";
import {v4 as uuid} from "uuid"

export const CARD_TYPE_TOOL = "CARD_TYPE_TOOL";
export const CARD_TYPE_CONTENT = "CARD_TYPE_CONTENT";


export default class CardData{
    /**
     * Creates a new empty Card Entity
     * All Content of the Card will be set to an empty String
     * @param id {string} [Auto Generated UUIDv4] id The Id of the Card
     * @param phaseId {number} The Index of the Phase this Card belongs to
     * @param mainTagId {string} The Id Of the Main Tag. The Main tag will be used as the Source of color of the Tag
     * @param linkName {string} The Value Used to link to this specific Card. Will be inserted into URLS. For Example: "/tools/{linkName}"
     */
    constructor(id, phaseId, mainTagId, linkName){
        this.linkName = linkName;
        this.id = id || uuid();
        this.phaseId = phaseId;

        this.title = "";
        this.short = "";
        this.tags = [mainTagId];
        this.mainTag = mainTagId;

        this.suggestedCards = [];
    }

    /**
     * Sets the Title of the Card. The Title will be displayed in the header
     * @param value
     * @returns {CardData}
     */
    Title(value){
        this.title = value;
        return this;
    }

    /**
     *
     * @param value {string} The Text that will be written on the Card
     * @returns {CardData}
     */
    Short(value){
        this.short = value;
        return this;
    }

    /**
     * Sets the Tags for the card (The Main Tag will be append at the beginning
     * @param value {string[]} The Ids of the Tags of this Card
     * @returns {CardData}
     */
    Tags(value){
        value = value.filter((element)=>element!==this.mainTag);
        this.tags = [this.mainTag, ...value];
        return this;
    }

    /**
     * Sets the Suggested Cards
     * @param cards {string[]}
     * @returns {CardData}
     */
    SuggestedCards(cards){
        this.suggestedCards = cards;
        return this;
    }

    /**
     * Sets the Type of this Card as a Tool
     * @returns {CardData}
     */
    SetTool(){
        this.type = CARD_TYPE_TOOL;
        return this;
    }

    /**
     * Sets the Type of the Card as Content
     * @returns {CardData}
     */
    SetContent(){
        this.type=CARD_TYPE_CONTENT;
        return this;
    }

    /**
     * Sets the Background Image of the Card Header
     * @param path {string} The Relativ Path of the Image
     * @returns {CardData}
     */
    SetImage(path){
        this.image = path;
        return this;
    }

    /**
     * Sets the Content of the Card
     * @param content Plain HTML-Code
     * @return {CardData}
     */
    SetContent(content) {
        this.content = content;
        return this;
    }


}