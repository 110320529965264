const initialState={
    done: ["b864c391-795d-43d0-ad00-22ce3f5bfb71", "1893d9ce-fb5f-4428-8fd7-6fb44b8cb56c"],
    current: ["ee96c8b1-0435-46e5-9609-fb67ad740848", "9907b565-4aa9-428b-bd6a-4f5d81bc960e"],
    suggestions: ["37d380f8-0fa8-4df2-8c39-662bebb5f5ad", "34f29dd6-d450-47eb-8e60-4d9f83951bcf", "fd106ef9-db83-49e9-b1bd-1d6ecba0e39d"]
};

export default function projectViewReducer(state=initialState, action){
    switch (action.type) {
        default:
            return state
    }
}