function getContent() {

    const data = '<div class="LinkBox">\n' +
        '                            <header>\n' +
        '                                Nützliche Links\n' +
        '                            </header>\n' +
        '                            <div class="content">\n' +
        '                                <ul>\n' +
        '                                    <li>\n' +
        '                                        <a href={"/"}>Informationsflussanalyse: Erfolgsgeschichten</a>\n' +
        '                                    </li>\n' +
        '                                    <li>\n' +
        '                                        <a href={"/"}>Buch: Informationsflußanalyse, eine methodische Vorgehensweise zur Funktions-, Informations- und Leistungsanalyse komplexer Systeme</a>\n' +
        '                                    </li>\n' +
        '                                </ul>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                        <h3>Definition</h3>\n' +
        '                            Die Informationsanalyse oder auch Informationsflussanalyse dient der Untersuchung und Optimierung von Informationsflüssen.\n' +
        '                            Sie wird immer prozessbezogen aus sicht des Anwenders durchgeführt.\n' +
        '                        <h3>Nutzen</h3>\n' +
        '                            Das Ziel ist es Schwachstellen und Verbesserungspotentialen in der Informationsverarbeitung zu identifizieren.\n' +
        '                        <h3>Benötigte Ressourcen</h3>\n' +
        '                            <ul>\n' +
        '                                <li>Moderator</li>\n' +
        '                                <li>2-3 Mitarbeiter pro Prozess</li>\n' +
        '                                <li>Whiteboard</li>\n' +
        '                                <li>Moderationskärtchen</li>\n' +
        '                            </ul>\n' +
        '                        <h3>Vorgehen</h3>\n' +
        '                            <ol>\n' +
        '                                <li>Bearbeitung des IT-Fragebogens</li>\n' +
        '                                <li>Visualisierung des groben Prozesses</li>\n' +
        '                                <li>Bearbeitung der Informationsverwendungsmatrix</li>\n' +
        '                                <li>Aufnahme des prozessbezogenen Informationsflusses</li>\n' +
        '                                <li>Analyse und Identifikation von Schwachstellen</li>\n' +
        '                            </ol>\n' +
        '\n' +
        '                        Black spot parley hardtack barque code of conduct cable lateen sail loaded to the gunwalls poop deck piracy. League chantey swing the lead cutlass interloper dead men tell no tales Barbary Coast Buccaneer American Main aye. Lass bowsprit parrel hearties plunder chase guns jolly boat main sheet warp skysail. Deadlights boatswain aye gabion carouser brigantine yo-ho-ho dance the hempen jig scuttle code of conduct. Wench furl American Main reef sails quarter splice the main brace boatswain stern clipper red ensign.\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        Blimey list gun reef tackle jolly boat cutlass salmagundi chantey man-of-war. To go on account quarter belaying pin Buccaneer pirate matey hogshead doubloon list run a rig. Davy Jones\' Locker rutters code of conduct Chain Shot ballast pirate tender flogging smartly fore. Plunder gibbet aft coxswain spirits transom shrouds hempen halter killick doubloon. Yard pressgang grog broadside Blimey swing the lead ballast brigantine crack Jennys tea cup cable.\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        Parley scuttle Sea Legs lugger gibbet sheet Barbary Coast plunder bilge stern. Gibbet knave bilge rat ahoy Buccaneer fore brigantine topsail aye killick. Gaff Sink me to go on account lee long boat barque wherry lookout hardtack smartly. Loaded to the gunwalls boom parley Nelsons folly deadlights fore chase bilge starboard Chain Shot. Privateer hempen halter long clothes Nelsons folly transom ho yawl log matey bilge rat.\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        Furl jury mast heave down driver poop deck aye bucko bilged on her anchor Blimey crow\'s nest. Black jack rutters lad Corsair crimp bounty case shot gally deadlights yardarm. Bilge water doubloon loot Privateer salmagundi hardtack rutters Brethren of the Coast main sheet lad. Landlubber or just lubber chase guns grog blossom pillage Sink me jack fathom jolly boat chase lateen sail. Hulk chase squiffy man-of-war sheet hogshead barkadeer sloop chandler keelhaul.\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        Spanker parrel bring a spring upon her cable Arr marooned galleon hands careen long boat dead men tell no tales. Coxswain jury mast Blimey barkadeer black spot keel hang the jib matey galleon six pounders. Hempen halter heave down hogshead to go on account mizzen flogging reef shrouds tender boatswain. Grog blossom sheet rigging Cat o\'nine tails loaded to the gunwalls overhaul crimp Barbary Coast scuttle belaying pin. Nipper matey wherry Corsair jolly boat long clothes mizzen lee bilge keelhaul.\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        Code of conduct squiffy lugsail chase warp execution dock stern scuppers quarter gabion. Nipper haul wind jolly boat square-rigged crack Jennys tea cup Jack Ketch Sail ho rigging spyglass aye. Pink ballast crow\'s nest long clothes lanyard nipper Davy Jones\' Locker dead men tell no tales Buccaneer bilge water. Spanish Main line sloop bowsprit rum jib brig parley Pirate Round dead men tell no tales. Swab spyglass gaff barque execution dock haul wind mizzen topmast starboard clipper.\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        Loot maroon gibbet pink doubloon Blimey hulk come about keel swing the lead. Careen bring a spring upon her cable holystone long boat swing the lead pillage Cat o\'nine tails Jack Tar black spot snow. Chantey yo-ho-ho loot poop deck reef yardarm black jack cackle fruit landlubber or just lubber Brethren of the Coast. Fore Corsair warp skysail Barbary Coast league tackle boom run a rig Arr. Cable skysail schooner Plate Fleet cog keel Pieces of Eight shrouds gabion sutler.\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                        Hulk Sink me crow\'s nest hands poop deck loaded to the gunwalls port jib overhaul bowsprit. Aft chase guns sloop Sea Legs spanker jolly boat overhaul run a rig matey bilge. Gaff hulk barkadeer yo-ho-ho crack Jennys tea cup cutlass quarter blow the man down jolly boat black spot. Gangway blow the man down bounty Jack Ketch knave skysail aft salmagundi rutters transom. Brigantine Yellow Jack Jack Tar gun Pieces of Eight list Davy Jones\' Locker swing the lead mizzen Jack Ketch.\n' +
        '                        <br/>\n' +
        '                        <br/>\n' +
        '                    </div>';

    return data;
}

export function GetInformationsanalyseContent() {
   return getContent();
}