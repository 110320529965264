import RestUtils from "./RestUtils";
import * as jsonwebtoken from "jsonwebtoken";

/**
 * The Auth Store can hold and refresh Auth Tokens
 */
export class AuthStore {

    /**
     * The Token this store holds
     * @type {String|null}
     */
    token;

    /**
     * If a refresh Timer is active, the current Timer Id is stored here
     * @type {null}
     * @private
     */
    _currentTimer = null;

    /**
     * A Callback that is Called, if the Token was changed with setToken
     * @type {function(string):void}
     */
    onTokenChanged;

    /**
     * Sets the Time when a Token should be refreshed. The Time is set by giving an percentage of the whole valid time of a token.
     * If this is set to 0.5 and a Token is valid for two days, the token will be refreshed after 24 hours.
     * @type {number}
     */
    refreshPercentage = 0.8;

    /**
     * This allows the clocks be slightly off. In s
     * @type {number}
     */
    clockSkew = 200;

    /**
     * Creates a new instance of the Auth Store
     * @param options {Object?}
     * @param options.refreshPercentage {Number?} Sets the Time when a Token should be refreshed. The Time is set by giving an percentage of the whole valid time of a token.
     * If this is set to 0.5 and a Token is valid for two days, the token will be refreshed after 24 hours.
     * @param options.clockSkew {number?} This allows the clocks of the client to be slightly off. In s
     * @param options.onTokenChanged {function(string):void?} A Callback that is called, if the Token was Changed.
     */
    constructor(options){
        options = options||{};
        this.refreshPercentage = options.refreshPercentage || this.refreshPercentage;
        this.clockSkew = options.clockSkew || this.clockSkew;
        this.onTokenChanged = options.onTokenChanged;

        window.addEventListener("message", (event)=>{
            if(event.data.message_type === "set_jwt"){
                let jwt = event.data.jwt;
                this.setToken(jwt)
            }
        })
    }


    /**
     * Sets a new Token and creates a refresher, that refreshes the Token before it expires
     * @param token {String}
     */
    setToken(token){
        if(typeof(token) !== "string"){
            throw new Error("Could not set Auth Token. Token is no String");
        }
        this.clearRefresher();
        this.token = token;
        this.createRefresher();

        if(this.onTokenChanged)
            this.onTokenChanged(token);
    }

    /**
     * Returns the currently set Token, or null if no Token is present or if the Token is expired
     * @returns {String|null}
     */
    getToken(){
        if(this.isTokenExpired(this.token)){
            this.token = null;
            this.clearRefresher();
        }
        return this.token;
    }

    /**
     * Checks if a Token is not jet expired
     * @param token {String}
     * @returns {Boolean}
     */
    isTokenExpired(token){
        try{
            let decoded = jsonwebtoken.decode(token);
            if(decoded === null || decoded === undefined)
                return false;
            if(!decoded.exp){
                return false;
            }
            let expiration = decoded.exp;
            let issuedAt = decoded.iat;
            if((Date.now()/1000) > (expiration + this.clockSkew))
                return true;
            return false
        }
        catch (e) {
            return false;
        }
    }

    /**
     * creates a new Timeout, that will refresh the token one the specified Time has Elapsed. The time is set by giving a Percentage of the Expirationtime.
     */
    createRefresher(){
        //Determine time left until Refresh
        let decoded = jsonwebtoken.decode(this.token);
        if(decoded === null || decoded === undefined)
            return;
        let currentTimeInSeconds = Date.now()/1000;
        let expirationTime = decoded.exp;
        let issuedAt = decoded.iat;

        //Do not refresh the token if no Expiration Time was given
        if(expirationTime === undefined)
            return;

        let liveTime = expirationTime - issuedAt;
        let refreshTimeRelative = liveTime * this.refreshPercentage;
        let refreshTime = issuedAt+refreshTimeRelative;
        let refreshIn = (refreshTime -currentTimeInSeconds)*1000;
        refreshIn = Math.max(refreshIn, 0);
        //Create Timeout
        this._currentTimer = setTimeout(()=>{
            (async ()=>{
              let result = await RestUtils.Get("/session", {}, {});
              if(result.status === 200){
                  let body = await result.json();
                  if(typeof(body.jwt) === "string") {
                      this.setToken(body.jwt);
                  }
                  else{
                      throw new Error("Received bad Response from Server.");
                  }
              }
            })().then(
                ()=>{},
                (error)=>{
                    console.error("Could not refresh Auth Token", error);
                }
            );
        }, refreshIn);
    }

    /**
     * Removes the currently active refresh Timer
     */
    clearRefresher(){
        if(this._currentTimer !== null){
            clearTimeout(this._currentTimer);
            this._currentTimer = null;
        }
    }





}
