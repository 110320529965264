import {SET_CURRENT_USER} from "../../Actions/userActions";


let initialState = {
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    roles: [],
    tenantRoles: []
};

export default function(state=initialState, action)
{
    let data = action.data;
    switch (action.type) {
        case SET_CURRENT_USER:
            return{
                ...state,
                username: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                roles: [...data.roles],
                tenantRoles: [...data.tenantRoles]
            }
        default:
            return state;
    }
}
