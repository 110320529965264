/**
 * Created by Sebastian Venhuis on 15.03.2021.
 */

import React from "react"

export class SizeCallbackTh extends React.Component{
    constructor(props) {
        super(props);

        this.tdRef = React.createRef();
        if(this.props.onResize !== undefined) {
            console.log("Setting OnResizeCallback")
            this.callback = this.props.onResize;
        }
        else {
            console.log("Setting Default OnResizeCallback")
            this.callback = () => {}
        }
        this.mounted = false;

        this.width = null;
        this.height = null;

        if(this.props.getSizeCallback) {
            console.log("Return getSizeCallback")
            this.props.getSizeCallback(() => {
                console.log("getSizeCallback")
                return {
                    width: this.width,
                    height: this.height
                }
            })
        }

    }

    internalResize = ()=> {
        if (this.mounted) {
            this.width = this.tdRef.current.clientWidth;
            this.height = this.tdRef.current.clientHeight;
            this.callback(this.tdRef.current.clientWidth, this.tdRef.current.clientHeight);
        }
    }

    componentDidMount() {
        this.tdRef.current.addEventListener("onresize", this.internalResize)
        this.mounted = true;
        this.internalResize();


    }

    componentWillUnmount() {
        this.tdRef.current.removeEventListener("onresize", this.internalResize)
    }

    render(){
        return <th ref={this.tdRef} {...this.props.tdProps}>
            {this.props.children}
        </th>
    }
}
