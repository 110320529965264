import "./CardStack.css"
import React from "react"
import PropTypes from "prop-types"
import CardData from "./CardData";
import Card from "./Card";

export default class CardStack extends React.Component{
    render(){
        return(
            <div className={"CardStack"}>
                {this.props.cards.map((card)=>{
                    return <Card card={card} key={card.id}/>
                })}
            </div>
        )
    }
}

CardStack.propTypes = {
    cards: PropTypes.arrayOf(PropTypes.instanceOf(CardData))
};

