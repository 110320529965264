import {combineReducers} from "redux";
import searchReducer from "./view/searchReducer";
import phaseViewReducer from "./view/phaseViewReducer";
import projectViewReducer from "./view/projectViewReducer";
import toolViewReducer from "./view/toolViewReducer";


const viewReducer = combineReducers({
    search: searchReducer,
    phaseView: phaseViewReducer,
    projectView: projectViewReducer,
    toolView: toolViewReducer
});

export default viewReducer;