import "./ViewSelector.css"
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faChalkboardTeacher, faColumns, faHome} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

library.add(faColumns, faChalkboardTeacher, faHome)

class ViewSelector extends React.Component{
    render(){
        return(
            <div className={"ViewSelector"} style={this.props.style}>
                <Link to={"/"}><FontAwesomeIcon icon={faHome} size={"lg"}/></Link>
                <Link to={"/phases"}><FontAwesomeIcon icon={faColumns} size={"lg"}/></Link>
                <Link to={"/project"}><FontAwesomeIcon icon={faChalkboardTeacher} size={"lg"}/></Link>
            </div>
        )
    }
}

export default ViewSelector;