import React from "react"
import "./EditPopUp.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-regular-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

import { v4 as uuidv4 } from 'uuid';

export default class EditPopUp extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            hasData: this.props.hasData,
            analysis: this.props.analysis,
            oldDescription: this.props.analysis.description,
            autoFocusDescription: false,
            currentValue: this.props.analysis.description,
        }
    }
    onKeyPressDescription(key, value) {
        if (key === "Enter") {
            let newState = this.state;
            newState.analysis.description = value;
            newState.oldDescription = value;
            newState.currentValue = value;
            newState.autoFocusDescription = true;
            this.setState(newState);
            this.props.onSave(newState.analysis);
        }
        else if (key === "Escape") {
            let newState = this.state;
            newState.analysis.description = this.state.oldDescription;
            newState.currentValue = this.state.oldDescription;
            newState.autoFocusDescription = true;
            this.setState(newState);
        }
        else {
            let newState = this.state;
            newState.currentValue = value;
            newState.autoFocusDescription = true;
            this.setState(newState);
        }
    }
    saveAndClose() {
        let analysis = this.state.analysis;
        analysis.description = this.state.currentValue;
        this.props.onSaveAndClose(analysis);
    }
    undoChanges() {
        let newState = this.state;
        newState.analysis.description = this.state.oldDescription;
        newState.autoFocusDescription = false;
        newState.currentValue = this.state.oldDescription;
        this.setState(newState);
    }
    close(){
        this.undoChanges();
        this.props.onClose();
    }
    render() {
        if (this.state.hasData) {
            return <div className={"EditPopUpBackground"}>
                <div className={"EditPopUp"}>
                    <button className={"closeButton"} onClick={()=>{this.close()}}> <FontAwesomeIcon icon={faTimes} className={"faTimes"}/> </button>
                    <div className={"change"}>
                        <div className={"changeInfo"}>
                            <div> Beschreibung: </div> <span/>
                            <input type={"text"}
                                   key={uuidv4()}
                                   autoFocus={this.state.autoFocusDescription}
                                   defaultValue={this.state.currentValue}
                                   onKeyUp={(event)=>{this.onKeyPressDescription(event.key, event.currentTarget.value)}}/>
                        </div>
                    </div>
                    <br/>
                    <button className={"save"} onClick={()=>{this.saveAndClose()}}> Save and Close <FontAwesomeIcon icon={faSave}/> </button>
                    <button className={"undoChanges"} onClick={()=>{this.undoChanges()}}> Undo Changes </button>
                </div>
            </div>;
        }
        else {
            return <div>
                no data exists
            </div>
        }
    }
}