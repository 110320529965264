import {GetCurrentUserId} from "../Selectors/authSelectors";
import RestUtils from "../../Utils/RestUtils/RestUtils";
import ServiceUnavailableException from "../../Utils/Exceptions/ServiceUnavailableException";
import InternalServerErrorException from "../../Utils/Exceptions/InternalServerErrorException";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export function SetCurrentUser(firstname, lastname, email, roles, tenantRoles){
    return{
        type: SET_CURRENT_USER,
        data:{
            firstname,
            lastname,
            email,
            roles,
            tenantRoles
        }
    }
}

/**
 *
 * @returns {function(...[*]=)}
 * @constructor
 */
export function AsyncSetCurrentUser(){
    return async function(dispatch, getState){
        let currentState = getState();
        let userId = GetCurrentUserId(currentState);

        let response = null;

        response = await RestUtils.Get(`/user/${userId}`);
        if(response === null || response === undefined)
            throw new ServiceUnavailableException();

        if(response.status === 400){
            console.warn("Could not get current User. Malformed message send.");
            return;
        }
        if(response.status === 500){
            throw new InternalServerErrorException();
        }
        if(response.status === 503)
            throw new ServiceUnavailableException();
        if(response.status !== 200)
            throw new Error("Unknown error Occurred " + JSON.stringify(response));
        let responseData = await response.json();
        let user = responseData.user;
        dispatch(SetCurrentUser(user.firstName, user.name, user.eMail, user.roles, user.tenantRoles));
    }
}